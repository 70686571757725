import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Link, useLocation } from "react-router-dom";
import "../DonationManagement.css";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';
import ScreeningQuestions from './../components/ScreeningQuestions';
import AuthRepository from '../../auth/AuthRepository';
import DonationManagementRepository from "../DonationManagementRepository";
import UpcomingAppointmentsRepository from "../../dashboard/upcoming-appointments/UpcomingAppointmentsRepository";
import AppointmentRepository from "../../appointments/AppointmentRepository";
import Paper from "@mui/material/Paper";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const AddDonation = () => {
    const user = AuthRepository.getUser();

    const [bloodTypeList, setBloodTypeList] = React.useState([]);
    const [donationID, setDonationID] = React.useState([]);
    const [appointmentList, setAppointmentList] = React.useState([]);

    const [appointment, setAppointment] = React.useState([]);

    const getBloodTypes = async () => {
        const data = await DonationManagementRepository.getBloodTypes();
        setBloodTypeList(data);
    };

    const getAppointment = async () => {
        const data = await UpcomingAppointmentsRepository.getUpcomingAppointments();
        setAppointmentList(data);
    };

    const location = useLocation();
    const getAppointmentById = async () => {
        if (location.state != null) {
            const appointmentId = location.state[0];
            const data = await AppointmentRepository.getAppointmentById(appointmentId);
            setAppointment(data);
        }
    };

    React.useEffect(() => {
        if (location.state != null) getAppointmentById();
        getBloodTypes();
        getAppointment();
        enableTrackingForMetrics(user.id);
    }, []);

    return <>
        <div style={{ margin: "0 0 200px" }} className="addproduct">
            <div style={{ margin: "40px auto 30px" }}>
                <Title>
                    <Link to="/donations" variant="body2">
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Link>
                    Add Donation</Title>
                <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
                    Manage Donations / <strong>Add Donation</strong>
                </Typography>
            </div>

            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 16, color: "#202020" }}>
                        {location.state ? (
                            <Alert severity="info">Adding donation for <strong>{location.state[1]}</strong></Alert>
                        ) : (
                            <Alert severity="info"><strong>"Fetching donor info...</strong> please refresh if it takes too long"</Alert>
                        )}
                    </Typography>

                </Grid>

                <br />

                <ScreeningQuestions
                    donationId={donationID}
                    gender={location.state[2]}
                    appointmentId={appointment.id}
                    donorId={appointment.donor_id}
                />

            </Paper>
        </div>
    </>
}

export default function ProductContent() {
    return <AddDonation />;
}
