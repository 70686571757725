import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import '../BloodRequest.css';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AuthRepository from '../../auth/AuthRepository';
import SuccessAlert from "../../../components/SuccessAlert";
import ErrorAlert from "../../../components/ErrorAlert";
import RequestManagementRepository from '../RequestManagementRepository';
import InventoryManagementRepository from '../../inventory/InventoryManagementRepository';

export default function RequestFulfillment(props) {
  const requestId = props.bloodtReqId;
  const reqFacilityId = props.reqFacility['id'];
  const reqFacilityName = props.reqFacility['name'];
  const bloodGroup = props.bloodGroup;
  const bloodComponent = props.bloodComponent;
  const closeFunction = props.closeFunction;
  const unitsReq = props.unitsReq;
  const [showError, setShowError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingBarcodeBtn, setLoadingBarcodeBtn] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [barcode, setBarcode] = React.useState([]);
  const [isShown, setIsShown] = React.useState(false);
  const [isMatch, setIsMatch] = React.useState(false);

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const addProduct = (data) => {
    setSelectedProducts((selectedProducts) => [
      ...selectedProducts,
      data,
    ]);
  };

  const isAdded = selectedProducts.some(element => element.barcode === product.barcode);

  const user = AuthRepository.getUser();

  const onBarcodeSubmit = async (data) => {
    setLoadingBarcodeBtn(true);
    await getProductByBarcode(data);
  };

  const showErrorAlert = () => {
    setShowError(true);
  };

  const getProductByBarcode = async (barcode) => {
    try {
      const response = await InventoryManagementRepository.getProductByBarcode(barcode);
      if (
        response.blood_type["name"] === bloodGroup["name"] &&
        response.blood_component["name"] === bloodComponent["name"]
      ) {
        setIsMatch(true);
      } else {
        setIsMatch(false);
      }
      setIsShown(true);
      if (response) {
        setProduct(response);
        setLoadingBarcodeBtn(false);
        setShowError(false);
      }
    } catch (e) {
      setIsShown(false);
      showErrorAlert();
      setLoadingBarcodeBtn(false);
    }
  };

  const fullfillRequest = async (data) => {
    setIsLoading(true);
    const body = {
      user_id: user.id,
      blood_request_id: requestId,
      facility_id: user.facility_id,
      issued_facility_id: reqFacilityId,
      reason: data.reason ? data.reason : null,
      product_ids: selectedProducts.map((selprod) => (selprod.barcode)),
    };

    try {
      const response = await RequestManagementRepository.fullfillRequest(body);
      showSuccessSnackbar();
      if (response) {
        setIsLoading(false);
        setTimeout(closeFunction(), 2000);
      }
    } catch (e) {
      setIsLoading(false);
      showErrorSnackbar();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box component="form">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Requesting Facility</TableCell>
                    <TableCell align="right">Blood Group</TableCell>
                    <TableCell align="right">Blood Component</TableCell>
                    <TableCell align="right">Units Requested</TableCell>
                    <TableCell align="right">Selected Product Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {reqFacilityName}
                    </TableCell>
                    <TableCell align="right">{bloodGroup['name']}</TableCell>
                    <TableCell align="right">{bloodComponent['name']}</TableCell>
                    <TableCell align="right">{unitsReq}</TableCell>
                    <TableCell align="right">
                      {selectedProducts.length > 0 ? (
                        <span className="count">
                          {selectedProducts.length}
                        </span>) : (
                        <span>
                          {selectedProducts.length}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={6} md={5} lg={5}>

            <form>
              <p><strong>Product Code/Barcode</strong></p>
              <TextField
                id="products"
                type="text"
                onChange={(v) => setBarcode(v.target.value)} //Add your setVariable to this line
                placeholder="Enter product code/barcode"
                fullWidth
              />

              <Button
                variant="contained"
                onClick={() => {
                  onBarcodeSubmit(barcode);
                }}
                size="small"
                sx={{ mt: 2, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200 }}
                disabled={selectedProducts.length === unitsReq}>
                {loadingBarcodeBtn ? 'Please wait ...' : 'Check product'}
              </Button>

            </form>
          </Grid>

          <Grid item xs={6} md={7} lg={7}>
            {showError ? <Alert severity="error" sx={{ mt: 7 }}>Product not found</Alert> : null}
            {isShown && (
              <Alert severity={isMatch ? "success" : "error"} sx={{ mt: 0 }}>
                {isMatch ? "Matched Product!" : "Product Doesn't Match Request"}
                <TableContainer sx={{ width: "100%", mt: 2, mb: 2 }} component={Paper}>
                  <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                    <TableHead sx={{ pl: "15px", pr: "15px" }}>
                      <TableRow>
                        <TableCell>Blood Type</TableCell>
                        <TableCell align="right">Blood Component</TableCell>
                        <TableCell align="right">Expiry Date</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {product.blood_type ? product.blood_type["name"] : null}
                        </TableCell>
                        <TableCell align="right">
                          {product.blood_component ? product.blood_component["name"] : null}
                        </TableCell>
                        <TableCell align="right">
                          {product.expiration_date ? product.expiration_date : null}
                        </TableCell>

                        {isMatch ? (
                          <TableCell align="right">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                addProduct(product);
                              }}
                              sx={{ backgroundColor: "#0046AF", color: "#fff" }}
                              disabled={isAdded}
                            >
                              {isLoading ? 'Adding ...' : '+ Add'}
                            </Button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Alert>
            )}
          </Grid>
        </Grid>

        <Button
          onClick={() => {
            fullfillRequest(product);
          }}
          variant="contained"
          sx={{ mt: 7, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200 }}
          disabled={selectedProducts.length === 0}
        >
          {isLoading ? 'Please wait ...' : 'Complete Order'}
        </Button>
      </Box>

      <SuccessAlert message={'Blood has been issued successfully !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to fulfill, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

    </Box>
  );
}

