import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LoginImage from "../../assets/images/loginbg.jpg";
import LogoLight from "../../assets/images/logo-light.png";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "./AuthRepository";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Copyright(props) {
  return (
    <Typography variant="body2" color="textsecondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.waladigital.io/" target="_blank" style={{ color: "#fff" }}>
        Wala Digital Health
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function LoginPage() {
  const { handleSubmit, control } = useForm();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = React.useState(false);

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const saveUserData = (user) => {
    AuthRepository.saveToken(user.token);
    delete user.token;
    AuthRepository.saveUser(user);
  };

  const onSubmit = async (data) => {
    await signIn(data.email, data.password);
  };

  const signIn = async (email, password) => {
    setLoading(true);
    const authData = {
      email: email,
      password: password
    };

    try {
      const user = await AuthRepository.login(authData);
      if (user) {
        setLoading(false);
        saveUserData(user);
        window.location.assign("/dashboard");
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }} className="authpage">
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6}
        sx={{
          backgroundImage: `linear-gradient(135deg, rgba(0,70,175,0.8) 0%, rgba(191,32,38,0.8) 100%), url(${LoginImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left top",
          height: "100vh"
        }}
        className="bgpage"
      >
        <div style={{ position: "absolute", color: "white", top: 30, left: "19%", transform: "translateX(-50%)" }}>
          <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="Wala Health." src={LogoLight} />
        </div>
        <Typography variant="body2" textAlign="center" sx={{ mt: "50%", fontSize: 22, fontWeight: 600, color: "#ffffff" }} gutterBottom>
          Connecting hospitals and donors
          <br /> for critical life-saving transfusions.
          <br />
        </Typography>
        <Typography variant="caption" textAlign="center" display="block" sx={{ mt: 2, color: "#ffffff" }} gutterBottom>
          A mission for better health.
        </Typography>

        <Typography variant="caption" align="center" sx={{ position: "absolute", bottom: 0, left: "11%", color: "#ffffff" }}>
          <Copyright sx={{ mb: 2, color: "#ffffff", fontSize: 11 }} />
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            mt: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="Wala Health." src={LogoLight} />

          <Typography component="h3" variant="h5" sx={{ mt: 3, mb: 2 }}>
            <strong>Login</strong>
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {showError
              ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                <AlertTitle>Login Failed</AlertTitle>
                {errorMessage}
              </Alert>
              : <div />
            }
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Email is required", pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email address is invalid"
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  type="email"
                  label="Email Address"
                  autoComplete="email-address"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  variant="outlined"
                  color="secondary"
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: "Password is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  variant="outlined"
                  color="secondary"
                  autoComplete="current-password"
                  helperText={error ? error.message : null}
                />
              )}
            />
            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Keep me signed in"
                />
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <Link to="/forgotpassword" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>

            <Box textAlign="center">
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 4, mb: 2, pr: 3, pl: 3, width: "100%", height: 45, fontWeight: 600, letterSpacing: 3 }} disabled={loading}>
                {loading ? "Please wait ..." : "Login"}
              </Button>
            </Box>
          </form>
          <p> Don't have an account? &nbsp;
            <Link to="/register" variant="body2">Create an account.</Link>
          </p>
        </Box>
      </Grid>
    </Grid>
  );
};
