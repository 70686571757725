import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import LoginImage from "../../assets/images/loginbg.jpg";
import LogoLight from "../../assets/images/logo-light.png";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "./AuthRepository";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.waladigital.io/" target="_blank" style={{color:"#fff"}}>
        Wala Digital Health
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function CodeVerification() {
  const navigate = useNavigate();
  const { handleSubmit, control } = useForm();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (data) => {
    await verifyCode(data.code);
  };

  const verifyCode = async (code) => {
    setLoading(true);
    const data = {
      code: code,
      user_id: localStorage.getItem('user_id'),
    };
    try {
      const response = await AuthRepository.verifyCode(data);
      if (response) {
        setLoading(false);
        navigate("/resetpassword");
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  return (
      <Grid container component="main" sx={{ height: "100vh" }} className="authpage">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6}
          sx={{
            backgroundImage: `linear-gradient(135deg, rgba(0,70,175,0.8) 0%, rgba(191,32,38,0.8) 100%), url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left top",
            height: "100vh"
          }}
          className="bgpage"
          >


          <div style={{ position: "absolute", color: "white", top: 30, left: "19%", transform: "translateX(-50%)" }}>
            <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="The house from the offer." src={LogoLight} />
          </div>
          <Typography variant="body2" textAlign="center" sx={{ mt: "50%", fontSize: 22, color: "#ffffff", fontWeight: 600 }} gutterBottom>
            Connecting hospitals and donors<br /> for critical life-saving transfusions.
          </Typography>

          <Typography variant="caption" textAlign="center" display="block" sx={{ mt: 2, color: "#ffffff" }} gutterBottom>
            A mission for better health.
          </Typography>

          <Typography variant="caption" align="center" sx={{ position: "absolute", bottom: 0, left: "11%", color: "#ffffff" }}>
            <Copyright sx={{ mb: 2, color: "#ffffff", fontSize: 11 }} />
          </Typography>
        </Grid>


        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, mt: 15, display: "flex", flexDirection: "column", alignItems: "center", }}>
            <Typography component="h1" variant="h5" sx={{ mb: 5, fontWeight: 600 }}>
              Verification Code
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'center'}}>
              {showError
                ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                  <AlertTitle>Verification Failed</AlertTitle>
                  {errorMessage}
                </Alert>
                : <div/>
              }
              <Controller
                name="code"
                control={control}
                defaultValue=""
                rules={{ required: "Code is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="code"
                    label="Enter Code"
                    name="code"
                    value={value}
                    onChange={onChange}
                    autoFocus
                    error={!!error}
                    fullWidth
                    color="secondary"
                    inputProps={{ maxLength: 6, style: {textAlign: 'center', letterSpacing: 30, fontWeight: 600 }}}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Box textAlign="center">
                <Typography component="p" sx={{ pr: 7, pl: 7, pt: 1, color: "#777", fontSize: 12 }}>
                  Please enter the <b>verification code</b> from your email inbox.
                </Typography>

                <Button type="submit" color="secondary" variant="contained" disabled={loading} sx={{ mt: 4, mb: 2, pl: 3, pr: 3, fontWeight: 600, width: 250, height: 45, letterSpacing: 2.5 }}>
                  { loading ? 'Please wait ...' : 'Submit' }
                </Button>

                <br />

                <Link to="/login" variant="body2">
                  Back to Login
                </Link>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
  );
}
