import './AdminUser.css';
import * as React from 'react';
import Title from './../../components/Title';
import Typography from '@mui/material/Typography';
import SettingsTab from './components/SettingsTab';
import AuthRepository from '../auth/AuthRepository';
import SettingsRepository from "./SettingsRepository";
import { enableTrackingForMetrics } from "../../libs/activityTracking";

const Settings = () => {
  const user = AuthRepository.getUser();

  const [facilityUsers, setFacilityUsers] = React.useState([]);
  const [positionList, setPositionList] = React.useState([]);

  const getPositions = async () => {
    const positions = await AuthRepository.getPositions();
    setPositionList(positions);
  };

  const getFacilityUsers = async () => {
    const userList = [];
    const user = await AuthRepository.getUser();
    const response = await SettingsRepository.getFacilityUsers(user.facility_id);

    response.forEach(value => {
      userList.push({
        "id": value.id,
        "First Name": value.firstname,
        "Last Name": value.lastname,
        "Email": value.email,
        "Can Access": "Everything",
      });
    });

    setFacilityUsers(userList);
  };

  React.useEffect(() => {
    getFacilityUsers();
    getPositions();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>

    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        Settings
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 1, fontSize: 11, color: "#202020" }}>
        settings <strong>Settings</strong>
      </Typography>
    </div>

    <SettingsTab />
  </>
}

export default function SettingsPage() {
  return <Settings />;
}
