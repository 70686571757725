import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, Controller } from "react-hook-form";
import SuccessAlert from "../../../components/SuccessAlert";
import ErrorAlert from "../../../components/ErrorAlert";
import MenuItem from "@mui/material/MenuItem";
import DonationManagementRepository from './../DonationManagementRepository';
import InventoryManagementRepository from "../../inventory/InventoryManagementRepository";

export default function QuestionTabs(props) {
  const donorId = props.donorID;
  const donationID = props.donationID;
  const closeFunction = props.closeFunction;
  const { handleSubmit, control } = useForm();
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [bloodTypeList, setBloodTypeList] = React.useState([]);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const getBloodTypes = async () => {
    const data = await InventoryManagementRepository.getBloodTypes();
    setBloodTypeList(data);
  };

  const onSubmit = async (data) => {
    await updateDonation(data);
    await updateDonor(data.bloodType);
    closeFunction();
  };

  const updateDonation = async (data) => {
    setIsLoading(true);
    const body = {
      id: donationID,
      screen_hiv: data.screen_hiv === 'passed' ? 1 : 0,
      screen_hepatitisB: data.screen_hepatitisB === 'passed' ? 1 : 0,
      rhesus: data.rhesus === 'Positive' ? "Positive" : "Negative",
      screen_hepatitisC: data.screen_hepatitisC === 'passed' ? 1 : 0,
      screen_syphilis: data.screen_syphilis === 'passed' ? 1 : 0,
      post_status: data.post_status === 'yes' ? "Passed" : "Failed",
      reason: data.reason,
      blood_type_id: data.bloodType
    };

    try {
      const donation = await DonationManagementRepository.updateDonation(body);
      if (donation) {
        showSuccessSnackbar();
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      showErrorSnackbar();
    }
  };

  const updateDonor = async (data) => {
    setIsLoading(true);
    const body = {
      id: donorId,
      blood_type_id: data
    };

    try {
      const donor = await DonationManagementRepository.updateDonor(body);
      if (donor) {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getBloodTypes();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <p><small>Donor Blood Group</small></p>
            <Controller
              name="bloodType"
              control={control}
              defaultValue=""
              rules={{ required: 'Donor Blood Type is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "50%" }}
                  helperText={error ? error.message : null}
                >
                  {bloodTypeList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <FormControl>
              <p><strong>Rhesus (Rh)</strong></p>
              <Controller
                name="rhesus"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="Positive" control={<Radio color="text" />} label="Positive" />
                    <FormControlLabel value="Negative" control={<Radio color="text" />} label="Negative" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <FormControl>
              <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: 600 }}>HIV/AIDS Screening</p>
              <Controller
                name="screen_hiv"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="passed" control={<Radio color="text" />} label="Passed" />
                    <FormControlLabel value="failed" control={<Radio color="text" />} label="Failed" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <FormControl>
              <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: 600 }}>Syphilis Screening</p>
              <Controller
                name="screen_syphilis"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="passed" control={<Radio color="text" />} label="Passed" />
                    <FormControlLabel value="failed" control={<Radio color="text" />} label="Failed" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <FormControl>
              <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: 600 }}>Heptatitis B Screening</p>
              <Controller
                name="screen_hepatitisB"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="passed" control={<Radio color="text" />} label="Passed" />
                    <FormControlLabel value="failed" control={<Radio color="text" />} label="Failed" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4} md={3} lg={3}>
            <FormControl>
              <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: 600 }}>Hepatitis C Screening</p>
              <Controller
                name="screen_hepatitisC"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="passed" control={<Radio color="text" />} label="Passed" />
                    <FormControlLabel value="failed" control={<Radio color="text" />} label="Failed" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <FormControl>
              <p style={{ marginBottom: "5px", fontSize: "14px", fontWeight: 600 }}>Add to Inventory?</p>
              <Controller
                name="post_status"
                control={control}
                defaultValue=""
                rules={{ required: 'Field is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel value="yes" onClick={() => setShow(false)} control={<Radio color="text" />} label="Yes" />
                    <FormControlLabel value="no" onClick={() => setShow(true)} control={<Radio color="text" />} label="No" />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>

          {show ? (
            <Grid item xs={3} md={5} lg={5} sx={{ mt: -1 }}>
              <p><strong>Reason for Rejecting Product</strong></p>
              <Controller
                name="reason"
                control={control}
                defaultValue=""
                rules={{ required: 'Reason is required' }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    id="reason"
                    multiline
                    rows={2}
                    type="text"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>) : null}
        </Grid>

        <Button type="submit" variant="contained" sx={{ mt: 7, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200 }}>
          {isLoading ? 'Please wait ...' : 'Submit'}
        </Button>
      </form>

      <SuccessAlert message={'Donation info updated successfully !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to update donation info, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </Box>
  );
}

