import "./../Billing.css";
import moment from 'moment';
import * as React from "react";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PaystackButton } from 'react-paystack';
import TableHead from '@mui/material/TableHead';
import AuthRepository from "../../auth/AuthRepository";
import SettingsRepository from './../../settings/SettingsRepository';

export default function InvoiceModal(props) {
  let openModal = props.open;
  const amountDue = props.amountdue;
  const [facility, setFacility] = React.useState({});
  const rows = [props.invoice];
  const supplier = rows[0]['supplier_facility']['name'];
  const supplierId = rows[0]['supplier_facility']['id'];
  const recipient = rows[0]['facility']['name'];
  const dateGen = rows[0]['createdAt'];
  const handleCloseModal = props.closeModal;

  const user = AuthRepository.getUser();
  const publicKey = "pk_test_adb2a40bb30dac52e6ee5f452949e2977a1d989a"
  const currency = "GHS"
  const email = user["email"]
  const name = user["facility"]["name"]
  const phone = user["phone"]

  const componentProps = {
    email,
    currency,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => alert("Thanks for doing business with us!"),
    onClose: () => alert("Wait! You haven't completed the payment."),
  }

  const [open, setOpen] = React.useState(openModal);

  const getFacility = async () => {
    const user = AuthRepository.getUser();
    const facility = await SettingsRepository.getFacility(user.facility_id);
    setFacility(facility);
  };

  React.useEffect(() => {
    setOpen(openModal);
    getFacility();
  }, [openModal]);

  return (
    <React.Fragment>

      <div style={{ padding: 20 }}>
        <h3 align="center" style={{ background: "#000", color: "#fff" }}>INVOICE</h3>

        <PaystackButton className="paystack-button invbtn"  {...componentProps} amount={amountDue * 100} />

        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <p><strong>PAY TO:</strong>
              <br />
              {supplierId === facility.id ? (
                <div>
                  <small>{user.facility['name']}</small>
                  <br />
                  <small>{facility.address}</small>
                </div>
              ) : (
                <div>
                  <small>{supplier}</small>
                  <br />
                  <small>{facility.address}</small>
                </div>
              )}
            </p>
          </Grid>

          <Grid item xs={6} md={6} lg={6} justify='flex-end'>
            <img src={facility.photo} height="80px" style={{ float: "right" }}  alt=""/>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <p><strong>CUSTOMER:</strong>
              <br />
              {supplierId === facility.id ? (
                <div>
                  <small>{recipient}</small>
                  <br />
                  <small>{facility.address}</small>
                </div>
              ) : (
                <div>
                  <small>{user.facility['name']}</small>
                  <br />
                  <small>{facility.address}</small>
                </div>
              )}

            </p>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <p align="right"><strong>Invoice #:</strong> {112233}</p>
            <p align="right"><strong>Date Generated:</strong> {moment(dateGen).format('ll')}</p>
            <p align="right"><strong>Amount Due:</strong> GHS {amountDue}</p>
          </Grid>
        </Grid>

        <br />

        <Table size="small">
          <TableHead style={{ background: "#f0f0f0" }}>
            <TableRow>
              <TableCell>Request Type</TableCell>
              <TableCell>Blood Type</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>Unit Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.request_type['name']}</TableCell>
                <TableCell>{row.blood_type['name']}</TableCell>
                <TableCell>{row.units}</TableCell>
                <TableCell>GHS {amountDue / row.units}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>


        <h3 align="right"><strong>Total Amount Due:</strong> <span style={{ border: "1px solid #aaa", padding: "10px" }}> GHS {amountDue}</span></h3>

      </div>

    </React.Fragment>
  );
}
