import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Tooltip  from '@mui/material/Tooltip';
import AuthRepository from "../../auth/AuthRepository";
import InventoryManagementRepository from './../InventoryManagementRepository';


export default function InventoryUnits(props) {
	const md = props.md;
	const lg = props.lg;
	const xs = props.xs;
	const units = props.units;

	const [expiring, setExpiring] = React.useState([]);

	const getExpiringProducts = async () => {
	    const user = AuthRepository.getUser();
	    const expiring = await InventoryManagementRepository.expiringProducts(user.facility_id);
	    setExpiring(expiring);
	  };

	 React.useEffect(() => {
	    getExpiringProducts();
	  }, []);

	return (
		<React.Fragment>
			{units.map((unit, i) => (
				<Grid item key={i} xs={xs} md={md} lg={lg}>
					<Box sx={{ maxWidth: 275 }}>
					<Tooltip title={unit.count < 5 ? "low inventory": unit.count + " units"} arrow>
						<Card variant="outlined" style={{ p: 0, backgroundColor: unit.count < 5 ?  "pink" : "#fff"  }}>
							<CardContent>
								<Typography variant="h5" textAlign='center' component="div" sx={{ fontWeight: 600 }}>
									{unit['blood_type.name']}
								</Typography>
								<Typography variant="body2" textAlign='center' component="div">
									<small>Units</small> <strong>{unit.count}</strong>
								</Typography>
								<Divider variant="middle" sx={{ mt: 2, mb: -2, borderBottomWidth: 5, borderRadius: 5, backgroundColor: "rgba(224, 69, 55, 1)" }} />
							</CardContent>
						</Card>
					</Tooltip>
					</Box>
				</Grid>
			))}
		</React.Fragment>
	);
}
