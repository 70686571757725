import moment from 'moment';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Title from './../../components/Title';
import ButtonGroup from '@mui/material/ButtonGroup';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CallIcon from '@mui/icons-material/Call';
import MUIDataTable from "mui-datatables";
import AuthRepository from '../auth/AuthRepository';
import RequestManagementRepository from './RequestManagementRepository';
import PendingRequestsRepository from "../dashboard/pending-requests/PendingRequestsRepository";
import SuccessAlert from "../../components/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogActions, DialogContent, useMediaQuery } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@mui/styles";

const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  enableNestedDataAccess: '.',
  responsive: 'vertical',
  rowsPerPage: 30,
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No blood requests.",
    },
  },
};

const ButtonTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));


const BloodRequest = () => {
  const [open, setOpen] = React.useState(false);
  const [outgoingrequests, setOutgoingRequests] = React.useState([]);
  const [facilityname, setFacilityName] = React.useState([]);
  const [facilityphone, setFacilityPhone] = React.useState([]);
  const [contactfirstname, setContactFN] = React.useState([]);
  const [contactlastname, setContactLN] = React.useState([]);
  const [requestId, setRequestId] = React.useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCancelClose = () => setOpenCancel(false);
  const handleCancelOpen = (requestId) => {
    setOpenCancel(true);
    setRequestId(requestId);
  };
  const [openCancel, setOpenCancel] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const showSupplierDialog = (facility_name, phone, contact_firstname, contact_lastname) => {
    setFacilityName(facility_name);
    setFacilityPhone(phone);
    setFacilityPhone(phone);
    setContactFN(contact_firstname);
    setContactLN(contact_lastname);
    handleOpen();
  };

  const user = AuthRepository.getUser();

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    setErrorSnackbar(false);
  };

  const cancelRequest = async (requestId, facilityId) => {
    setLoading(true);
    try {
      const data = await PendingRequestsRepository.cancelRequest(requestId, facilityId);
      if (data) {
        handleCancelClose();
        setLoading(false);
        showSuccessSnackbar();
        await getOutgoingRequests();
      }
    } catch (e) {
      handleCancelClose();
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const columns = [
    { name: 'id', label: 'ID', options: { sort: true, filter: false, display: false } },
    {
      name: 'approved_declined_user', label: 'Type', options: {
        sort: true, filter: false, display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['phone']
        )
      }
    },
    {
      name: 'created_at', label: 'Request Date', options: {
        sort: true,
        filter: false,
        display: true,
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
      },
    },
    {
      name: 'blood_type', label: 'B. Type', options: {
        sort: true, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: 'blood_component', label: 'Component'.toUpperCase(), options: {
        sort: false, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name'])
      }
    },
    {
      name: 'request_type', label: 'Type', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    { name: 'units', label: 'Units', options: { sort: true, filter: false } },
    {
      name: 'supplier_facility', label: 'Supplier', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ButtonTooltip
                title="Contact Supplier"
                placement="left-start"
                onClick={() => {
                  showSupplierDialog(
                    tableMeta.rowData[7]['name'],
                    tableMeta.rowData[1]['phone'],
                    tableMeta.rowData[1]['firstname'],
                    tableMeta.rowData[1]['lastname']
                  )
                }}
              >
                {
                  value['name'] !== null ? (
                    <IconButton aria-label="call" size="small" sx={{ backgroundColor: "green", color: "#fff", mr: 1 }}>
                      <CallIcon sx={{ fontSize: "12px" }} />
                    </IconButton>
                  ) : (
                    <Box />
                  )
                }
              </ButtonTooltip>
              {value['name'] !== null ? value['name'] : '-'}
            </>
          )
        }
      }
    },
    {
      name: 'status', label: 'Status'.toUpperCase(), options: {
        sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: "ACTION",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {
                tableMeta.rowData[8]['name'] === 'Pending' ?
                  <ButtonGroup color="secondary" size="small" label="small">
                    <Button size="small" type="icon" onClick={() => handleCancelOpen(tableMeta.rowData[0])} sx={{ backgroundColor: "red", color: "#fff", fontSize: 11, ml: 1, fontWeight: 600 }}>
                      Cancel
                    </Button>
                  </ButtonGroup> : null
              }
            </>
          );
        },
        filter: false,
        display: true,
      }
    }
  ];

  const getOutgoingRequests = async () => {
    const data = await RequestManagementRepository.getOutgoingRequests(user.facility_id);
    setOutgoingRequests(data.reverse());
  };

  React.useEffect(() => {
    getOutgoingRequests();
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        Request Blood
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 11, color: "#202020" }}>
        <strong>Outgoing Blood Request</strong>
      </Typography>
    </div>

    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box textAlign="center">
        <MUIDataTable
          className="muidatatable"
          data={outgoingrequests}
          columns={columns}
          options={options}
        />

      </Box>
    </Paper>


    <Dialog open={open} onClose={handleClose}>
      <Typography variant="subtitle1" component="div" sx={{ p: 2, pb: 0 }}>
        Supplier: <strong>{facilityname}</strong>
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ p: 2, pb: 0 }}>
        Contact Person: <strong>{contactfirstname}</strong> <strong>{contactlastname}</strong>
      </Typography>
      <Typography variant="subtitle1" component="div" sx={{ p: 2 }}>
        Phone: <strong>{facilityphone}</strong>
      </Typography>
    </Dialog>

    <Dialog fullScreen={fullScreen} open={openCancel} onClose={handleCancelClose} aria-labelledby="confirm-cancel-dialog">
      <DialogTitle id="responsive-dialog-title">
        Cancel Request?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this request? NOTE: Cancelling a request is a permanent action and
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancelClose}>
          No
        </Button>
        <Button disabled={loading} color="error" onClick={() => cancelRequest(requestId, user.facility_id)}>
          { loading ? 'Please wait...' : 'Yes - Cancel Request' }
        </Button>
      </DialogActions>
    </Dialog>

    <SuccessAlert message={'Request cancelled successfully!'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    <ErrorAlert message={'Oops! Failed to cancel request, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

  </>
}

export default function OutgoingRequests() {
  return <BloodRequest />;
}
