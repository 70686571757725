import moment from 'moment';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { useLocation } from "react-router-dom";
import "../InventoryManagement.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import ErrorAlert from "../../../components/ErrorAlert";
import { CloseOutlined } from "@mui/icons-material";
import SuccessAlert from "../../../components/SuccessAlert";
import Barcode from "react-jsbarcode";
import InventoryManagementRepository from "../InventoryManagementRepository";
import AuthRepository from "../../auth/AuthRepository";
import Alert from '@mui/material/Alert';
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
  p: 4,
};

const formLabel = {
  fontWeight: 600,
};

const AddProduct = () => {
  const navigate = useNavigate();
  const user = AuthRepository.getUser();

  const handleClose = () => setOpen(false);
  const { handleSubmit, reset, control } = useForm();
  const [open, setOpen] = React.useState(false);

  const [expiry, setExpiry] = React.useState('');
  const [barcode, setBarcode] = React.useState(null);
  const [bloodType, setBloodType] = React.useState('');
  const [component, setComponent] = React.useState('');
  const [collection, setCollection] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [resetForm, setResetForm] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const [bloodTypeList, setBloodTypeList] = React.useState([]);
  const [bloodComponentList, setComponentList] = React.useState([]);

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
    if (!resetForm) navigate('/inventory');
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const onSubmit = async (data) => {
    await addProduct(data);
  };

  const addContinue = () => {
    setResetForm(true);
  };

  const addProduct = async (data) => {
    setLoading(true);
    const body = {
      status: 1,
      created_by: user.id,
      facility_id: user.facility_id,
      blood_type_id: data.bloodType,
      blood_component_id: data.product,
      product_code: data.product_code,
      blood_source: data.blood_source,
      expiration_date: data.expiryDate,
      source_facility_id: user.facility_id,
      make_available: data.makeAvailable ? 1 : 0,
      received_date: moment(data.receivedDate).format('YYYY-DD-MM'),
    };

    try {
      const response = await InventoryManagementRepository.addProduct(body);
      if (response.id) {
        setLoading(false);
        showSuccessSnackbar();
        reset();
      }
    }
    catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const getBloodTypes = async () => {
    const data = await InventoryManagementRepository.getBloodTypes();
    setBloodTypeList(data);
  };

  const getBloodComponents = async () => {
    const data = await InventoryManagementRepository.getBloodComponents();
    setComponentList(data);
  };

  const location = useLocation();
  React.useEffect(() => {
    getBloodTypes();
    getBloodComponents();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "0 0 200px" }} className="addproduct">
      <div style={{ margin: "40px auto 30px" }}>
        <Title>
          Add Product
        </Title>
        <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 11, color: "#202020" }}>
          Manage Inventory / <strong>Add Product</strong>
        </Typography>
      </div>

      <Typography variant="h5" textAlign='left' component="div" sx={{ mt: 5, fontSize: 15, fontWeight: 600, color: "#464D69" }}>
        Product Detail
        <Divider variant="left" sx={{ mt: 2, mb: 2, backgroundColor: "#ddd" }} />
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={4} lg={4}>
            <p style={formLabel}>Name (Blood Type)</p>
            <Controller
              name="bloodType"
              control={control}
              defaultValue=""
              rules={{ required: 'Blood Type is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={location.state ? location.state[1] : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                >
                  {bloodTypeList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <p style={formLabel}>Product</p>
            <Controller
              name="product"
              control={control}
              defaultValue=""
              rules={{ required: 'Product is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={location.state ? location.state[0] : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                >
                  {bloodComponentList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <p style={formLabel}>Product Code/ID</p>
            <Controller
              name="product_code"
              control={control}
              defaultValue=""
              rules={{ required: "Product code is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  id="product_code"
                  label="Product Code"
                  autoComplete="product_code"
                  variant="outlined"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  sx={{ mt: 0 }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <p style={formLabel}>Collection Date</p>
            <Controller
              name="receivedDate"
              control={control}
              defaultValue=""
              rules={{ required: 'Received date is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="receive_date"
                  type="date"
                  size="small"
                  variant="outlined"
                  value={location.state ? moment(location.state[6]).format('YYYY-DD-MM') : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <p style={formLabel}>Blood Source</p>
            <Controller
              name="blood_source"
              control={control}
              defaultValue="Donation"
              rules={{ required: 'Enter Blood Source' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="blood_source"
                  type="text"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Alert severity="warning"><strong>The expiry date</strong> is calculated automatically based on the collection date</Alert>
          </Grid>
        </Grid>

        <Button type="submit" variant="contained" color="secondary" onClick={addContinue} sx={{ mt: 3, mb: 2, width: 200, height: 50, fontWeight: 600, }} disabled={loading}>
          {loading ? 'Please wait ...' : 'Add product'}
        </Button>
      </form>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleClose}>
            <CloseOutlined />
          </a>

          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="body" component="div" sx={{ mt: 4, fontSize: 13, color: "#202020" }}>
                <strong>CAUTION: </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 5, mb: 2, fontSize: 24, color: "#202020" }}>
                <strong>{component}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Collected" color="secondary" variant="outlined" /> {collection}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Expires" color="error" variant="outlined" /> {expiry}</strong>
              </Typography>
            </Grid>

            <Grid item xs={6} md={6} lg={6} align="center">
              <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 94, color: "#202020" }}>
                <strong>{bloodType}</strong>
              </Typography>

              <Barcode value={barcode} />
            </Grid>

          </Grid>
        </Box>
      </Modal>

      <SuccessAlert message={'Product successfully added !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to add product, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </div>
  </>
}

export default function ProductContent() {
  return <AddProduct />;
}
