import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './../../components/Title';
import DonorCount from './components/donor-count';
import BarChart from './../../components/BarChart';
import DonutChart from './../../components/DonutChart';
import AuthRepository from './../auth/AuthRepository';
import DonationsCount from './components/donations-count';
import RequestWidgets from './components/request-widgets';
import PendingRequests from './pending-requests/PendingRequests';
import BloodInventoryCount from './components/blood-inventory-count';
import InventoryUnits from './../inventory/components/InventoryUnits';
import DonorsEnrolledPerMonth from './components/donor-enrolled-count';
import UpcomingAppointments from './upcoming-appointments/UpcomingAppointments';
import InventoryManagementRepository from '../inventory/InventoryManagementRepository';

const DashboardContent = () => {
  const user = AuthRepository.getUser();
  const runsDonations = user.facility.runs_donations;

  const [unitCount, setUnitCount] = React.useState([]);
  const [barChartLabels, setBarChartLabels] = React.useState([]);
  const [barChartDatasets, setBarChartDatasets] = React.useState([]);
  const [donutChartLabels, setDonutChartLabels] = React.useState([]);
  const [donutChartDatasets, setDonutChartDatasets] = React.useState([]);

  const initDonutChartLabels = (counts) => {
    const labels = counts.map((item) => item['blood_type.name']);
    setDonutChartLabels(labels);
  }

  const initDonutChartData = (counts) => {
    const data = counts.map((item) => item.count);
    setDonutChartDatasets(data);
  }

  const initBarChartLabels = (counts) => {
    const labels = counts.map((item) => item['blood_component.name']);
    setBarChartLabels(labels);
  }

  const initBarChartDatasets = (counts) => {
    const data = counts.map((item) => item.count);
    setBarChartDatasets(data);
  }

  const getBloodTypeProductUnitsCount = async () => {
    const user = await AuthRepository.getUser();
    const counts = await InventoryManagementRepository.getUnitsOfProductByBloodType(user.facility_id);
    initDonutChartLabels(counts);
    initDonutChartData(counts);
    setUnitCount(counts);
  };

  const getProductUnitsCount = async () => {
    const user = await AuthRepository.getUser();
    const counts = await InventoryManagementRepository.getUnitsOfProductByBloodComponent(user.facility_id);
    initBarChartLabels(counts);
    initBarChartDatasets(counts);
  };

  React.useEffect(() => {
    getProductUnitsCount();
    getBloodTypeProductUnitsCount();
  }, []);

  return <>
    <Grid container spacing={3}>
      {runsDonations ? (
        <>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <PendingRequests />
            </Paper>
          </Grid>

          {/* Upcoming appointments */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <UpcomingAppointments />
            </Paper>
          </Grid>

          <DonationsCount />
          <DonorCount />
          <BloodInventoryCount />
          <DonorsEnrolledPerMonth />
        </>
      ) : (
        <>
          <RequestWidgets />
          <BloodInventoryCount />
        </>
      )}

      <Grid item xs={12} md={4} lg={4}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <React.Fragment>
            <Title>Inventory Overview</Title>
            <div style={{ padding: "22px 10px" }}>
              <DonutChart labels={donutChartLabels} data={donutChartDatasets} />
            </div>
          </React.Fragment>
        </Paper>
      </Grid>
      <Grid item xs={12} md={4} lg={8}>
        <Paper sx={{ p: 2, pb: 6, display: 'flex', flexDirection: 'column', }}>
          <Title sx={{ fontWeight: 600 }}>Units Available In Inventory</Title>
          <Grid container spacing={3} sx={{ mt: 5, mb: 4 }}>
            <InventoryUnits units={unitCount} xs={6} md={3} lg={3} />
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Title>Units By Products</Title>
          <BarChart labels={barChartLabels} data={barChartDatasets} />
        </Paper>
      </Grid>
    </Grid>
  </>
}

export default function Dashboard() {
  return <DashboardContent />;
}
