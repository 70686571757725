import * as React from "react";
import Box from "@mui/material/Box";
import '../InventoryManagement.css';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import FormGroup from '@mui/material/FormGroup';
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import AuthRepository from '../../auth/AuthRepository';
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import FormControlLabel from '@mui/material/FormControlLabel';
import InventoryManagementRepository from './../InventoryManagementRepository';

export default function TransfusionReactionQuestions(props) {
  const productID = props.productID;
  const labelStyle = { fontWeight: 600 };
  const user = AuthRepository.getUser();
  const closeFunction = props.closeFunction;

  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const { handleSubmit, reset, control } = useForm();
  const [resetForm] = React.useState(false);

  const showSucessSnakbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await addReaction(data);
    closeFunction();
  };

  const addReaction = async (data) => {
    setLoading(true);
    const body = {
      source_facility_id: 1,
      created_by: user.id,
      product_id: productID,
      facility_id: user.facility_id,
      diagnosis: data.diagnosis,
      pre_transfusion_hb: data.pre_transfusion_hb,
      reaction_observe_time: data.reaction_observe_time,
      previous_transfusion: data.previous_transfusion ? 1 : 0,
      previous_reaction: data.previous_reaction ? 1 : 0,
      volume_transfused: data.volume_transfused,
      transfusion_start_time: data.transfusion_start_time,
      transfusion_end_time: data.transfusion_end_time,
      transfusion_end_reason: data.transfusion_end_reason,
      specimen: [data.specimen.split(',')],
      symptoms: [data.symptoms.split(',')],
      outcome: [data.outcome.split(',')],
      physician_name: data.physician_name,
      physician_contact: data.physician_contact,
      nurse_name: data.nurse_name,
      nurse_contact: data.nurse_contact,
    };

    try {
      const reaction = await InventoryManagementRepository.addReaction(body);
      if (reaction) {
        setLoading(false);
        showSucessSnakbar();
        if (resetForm) reset();
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };


  return (
    <Box>
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: 16, mb: 6, fontWeight: 600 }}>
        Transfusion Reaction
      </Typography>

      <form style={{ marginTop: 15 }} onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="checkboxes" sx={{ mb: 1 }}>
          <Box className="TReaction">
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Diagnosis</p>
                <Controller
                  name="diagnosis"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Diagnosis is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="diagnosis"
                      autoComplete="diagnosis"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={2} lg={2} sx={{ mt: 2 }}>
                <p style={ labelStyle }>HB before transfusion</p>
                <Controller
                  name="pre_transfusion_hb"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Pre-transfusion HB is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="pre_transfusion_hb"
                      autoComplete="pre_transfusion_hb"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={2} lg={2} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Volume transfused(ml)</p>
                <Controller
                  name="volume_transfused"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Enter blood volume transfused' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="volume_transfused"
                      type="number"
                      size="small"
                      variant="outlined"
                      value={value}
                      error={!!error}
                      onChange={onChange}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Reaction observed at</p>
                <Controller
                  name="reaction_observe_time"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Observation time is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="reaction_observe_time"
                      type="datetime-local"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      defaultValue="2017-05-24T10:30"
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Transfusion start time</p>
                <Controller
                  name="transfusion_start_time"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Transfusion start time is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="transfusion_start_time"
                      type="datetime-local"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      defaultValue="2017-05-24T10:30"
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Transfusion end time</p>
                <Controller
                  name="transfusion_end_time"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Transfusion end time is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="transfusion_end_time"
                      type="datetime-local"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      defaultValue="2017-05-24T10:30"
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Specimen collected (eg. urine, stool)</p>
                <Controller
                  name="specimen"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Specimen are required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="specimen"
                      autoComplete="specimen"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={6} lg={6} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Symptoms (eg. fever, headache)</p>
                <Controller
                  name="symptoms"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Symptoms are required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="symptoms"
                      autoComplete="symptoms"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={6} lg={6} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Outcome (Allergic Reaction, Delayed Haemolysis)</p>
                <Controller
                  name="outcome"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Symptoms are required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="outcome"
                      autoComplete="outcome"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3} lg={3} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Physician name</p>
                <Controller
                  name="physician_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="physician_name"
                      autoComplete="physician_name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3} lg={3} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Physician contact</p>
                <Controller
                  name="physician_contact"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Contact is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="physician_contact"
                      autoComplete="physician_contact"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3} lg={3} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Nurse name</p>
                <Controller
                  name="nurse_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="nurse_name"
                      autoComplete="nurse_name"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} md={3} lg={3} sx={{ mt: 2 }}>
                <p style={ labelStyle }>Nurse contact</p>
                <Controller
                  name="nurse_contact"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Contact is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="nurse_contact"
                      autoComplete="nurse_contact"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 1 }}>
              <Grid item xs={6} md={6} lg={6}>
                <Controller
                  name="previous_transfusion"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel label="Patient had previous transfusion during admission?" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Controller
                  name="previous_reaction"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel label="Patient reacted to previously transfused blood?" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} md={4} lg={4} sx={{ mt: 2 }}>
              <Button type="submit" variant="contained" disabled={loading} sx={{ mt: 0, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200, height: 45 }}>
                {loading ? 'Please wait ...' : 'Submit Info'}
              </Button>
            </Grid>
          </Box>
        </FormGroup>
      </form>

      <SuccessAlert message={'Reaction info added successfully !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to add Reaction info, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </Box>
  );
}
