import * as React from 'react';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from '@mui/material/Backdrop';
import { AlertTitle } from '@material-ui/lab';

export default function SuccessAlert(props) {
  const message = props.message;
  const successSnackbar = props.successSnackbar;
  const closeSuccessSnackbar = props.closeSuccessSnackbar;

  return (
    <React.Fragment>
      <Backdrop id={0} open={successSnackbar} onClick={closeSuccessSnackbar} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Snackbar sx={{ height: "100%" }} open={successSnackbar} autoHideDuration={10000} onClose={closeSuccessSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert onClose={closeSuccessSnackbar} severity="success" sx={{ width: "100%" }}>
            <AlertTitle>Success</AlertTitle>
            { message }
          </Alert>
        </Snackbar>
      </Backdrop>
    </React.Fragment>
  );
}