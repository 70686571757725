import axios from 'axios';

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const AppointmentRepository = {

  /**
   * Add new appointment to inventory.
   * @param {*} appointment
   * @returns
   */
  addAppointment: async (appointment) => {
    const response = await axios.post(`${HOST}/appointments`, appointment);
    return response.data;
  },

  /**
   * Returns all appointments.
   * @returns
   */
  getAllAppointments: async (facilityId) => {
    const response = await axios.get(`${HOST}/appointments/all/${facilityId}`);
    return response.data;
  },

  /**
   * Returns a list of upcoming appointments.
   */
  getUpcomingAppointments: async (facilityId) => {
    const response = await axios.get(`${HOST}/appointments/upcoming/${facilityId}`);
    return response.data;
  },


  /**
   * Get appointment by ID.
   * @returns
   */
  getAppointmentById: async (appointmentId) => {
    const response = await axios.get(`${HOST}/appointments/${appointmentId}`);
    return response.data;
  },


  /**
   * Add new appointment schedule.
   * @param {*} appointmentSchedule
   * @returns
   */
  addAppointmentSchedule: async (appointmentschedule) => {
    const response = await axios.post(`${HOST}/appointments/schedule`, appointmentschedule);
    return response.data;
  },


  /**
   * Update appointment.
   * @param {*} update appointment
   * @returns
   */
  cancelAppointment: async (appointmentId, status) => {
    const response = await axios.put(`${HOST}/appointments/${appointmentId}`, status);
    return response.data;
  },


  /**
   * Returns all appointment schedules.
   * @returns
   */
  getAppointmentSchedules: async (facilityId) => {
    const response = await axios.get(`${HOST}/appointments/schedule/${facilityId}`);
    return response.data;
  },

  /**
   * Remove scheduled appointment.
   * @returns
   */
  deleteAppointmentSchedule: async (scheduleID) => {
    const response = await axios.delete(`${HOST}/appointments/schedule/${scheduleID}`);
    return response.data;
  },

  /**
   * Add new appointment schedule.
   * @param {*} appointmentSchedule
   * @returns
   */
  addBlockedDays: async (appointmentblockeddays) => {
    const response = await axios.post(`${HOST}/appointments/schedule/blocked`, appointmentblockeddays);
    return response.data;
  },


  /**
   * Returns all appointment blocked days.
   * @returns
   */
  getAppointmentBlockedDays: async (facilityId) => {
    const response = await axios.get(`${HOST}/appointments/schedule/blocked/${facilityId}`);
    return response.data;
  },


  /**
   * Remove all appointment blocked days.
   * @returns
   */
  deleteAppointmentBlockedDays: async (blockedID) => {
    const response = await axios.delete(`${HOST}/appointments/schedule/blocked/${blockedID}`);
    return response.data;
  },


};

export default AppointmentRepository;