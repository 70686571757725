import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './../../components/Title';
import PasswordChange from './ProfileComponents';
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import MuiPhoneNumber from 'material-ui-phone-number';
import './Profile.css';
import AuthRepository from "../auth/AuthRepository";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import {CloseOutlined} from "@mui/icons-material";
import {useState} from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SuccessAlert from "../../components/SuccessAlert";
import { uploadFile } from 'react-s3';
import { enableTrackingForMetrics } from "../../libs/activityTracking";

// Works with S3 image upload
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
    bucketName: 'lagos-state-hospitaldashboard',
    dirName: 'facility_images',
    region: 'eu-west-3',
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY ,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

const upload = (e) => {
  const file = e.target.files[0]

  uploadFile(file, config)
  .then(data => console.log(data))
  .catch(err => console.error(err))
}

const modalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const muiPhoneTextFieldStyle = {
  paddingX: "11px",
  paddingY: "10px",
  marginTop: "15px",
  marginBottom: "12px",
  borderRadius: "6px",
  border: "1px solid #aaa",
};

const Profile = () => {
  const user = AuthRepository.getUser();

  const { handleSubmit, control, setValue } = useForm();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [positionList, setPositionList] = React.useState([]);
  const [phone, setPhone] = useState('');
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  function handleNumberChange(value) {
   setPhone(value);
  }


  React.useEffect(() => {
    getUserDetails();
    getPositions();
    enableTrackingForMetrics(user.id);
  }, []);

  const getUserDetails  = () => {
    const user = AuthRepository.getUser();
    initializeProfile(user);
    setPhone(user.phone);
  };

  const initializeProfile = (user) => {
    setValue('firstname', user.firstname);
    setValue('lastname', user.lastname);
    setValue('email', user.email);
    setValue('phone', user.phone);
    setValue('position', user.position_id);
  };

  const getPositions = async () => {
    const positions = await AuthRepository.getPositions();
    setPositionList(positions);
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (data) => {
    const userId = AuthRepository.getUser().id;
    await updateProfile(userId, data);
  };

  const updateProfile = async (userId, data) => {
    setIsLoading(true);
    dismissErrorAlert();
    const profile = {
      id: userId,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: phone,
      facility_photo: data.photo,
      position_id: data.position,
    };

    try {
      const user = await AuthRepository.updateUser(userId, profile);
      if (user) {
        setIsLoading(false);
        AuthRepository.saveUser(user);
        showSuccessSnackbar();
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  return <>
    <div style={{margin: "40px auto 30px"}}>
      <Title>Profile</Title>
    </div>
    <Grid container id="profilePage" spacing={3}>

      <Grid item xs={12} md={1} lg={1}>

      </Grid>

      <Grid item xs={12} md={10} lg={10}>
        <Paper
          sx={{
            p: 5,
            pb: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <React.Fragment>
            <Title>Profile Details</Title>
            <form onSubmit={handleSubmit(onSubmit)}>
              {showError
                ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                  <AlertTitle>Profile Update Failed</AlertTitle>
                  {errorMessage}
                </Alert>
                : <div/>
              }
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <p>First Name</p>
                  <Controller
                    name="firstname"
                    control={control}
                    defaultValue=""
                    rules={{ required: "First name is required" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="fname"
                        label="First Name"
                        autoComplete="first-name"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <p>Last Name</p>
                  <Controller
                    name="lastname"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Last name is required" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="lname"
                        label="Last name"
                        autoComplete="last-name"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <p>User Email <small>(Change Email</small>)</p>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required", pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Email address is invalid"
                      }
                    }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        type="email"
                        label="Email Address"
                        autoComplete="email-none"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <p>User Number</p>
                  <MuiPhoneNumber
                      id="phone"
                      value={phone}
                      disableAreaCodes={true}
                      fullWidth defaultCountry={'gh'}
                      sx={ muiPhoneTextFieldStyle }
                      onChange={handleNumberChange}
                      InputProps={{ disableUnderline: true }}
                  />
                </Grid>


                <Grid item xs={6}>
                  <p>User Position <small>(Change Position</small>)</p>
                  <Controller
                    name="position"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Position is required" }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        select
                        id="position"
                        label="Select Position"
                        autoComplete="lastname"
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                      >
                        {positionList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={10} lg={10}>
                  <Button
                    onClick={handleOpen}
                    variant="contained"
                    color="error"
                    sx={{
                      mt: 4,
                      mb: 2,
                      mr: 2,
                      width: 200,
                      textTransform: "capitalize",
                    }}
                  >
                    Change Password
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                    sx={{
                      mt: 4,
                      mb: 2,
                      width: 200,
                      textTransform: "capitalize",
                    }}
                  >
                    { isLoading ? 'Please wait ...' : 'Update Profile' }
                  </Button>
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        </Paper>
      </Grid>

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <a
              href="#"
              style={{float: "right", mt: "-20px", textDecoration: "none"}}
              onClick={handleClose}
            >
              <CloseOutlined />
            </a>

            <PasswordChange/>
          </Box>
        </Modal>
      </div>

      <SuccessAlert message={'Profile successfully updated'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar}/>

    </Grid></>
}

export default function ProfilePage() {
  return <Profile/>;
}
