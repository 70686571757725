import * as React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import { AlertTitle } from '@material-ui/lab';

export default function LoadingAlert(props) {
  const title = props.title;
  const openSnackbar = props.openSnackbar;
  const closeSnackbar = props.closeSnackbar;

  return (
    <React.Fragment>
      <Backdrop id={1} open={openSnackbar} onClick={null} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Snackbar sx={{ height: "100%" }} open={openSnackbar} onClose={closeSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="info" sx={{ width: "100%" }}>
            <AlertTitle>{ title }</AlertTitle>
            Please wait ...
          </Alert>
        </Snackbar>
      </Backdrop>
    </React.Fragment>
  );
}
