import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";

export default function TransfusionReactionDetails(props) {
  const getProperDate = (date) => {
    return moment(date).format('lll');
  };

  React.useEffect(() => {
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography sx={{ mt: -6, mb: 1, ml: 2, fontWeight: 600 }} variant="h6" component="div">
          Transfusion Reaction Details
        </Typography>
        {/* Row One */}
        <List>
          <ListItem>
            <ListItemText
              primary="Diagnosis"
              secondary={props.reactionDetails?.diagnosis}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Transfusion HB"
              secondary={props.reactionDetails?.pre_transfusion_hb}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reactive Observed at"
              secondary={getProperDate(props.reactionDetails?.reaction_observe_time)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Previous Transfusion"
              secondary={props.reactionDetails?.previous_transfusion ? 'Yes' : 'No'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Previous Reaction"
              secondary={props.reactionDetails?.previous_reaction ? 'Yes' : 'No'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Volume Transfused"
              secondary={props.reactionDetails?.volume_transfused + ' ml'}
            />
          </ListItem>
        </List>
      </Grid>

      {/* Row Two */}
      <Grid item xs={12} md={4}>
        <Box sx={{ mt: 5, mb: 2 }}/>
        <List>
          <ListItem>
            <ListItemText
              primary="Transfusion Start Time"
              secondary={getProperDate(props.reactionDetails?.transfusion_start_time)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Transfusion End Time"
              secondary={getProperDate(props.reactionDetails?.transfusion_end_time)}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Blood Type"
              secondary={props.reactionDetails?.product.blood_type.name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Blood Component"
              secondary={props.reactionDetails?.product.blood_component.name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Specimen"
              secondary={props.reactionDetails?.specimen.join(',')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Symptoms"
              secondary={props.reactionDetails?.symptoms.join(',')}
            />
          </ListItem>
        </List>
      </Grid>

      {/* Row Three */}
      <Grid item xs={12} md={4}>
        <Box sx={{ mt: 5, mb: 2 }}/>
        <List>
          <ListItem>
            <ListItemText
              primary="Outcome"
              secondary={props.reactionDetails?.outcome.join(',')}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Physician Name"
              secondary={props.reactionDetails?.physician_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Physician Contact"
              secondary={props.reactionDetails?.physician_contact}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Nurse Name"
              secondary={props.reactionDetails?.nurse_name}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Nurse Contact"
              secondary={props.reactionDetails?.nurse_contact}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}

