import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AuthRepository from '../../auth/AuthRepository';
import BillingRepository from '../BillingRepository';
import MUIDataTable from "mui-datatables";
import { Close } from '@mui/icons-material';
import FacilityInvoiceModal from './FacilityInvoiceModal';

const modalStyle = {
  p: 2,
  top: "50%",
  left: "50%",
  width: "70%",
  boxShadow: 24,
  borderRadius: 4,
  overflow: 'scroll',
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function FacilityInvoice(props) {
  const user = AuthRepository.getUser();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [invoiceItem, setInvoiceItem] = React.useState({});

  const [invoices, setInvoices] = React.useState([]);
  const [amount, setAmount] = React.useState('');

  const getFacilityInvoice = async () => {
    const id = user.facility_id;
    const data = await BillingRepository.getFacilityInvoice(id);
    setInvoices(data.reverse());
  };

  const options = {
    rowsPerPage: 30,
    filterType: 'dropdown',
    selectableRows: 'none',
    responsive: "vertical",
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    textLabels: {
      body: {
        noMatch: "No invoices.",
      },
    },
  };

  const columns = [
  { name: 'payment_id', label: 'Invoice Number', options: { filter: false, sort: false, } },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>GHS {tableMeta.rowData[1]}</div>
          );
        }
      }
    },
    {
      name: "createdAt",
      label: "Generated On",
      options: {
        filter: true,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            let from = new Date(filters[0]);
            let to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
        },
        print: false,
      },
    },
    { name: 'status', label: 'Status', options: { sort: true, filter: true } },
    {
      name: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                type="icon"
                color="secondary"
                onClick={() => {
                  setInvoiceItem(tableMeta.rowData);
                  setAmount(tableMeta.rowData[1]);
                  setStatus(tableMeta.rowData[3]);
                  const inv = invoices.filter((i) => i.payment_id === tableMeta.rowData[0])[0];
                  setDescription(inv.description);
                  handleOpen();
                }}
              >
                <VisibilityIcon />
              </Button>
            </>
          );
        }
      }
    }
  ];

  React.useEffect(() => {
    getFacilityInvoice();
  });

  return (
    <Box sx={{ width: '100%' }}>
      <MUIDataTable
        className="muidatatable"
        data={invoices}
        columns={columns}
        options={options}
      />

      <div>
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleClose}>
              <Close />
            </a>
            <FacilityInvoiceModal invoice={invoiceItem} amountdue={amount} status={status} description={description} />
          </Box>
        </Modal>
      </div>
    </Box>

  );
}
