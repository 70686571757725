import * as React from "react";
import Box from "@mui/material/Box";
import "./../InventoryManagement.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Barcode from "react-jsbarcode";
import { CloseOutlined } from "@mui/icons-material";
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const modalStyle = {
  p: 4,
  top: "45%",
  left: "50%",
  width: "50%",
  boxShadow: 24,
  borderRadius: 3,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function ProductViewModal(props) {
  let openModal = props.open;
  const expiry = props.expiry;
  const barcode = props.barcode;
  const bloodType = props.bloodtype;
  const collected = props.collected;
  const component = props.component;
  const handleCloseModal = props.closeModal;
  const [open, setOpen] = React.useState(openModal);

  const handleClose = () => {
    openModal = false;
    setOpen(openModal);
    handleCloseModal();
  };

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <React.Fragment>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleClose}>
            <CloseOutlined />
          </a>

          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="body" component="div" sx={{ mt: 4, fontSize: 13, color: "#202020" }}>
                <strong>ATTENTION: </strong> <br />
                <strong>STORE</strong> at +2-6 degrees Celcius. <br />
                <strong>TRANSPORT</strong> at +2-10 degrees Celcius
              </Typography>
              <br />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%", border: "1px solid #ddd" }} aria-label="simple table">
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" sx={{ letterSpacing: 2.4 }}>HIV I & II</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>NEGATIVE</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" sx={{ letterSpacing: 2.4 }}>Hbs Ag</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>NEGATIVE</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" sx={{ letterSpacing: 2.4 }}>HCV</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>NEGATIVE</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" sx={{ letterSpacing: 2.4 }}>SYPHILIS</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 600 }}>NEGATIVE</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="body" component="div" sx={{ mt: 5, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Collected" color="secondary" variant="outlined" /> {collected}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Expires" color="error" variant="outlined" /> {expiry}</strong>
              </Typography>
            </Grid>


            <Grid item xs={6} md={6} lg={6} align="center">
              <Typography variant="body" component="div" sx={{ mt: 2, mb: 0, fontSize: 24, color: "#202020" }}>
                <strong>
                  {component}
                </strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 94, color: "#202020" }}>
                <strong>{bloodType}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 0, mb: 1, fontSize: 24, color: "#202020" }}>
                <strong>
                  RhD: {bloodType.includes("+") ? "Positive" : "Negative"}
                </strong>
              </Typography>

              <Barcode value={barcode} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
