import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Title from './../../../components/Title';
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import AuthRepository from "../../auth/AuthRepository";
import PendingRequestsRepository from './PendingRequestsRepository';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

export default function PendingRequests() {
  let [rows, setRows] = React.useState([]);
  let [status, setStatus] = React.useState([]);

  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const [requestId, setRequestId] = React.useState([]);
  const [facilityId, setFacilityId] = React.useState([]);

  const user = AuthRepository.getUser();

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const handleClickOpen = (value) => () => {
    setOpenDialogue(true);
    setRequestId(value);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };

  const getProperDate = (date) => {
    return moment(date).fromNow();
  };

  const getRequestStatuses = async () => {
    const data = await PendingRequestsRepository.getRequestStatuses();
    setStatus(data);
  };

  const getPendingRequests = async () => {
    const facilityId = AuthRepository.getUser().facility_id;
    const data = await PendingRequestsRepository.getPendingRequests(facilityId);
    setRows(data.splice(0, 5));
  };

  const approveRequest = async (requestId) => {
    try {
      const data = await PendingRequestsRepository.approveRequest(requestId, user.id, user.facility_id);
      if (data) {
        handleDialogueClose();
        showSuccessSnackbar();
        await getPendingRequests();
        window.location.assign("/requests/accepted");
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const getFacilityId = () => {
    const facilityId = AuthRepository.getUser().facility_id;
    setFacilityId(facilityId);
  };

  React.useEffect(() => {
    getFacilityId();
    getPendingRequests();
    getRequestStatuses();
  }, []);

  return (
    <React.Fragment>
      <Title>
        <Grid container spacing={2}>
          <Grid item xs={9}>Incoming blood requests</Grid>
        </Grid>
      </Title>
      {
        rows.length !== 0 ? (
          <>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Requestor</TableCell>
                  <TableCell>Time Requested</TableCell>
                  <TableCell>Blood Type</TableCell>
                  <TableCell>Component</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Request Type</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={row.facility.id === facilityId ? { fontStyle: 'italic', fontWeight: 'bold' } : null}>{row.facility.name}</TableCell>
                    <TableCell>{getProperDate(row.created_at)}</TableCell>
                    <TableCell>{row.blood_type.name}</TableCell>
                    <TableCell>{row.blood_component.name}</TableCell>
                    <TableCell>{row.units}</TableCell>
                    <TableCell>{row.request_type.name}</TableCell>
                    <TableCell>
                      <Button variant="contained"
                        onClick={handleClickOpen(row.id)}
                        style={{ background: "green", color: "#fff", textDecoration: 'none', padding: "5px 20px", fontSize: "12px" }}>
                        Accept
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <>
              <Box sx={{ textAlign: "center" }}>
                <p>No incoming blood requests yet!</p>
              </Box>
          </>
        )
      }


      <Dialog
        open={openDialogue}
        onClose={handleDialogueClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        show={requestId}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to fulfill request?"}
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{ backgroundColor: "green", color: "#fff", width: "200px" }}
            onClick={() => approveRequest(requestId)}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessAlert message={'Request Accepted successfully !'} successSnackbar={successSnackbar}
        closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'An error occured. Please try again.'}
        errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

    </React.Fragment>
  );
}
