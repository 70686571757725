import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AuthRepository from "./AuthRepository";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import AlertTitle from "@mui/material/AlertTitle";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CssBaseline from "@mui/material/CssBaseline";
import { Controller, useForm } from "react-hook-form";
import LoginImage from "../../assets/images/loginbg.jpg";
import LogoLight from "../../assets/images/logo-light.png";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiPhoneNumber from 'material-ui-phone-number';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Divider from '@mui/material/Divider';

const muiPhoneTextFieldStyle = {
  marginTop: "9px",
  padding: "10px",
  borderRadius: "6px",
  marginBottom: "12px",
  border: "1px solid #ccc",
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.waladigital.io/" target="_blank" style={{ color: "#fff" }}>
        Wala Digital Health
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function RegisterPage() {
  const facility_types = [
    {
      value: '',
      label: "Select Facility Type",
    },
    {
      value: "hospital",
      label: "Hospital/Clinic",
    },
    {
      value: "bloodbank",
      label: "Blood Bank",
    },
  ];

  const [facility_typeval, setDefault] = React.useState('');

  const handleChange = (event) => {
    setDefault(event.target.value);
  };

  const navigate = useNavigate();
  const { handleSubmit, control, watch } = useForm();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [phone, setPhone] = useState('');

  function handleNumberChange(value) {
    setPhone(value);
  }

  React.useEffect(() => {
    clearAllLocalData();
  }, []);

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (data) => {
    await createAccount(data);
  };

  const createAccount = async (user) => {
    setLoading(true);
    const userData = {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone: phone,
      runs_donations: user.runs_donations === 'Yes' ? 1 : 0,
      keeps_inventory: user.keeps_inventory === 'Yes' ? 1 : 0,
      password: user.password,
      facility_name: user.facility_name,
      facility_type: facility_typeval,
    };

    try {
      const user = await AuthRepository.signup(userData);
      if (user) {
        setOpenDialogue(true);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleClickShowConfirmPassword = () => {
    showConfirmPassword ? setShowConfirmPassword(false) : setShowConfirmPassword(true);
  };

  const clearAllLocalData = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
    navigate("/login");
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }} className="authpage">
      <CssBaseline />
      <Grid item xs={false} sm={4} md={5}
        sx={{
          backgroundImage: `linear-gradient(135deg, rgba(0,70,175,0.8) 0%, rgba(191,32,38,0.8) 100%), url(${LoginImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "left top",
          height: "100vh"
        }}
        className="bgpage"
      >
        <div style={{ position: "absolute", color: "white", top: 30, left: "19%", transform: "translateX(-50%)" }}>
          <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="Wala Health." src={LogoLight} />
        </div>
        <Typography variant="body2" textAlign="center" sx={{ mt: "50%", fontSize: 22, color: "#ffffff", fontWeight: 600 }} gutterBottom>
          Connecting hospitals and donors
          <br /> for critical life-saving transfusions.
          <br />
        </Typography>
        <Typography variant="caption" textAlign="center" display="block" sx={{ mt: 2, color: "#ffffff" }} gutterBottom>
          A mission for better health.
        </Typography>

        <Typography variant="caption" align="center" sx={{ position: "absolute", bottom: 0, left: "11%", color: "#ffffff" }}>
          <Copyright sx={{ mb: 2, color: "#ffffff", fontSize: 11 }} />
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
        <Box id="registration" sx={{ my: 8, mx: 4, mt: 5, display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography component="h3" variant="h5" sx={{ mt: 3, mb: 5 }}>
            <strong>Create Account</strong>
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {showError
              ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                <AlertTitle>Signup Failed</AlertTitle>
                {errorMessage}
              </Alert>
              : <div />
            }
            <Grid container spacing={2}>
              {/* <p>Facility Type:</p> */}
              <Grid item xs={6}>
                <TextField
                  id="facility_type"
                  name="facility_type"
                  label="Select Facility Type"
                  select
                  color="secondary"
                  value={facility_typeval}
                  onChange={handleChange}
                  sx={{ width: "100%", borderRadius: 2 }}
                >
                  {facility_types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="facility_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Facility name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="facility_name"
                      label="Facility Name"
                      autoComplete="facility_name"
                      autoFocus
                      value={value}
                      onChange={onChange}
                      sx={{ mt: 0 }}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>



              <Grid item xs={6} sx={{ mt: -2 }}>
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "First name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="fname"
                      label="First Name"
                      autoComplete="firstname"
                      autoFocus
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: -2 }}>
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Last name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="lname"
                      label="Last name"
                      autoComplete="last-name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required", pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email address is invalid"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      autoComplete="none"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: 1 }}>
                <MuiPhoneNumber
                  id="phone"
                  fullWidth defaultCountry={'gh'}
                  sx={muiPhoneTextFieldStyle}
                  onChange={handleNumberChange}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: -3 }}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Password is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="old-password"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      autoComplete="password-none"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={handleClickShowPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: -3 }}>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm password is required", validate: (val) => {
                      if (watch('password') !== val) {
                        return "Your passwords do no match";
                      }
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      autoComplete="password-none"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                      InputProps={{
                        endAdornment:
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={handleClickShowConfirmPassword}>
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                      }}
                    />
                  )}
                />
              </Grid>

              <br />
              <br />
              <Divider />
              <br />

              <Grid item xs={6} sx={{ mt: -1 }}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Do you keep/manage inventory of blood?</FormLabel>
                  <Controller
                    name="keeps_inventory"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sx={{ mt: -1 }}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Do you enroll donors or manage blood donations?</FormLabel>
                  <Controller
                    name="runs_donations"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box textAlign="center">
              <Button type="submit" variant="contained" color="secondary" sx={{ mt: 3, mb: 2, pr: 3, pl: 3, fontWeight: 600, width: "50%", height: 45, letterSpacing: 2 }} disabled={loading}>
                {loading ? "Please wait ..." : "Create Account"}
              </Button>
              <br />
              <Link to="/login" variant="body2">
                Back to Login
              </Link>
            </Box>
          </form>
        </Box>
      </Grid>

      <Dialog open={openDialogue} onClose={handleDialogueClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Registration successful!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please check your email (inbox & spam folder) for your password
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ backgroundColor: "green", color: "#fff", width: "200px" }} onClick={() => handleDialogueClose()} autoFocus>
            Go to Login page
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
