import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import MenuItem from "@mui/material/MenuItem";
import { request, gql } from 'graphql-request';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CssBaseline from "@mui/material/CssBaseline";
import MuiPhoneNumber from 'material-ui-phone-number';
import { Controller, useForm } from "react-hook-form";
import LoginImage from "../assets/images/loginbg.jpg";
import DialogContent from '@mui/material/DialogContent';
import Visibility from '@mui/icons-material/Visibility';
import DialogActions from '@mui/material/DialogActions';
import LogoLight from "../assets/images/logo-light.png";
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DialogContentText from '@mui/material/DialogContentText';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const muiPhoneTextFieldStyle = {
  paddingX: "7px",
  paddingY: "7px",
  marginTop: "9px",
  marginBottom: "12px",
  borderRadius: "6px",
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.waladigital.io/" rel="noreferrer" target="_blank" style={{ color: "#fff" }}>
        Wala Digital Health
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#0046AF",
    },
    error: {
      main: "#ba000d",
    },
    success: {
      main: "#1faa00",
    },
    text: {
      main: "#000",
    },
  },
});

export default function MobileSignup() {
  const gender = [
    { value: '', label: 'Select Gender' },
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
  ];

  const gqlEndpoint = "https://mobile-api.app.waladigital.io/graphql";
  const [genderValue, setDefault] = React.useState('');

  const { handleSubmit, control, watch } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [phone, setPhone] = useState('');
  const [loading, setLoading] = React.useState(false);

  const [step1, setStep1] = React.useState(true);
  const [step2, setStep2] = React.useState(false);
  const [step3, setStep3] = React.useState(false);

  const handleNumberChange = (value) => {
    setPhone(value);
  };

  const handleChange = (event) => {
    setDefault(event.target.value);
  };

  React.useEffect(() => {
    clearAllLocalData();
  }, []);

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const verifyPhoneNumber = async (data) => {
    const query = gql`
      mutation VerifyPhone($phone: String!) {
        verifyPhone(phone: $phone) {
          sid,
        }
      }
    `;
    const variables = {
      'phone': phone
    };

    const response = await request(gqlEndpoint, query, variables);
    if (response.sid !== null || true) {
      setStep1(false);
      setStep2(true);
    }
  };

  const verifyVerificationCode = async (data) => {
    const query = gql`
      mutation VerifyCode($phone: String!, $code: String!) {
        verifyCode(phone: $phone, code: $code)
      }
    `;
    const variables = {
      'phone': phone,
      'code': data.verificationCode,
    };

    const response = await request(gqlEndpoint, query, variables);
    if (response.verifyCode === true) {
      setStep2(false);
      setStep3(true);
    }
  };

  const submitAccountInfo = async (data) => {
    await createAccount(data);
  };

  const createAccount = async (user) => {
    setLoading(true);
    const query = gql`
      mutation CreateUser($createUserInput: CreateUserInput!) {
        createUser(createUserInput: $createUserInput) {
          id firstname lastname phone email password dial_code iso_code
          gender genotype blood_group photo_url photo_path status dob
          device_token created_at updated_at
        }
      }
    `;
    const variables = {
      'createUserInput': {
        'firstname': user.firstname,
        'lastname': user.lastname,
        'email': user.email,
        'password': user.password,
        'dial_code': '+233',
        'iso_code': 'GH',
        'phone': phone,
        'gender': genderValue,
        'dob': user.dateOfBirth,
        'blood_group': 'Unknown',
        'genotype': 'Unknown',
        'photo_url': '',
        'photo_path': '',
        'device_token': '',
      },
    };

    const response = await request(gqlEndpoint, query, variables);
    if (response) {
      setOpenDialogue(true);
    }
  };

  const handleClickShowPassword = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleClickShowConfirmPassword = () => {
    showConfirmPassword ? setShowConfirmPassword(false) : setShowConfirmPassword(true);
  };

  const clearAllLocalData = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
    window.location.reload();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }} className="authpage">
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: `linear-gradient(135deg, rgba(0,70,175,0.8) 0%, rgba(191,32,38,0.8) 100%), url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left top",
            height: "100vh"
          }}
          className="bgpage"
        >
          <div
            style={{
              position: "absolute",
              color: "white",
              top: 30,
              left: "19%",
              transform: "translateX(-50%)"
            }}
          >
            <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="Wala Health." src={LogoLight} />
          </div>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ mt: "50%", ml: "40px", mr: "40px", fontSize: 18, color: "#ffffff" }}
            gutterBottom
          >
            Get started with Wala Mobile App.
            <br />
            <br />
            <br /> Create and join communities, create posts and share comments, book appointments, manage donor cards and many more.
            <br />
          </Typography>
          <Typography
            variant="caption"
            textAlign="center"
            display="block"
            sx={{ mt: 2, color: "#ffffff" }}
            gutterBottom
          >
            A mission for better health
          </Typography>

          <Typography
            variant="caption"
            align="center"
            sx={{
              position: "absolute",
              bottom: 0,
              left: "11%",
              color: "#ffffff"
            }}
          >
            <Copyright sx={{ mb: 2, color: "#ffffff", fontSize: 11 }} />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            id="registration"
            sx={{
              my: 8,
              mx: 4,
              mt: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >

            <Typography component="h3" variant="h5" sx={{ mt: 3, mb: 2 }}>
              <strong>Create Mobile App User</strong>
            </Typography>

            {showError
              ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                <AlertTitle>Signup Failed</AlertTitle>
                {errorMessage}
              </Alert>
              : <div></div>
            }

            {
              step1 ?
                <form onSubmit={handleSubmit(verifyPhoneNumber)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MuiPhoneNumber
                        id="phone"
                        fullWidth defaultCountry={'gh'}
                        sx={muiPhoneTextFieldStyle}
                        onChange={handleNumberChange}
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ mt: -3 }}>
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Password is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            margin="normal"
                            fullWidth
                            id="old-password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            autoComplete="password-none"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            InputProps={{
                              endAdornment:
                                <InputAdornment position="end">
                                  <IconButton edge="end" onClick={handleClickShowPassword}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6} sx={{ mt: -3 }}>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Confirm password is required", validate: (val) => {
                            if (watch('password') !== val) {
                              return "Your passwords do no match";
                            }
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            margin="normal"
                            fullWidth
                            id="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            label="Confirm Password"
                            autoComplete="password-none"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            InputProps={{
                              endAdornment:
                                <InputAdornment position="end">
                                  <IconButton edge="end" onClick={handleClickShowConfirmPassword}>
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <div>
                    <Box textAlign="center">
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, pr: 3, pl: 3, backgroundColor: "#0046AF", color: "#fff" }}
                        disabled={loading}
                      >
                        {loading ? "Please wait ..." : "Continue"}
                      </Button>
                    </Box>
                  </div>
                </form> :
                <Box />
            }

            {
              step2 ?
                <form onSubmit={handleSubmit(verifyVerificationCode)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mt: -2 }}>
                      <Controller
                        name="verificationCode"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Verification code is required" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            margin="normal"
                            fullWidth
                            id="verifycode"
                            label="Verification code"
                            autoComplete="verify-code"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            sx={{ textAlign: 'center' }}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <div>
                    <Box textAlign="center">
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2, pr: 3, pl: 3, backgroundColor: "#0046AF", color: "#fff" }}
                        disabled={loading}
                      >
                        {loading ? "Please wait ..." : "Verify Continue"}
                      </Button>
                    </Box>
                  </div>
                </form> :
                <Box />
            }

            {
              step3 ?
                <form onSubmit={handleSubmit(submitAccountInfo)}>
                  <Grid container spacing={2}>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={6} sx={{ mt: -2 }}>
                        <Controller
                          name="firstname"
                          control={control}
                          defaultValue=""
                          rules={{ required: "First name is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id="fname"
                              label="First Name"
                              autoComplete="firstname"
                              autoFocus
                              value={value}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} sx={{ mt: -2 }}>
                        <Controller
                          name="lastname"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Last name is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id="lname"
                              label="Last name"
                              autoComplete="last-name"
                              value={value}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sx={{ mt: -2 }}>
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Email address is invalid"
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id="email"
                              type="email"
                              label="Email Address"
                              autoComplete="none"
                              value={value}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} sx={{ mt: -2 }}>
                        <Controller
                          name="dateOfBirth"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Date of birth is required" }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              margin="normal"
                              fullWidth
                              id="dob"
                              label="Date of Birth"
                              autoComplete="dob"
                              autoFocus
                              value={value}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} sx={{ mt: 0 }}>
                        <TextField
                          id="gender"
                          name="gender"
                          label="Select Gender"
                          select
                          value={genderValue}
                          onChange={handleChange}
                          sx={{ width: "100%", borderRadius: 2 }}
                        >
                          {gender.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Box textAlign="center">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, pr: 3, pl: 3, backgroundColor: "#0046AF", color: "#fff" }}
                      disabled={loading}
                    >
                      {loading ? "Please wait ..." : "Create Account"}
                    </Button>
                  </Box>
                </form> :
                <Box />
            }
          </Box>
        </Grid>

        <Dialog
          open={openDialogue}
          onClose={handleDialogueClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Registration successful!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your Wala mobile account have been successfully created.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button sx={{ backgroundColor: "green", color: "#fff", width: "200px" }} onClick={() => handleDialogueClose()} autoFocus>
              Register New User
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </ThemeProvider>
  );
};
