import * as React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/wala-logo.png";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from '@mui/material/Badge';
import MoreIcon from "@mui/icons-material/MoreVert";
import Modal from "@mui/material/Modal";
import MessageTribe from "./MessageTribe";
import AuthRepository from "../pages/auth/AuthRepository";
import SettingsRepository from './../pages/settings/SettingsRepository';
import InventoryManagementRepository from './../pages/inventory/InventoryManagementRepository';
import Popper from '@mui/material/Popper';
import Alert from '@mui/material/Alert';
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4
};

export default function PrimarySearchAppBar() {
  const user = AuthRepository.getUser();
  const [facility, setFacility] = React.useState({});
  const [lowInventory, setLowInventory] = React.useState([]);
  const [expiring, setExpiring] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const notificationCount = lowInventory.length + expiring.length;
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const [anchorElPopper, setAnchorElPopper] = React.useState(null);

  const handleClick = (event) => {
    setAnchorElPopper(anchorElPopper ? null : event.currentTarget);
  };

  let adminUser = user ? user.position.name === "Admin" : null

  const openPopper = Boolean(anchorElPopper);
  const id = openPopper ? 'simple-popper' : undefined;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getFacility = async () => {
    const user = AuthRepository.getUser();
    const facility = await SettingsRepository.getFacility(user.facility_id);
    setFacility(facility);
  };

  const getExpiringProducts = async () => {
    const user = AuthRepository.getUser();
    const expiring = await InventoryManagementRepository.expiringProducts(user.facility_id);
    setExpiring(expiring);
  };

  const getLowInventory = async () => {
    const user = AuthRepository.getUser();
    const low = await InventoryManagementRepository.lowInventoryProducts(user.facility_id);
    setLowInventory(low);
  };

  const signOut = async () => {
    AuthRepository.logout();
    window.location.assign('/login');
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  React.useEffect(() => {
    getFacility();
    getLowInventory();
    getExpiringProducts();
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <div>
      <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuId} keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={isMenuOpen} onClose={handleMenuClose} sx={{ mt: 6.5 }}>
        <Link to="/profile" variant="body2" style={{ textDecoration: "none", color: "#000" }}>
          <MenuItem onClick={handleMenuClose} sx={{ pr: 15 }}>
            Profile
          </MenuItem>
        </Link>

        {adminUser ?
          (<Link
            to="/settings"
            variant="body2"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <MenuItem onClick={handleMenuClose} sx={{ pr: 15 }}>
              Settings
            </MenuItem>
          </Link>
          ) : null}

        <MenuItem onClick={signOut} sx={{ pr: 15 }}>
          Log out
        </MenuItem>
      </Menu>
    </div>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar sx={{ height: "60px" }}>
        <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }}
          alt="Wala Digital Health"
          src={Logo}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", mb: 2, md: "flex" } }}>
          <Button
            onClick={handleOpen}
            variant="outlined"
            startIcon={<MessageIcon sx={{ color: "#BF2026" }} />}
            sx={{
              border: "1px solid #ddd",
              mt: 1,
              mr: 4,
              height: 40,
              color: "#777",
              textTransform: "capitalize",
              ":hover": {
                border: "1px solid #ccc",
              }
            }}>
            Send Message to Tribe
          </Button>

          <IconButton size="large" aria-label="show notifications" color="inherit" onClick={handleClick} sx={{ borderRadius: "0%" }}>
            <Badge badgeContent={notificationCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <Button size="large" edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit" endIcon={<KeyboardArrowDownIcon />}>
            <Avatar alt={facility.name} src={facility.photo} sx={{ mr: 1 }} />
            <Typography variant="div" textAlign="left" sx={{ color: "#000", fontSize: 13 }}>
              {facility.name}
              <Box sx={{ mt: -0.5, fontSize: 11, color: "#A4A4A4" }}>
                {user.firstname} {user.lastname}
              </Box>
            </Typography>
          </Button>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
            <MoreIcon />
          </IconButton>
        </Box>
      </Toolbar>
      {renderMenu}

      <div>
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <a href="#" style={{ float: "right", mt: -5, textDecoration: "none" }} onClick={handleClose}>
              <Close />
            </a>
            <MessageTribe />
          </Box>
        </Modal>
      </div>

      <Popper id={id} open={openPopper} anchorEl={anchorElPopper} className="popper">
        <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
          <Alert
            severity="warning"
            action={
              <Link to="/inventory" variant="body2" style={{ textDecoration: "none", color: "#000" }}>
                <Button variant="outline" color="warning" size="small" sx={{ fontSize: "11px", color: "red" }}>VIEW</Button>
              </Link>
            } sx={{ mb: "5px", pt: "2px", pb: "2px", fontSize: "12px" }}>
            {lowInventory.length} blood groups have low inventory
          </Alert>
          <Alert
            severity="warning"
            action={
              <Link to="/inventory" variant="body2" style={{ textDecoration: "none", color: "#000" }}>
                <Button variant="outline" color="warning" size="small" sx={{ fontSize: "11px", color: "red" }}>VIEW</Button>
              </Link>
            } sx={{ mb: "5px", pt: "2px", pb: "2px", fontSize: "12px" }}>
            {expiring.length} product(s) expiring soon
          </Alert>
        </Box>
      </Popper>
    </Box>
  );
}
