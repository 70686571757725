import React from 'react';
import { Bar as Barchart } from 'chart.js/auto'; // do not remove
import { Bar } from 'react-chartjs-2';

const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
			text: 'Units by products',
		},
	},
};


const BarChart = (props) => {
	const labels = props.labels;
	const data = props.data;

	return <div>
		<Bar
			options={options}
			data={{
				labels: labels,
				datasets: [
					{
						id: 1,
						label: '',
						data: data,
						barThickness: 70,
						backgroundColor: [
							'rgba(51, 102, 204, 1)',
							'rgba(189, 189, 189, 1)',
							'rgba(0, 138, 115, 1)',
							'rgba(0, 138, 5, 1)'
						],
					},
				],
			}}
		/>
	</div>
}

export default BarChart;
