import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Link, useLocation } from "react-router-dom";
import "../DonationManagement.css";
import Button from '@mui/material/Button';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Radio from '@mui/material/Radio';
import Paper from "@mui/material/Paper";
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorAlert from "../../../components/ErrorAlert";
import FormControlLabel from '@mui/material/FormControlLabel';
import AuthRepository from '../../auth/AuthRepository';
import SuccessAlert from "../../../components/SuccessAlert";
import DonationManagementRepository from "../DonationManagementRepository";
import UpcomingAppointmentsRepository from "../../dashboard/upcoming-appointments/UpcomingAppointmentsRepository";
import AppointmentRepository from "../../appointments/AppointmentRepository";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const AddDonor = () => {
    const user = AuthRepository.getUser();

    const { handleSubmit, reset, control } = useForm();
    const [resetForm, setResetForm] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [errorSnackbar, setErrorSnackbar] = React.useState(false);
    const [successSnackbar, setSuccessSnackbar] = React.useState(false);

    const [bloodTypeList, setBloodTypeList] = React.useState([]);
    const [appointmentList, setAppointmentList] = React.useState([]);

    const [getDonorBy, setGetDonorBy] = React.useState([]);
    const [appointment, setAppointment] = React.useState([]);

    const showSuccessSnackbar = () => {
        setSuccessSnackbar(true);
    };

    const showErrorSnackbar = () => {
        setErrorSnackbar(true);
    };

    const closeSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessSnackbar(false);
    };

    const closeErrorSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorSnackbar(false);
    };

    const onSubmit = async (data) => {
        setResetForm(true);
        await addDonor(data);
    };

    const addDonor = async (data) => {
        setLoading(true);
        const donor = {
            facility_id: user.facility_id,
            fname: data.fname,
            lname: data.lname,
            gender: data.gender === 'male' ? "Male" : "Female",
            email: data.email,
            phone: data.phone,
            blood_type_id: data.bloodtype ? data.bloodtype : null,
            units: 1
        };

        try {
            const response = await DonationManagementRepository.addDonor(donor);
            if (response.id) {
                setLoading(false);
                showSuccessSnackbar();
                if (resetForm) reset();
            }
        } catch (e) {
            setLoading(false);
            showErrorSnackbar();
        }
    };

    const getBloodTypes = async () => {
        const data = await DonationManagementRepository.getBloodTypes();
        setBloodTypeList(data);
    };


    const getAppointment = async () => {
        const data = await UpcomingAppointmentsRepository.getUpcomingAppointments();
        setAppointmentList(data);
    };

    const location = useLocation();

    const getAppointmentById = async () => {
        if (location.state != null) {
            const appointmentId = location.state[0];
            const data = await AppointmentRepository.getAppointmentById(appointmentId);
            setAppointment(data);
        }
    };


    React.useEffect(() => {
        if (location.state != null) getAppointmentById();
        getBloodTypes();
        getAppointment();
        enableTrackingForMetrics(user.id);
    }, []);

    return <>
        <div style={{ margin: "0 0 200px" }} className="addproduct">
            <div style={{ margin: "40px auto 30px" }}>
                <Title>
                    <Link to="/donations" variant="body2">
                        <ArrowBackIcon sx={{ mr: 1 }} />
                    </Link>
                    Add Donor</Title>
                <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
                    Manage Donations / <strong>Add Donor</strong>
                </Typography>
            </div>

            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >


                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            {getDonorBy === 'appointments' &&
                                <Grid item xs={6} md={6} lg={6}>
                                    <p>Select Appointment</p>

                                </Grid>
                            }
                            {getDonorBy === 'donors' &&
                                <Grid item xs={6} md={4} lg={4}>
                                    <p>Search by donor phone number</p>

                                </Grid>
                            }

                            {getDonorBy === 'new' &&
                                <Grid item xs={6} md={4} lg={4}>
                                    <p>Add New Donor</p>

                                </Grid>
                            }
                        </Grid>


                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>First Name</p>
                                <Controller
                                    name="fname"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'First name is required' }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            text
                                            id="fname"
                                            label="First Name"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            error={!!error}
                                            onChange={onChange}
                                            sx={{ width: "100%" }}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>Last Name</p>
                                <Controller
                                    name="lname"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Last name is required' }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            text
                                            id="lname"
                                            label="Last Name"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            error={!!error}
                                            onChange={onChange}
                                            sx={{ width: "100%" }}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>Gender</p>
                                <Controller
                                    name="gender"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Field is required' }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={value}
                                            onChange={onChange}
                                        >
                                            <FormControlLabel value="male" control={<Radio color="text" />} label="Male" />
                                            <FormControlLabel value="female" control={<Radio color="text" />} label="Female" />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>Email</p>
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Email is required' }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            text
                                            id="email"
                                            label="Email"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            error={!!error}
                                            onChange={onChange}
                                            sx={{ width: "100%" }}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>Phone</p>
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Phone is required' }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            text
                                            id="phone"
                                            label="Phone"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            error={!!error}
                                            onChange={onChange}
                                            sx={{ width: "100%" }}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormControl>
                                <p>Blood Type</p>
                                <Controller
                                    name="bloodtype"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: false }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <TextField
                                            select
                                            id="bloodtype"
                                            label="Select"
                                            size="small"
                                            variant="outlined"
                                            value={value}
                                            error={!!error}
                                            onChange={onChange}
                                            sx={{ width: "100%" }}
                                            helperText={error ? error.message : null}
                                        >
                                            {bloodTypeList.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Button type="submit" variant="contained"
                        sx={{ mt: 7, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200 }}
                        disabled={loading}>
                        {loading ? 'Please wait ...' : 'Add Donor'}
                    </Button>

                </form>

                <SuccessAlert message={'Donor successfully added !'} successSnackbar={successSnackbar}
                    closeSuccessSnackbar={closeSuccessSnackbar} />
                <ErrorAlert message={'Failed to add donor. Email or phone already exists.'}
                    errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
            </Paper>
        </div>
    </>
}

export default function ProductContent() {
    return <AddDonor />;
}
