import * as React from "react";
import Box from "@mui/material/Box";
import "./../InventoryManagement.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import AuthRepository from "../../auth/AuthRepository";
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import InventoryManagementRepository from "../InventoryManagementRepository";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const modalStyle = {
  p: 4,
  top: "45%",
  left: "50%",
  width: "50%",
  boxShadow: 24,
  borderRadius: 3,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function IssueProductInternal(props) {
  let openInternal = props.open;
  const product = props.product;
  const productId = props.productId;
  const handleCloseInternal = props.closeInternal;
  const { handleSubmit, reset, control } = useForm();
  const facilityId = AuthRepository.getUser().facility_id;

  const [open, setOpen] = React.useState(openInternal);
  const [loading, setLoading] = React.useState(false);
  const [bloodTypeList, setBloodTypeList] = React.useState([]);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const buttonStyle = { mt: 4, textTransform: "capitalize", width: "200px" };

  const handleClose = () => {
    openInternal = false;
    setOpen(openInternal);
    handleCloseInternal();
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const getBloodTypes = async () => {
    const data = await InventoryManagementRepository.getBloodTypes();
    setBloodTypeList(data);
  };

  const onSubmit = async (data) => {
    await issueProduct(data);
  };

  const issueProduct = async (data) => {
    setLoading(true);
    const user = AuthRepository.getUser();

    const body = {
      user_id: user.id,
      reason: data.reason,
      facility_id: facilityId,
      product_ids: [productId],
      department: data.department,
      patient_age: data.patientAge,
      patient_name: data.patientName,
      physician_name: data.physicianName,
      physician_contact: data.physicianContact,
      patient_blood_type_id: data.patientBloodType,
    };

    try {
      const response = await InventoryManagementRepository.issueProductInternal(body);
      if (response.status === 'successful') {
        setLoading(false);
        handleClose();
        showSuccessSnackbar();
        reset();
      }
    }
    catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  React.useEffect(() => {
    setOpen(openInternal);
    getBloodTypes();
  }, [openInternal]);

  return (
    <React.Fragment>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", marginTop: "-20px", textDecoration: "none" }} onClick={handleCloseInternal}>
            <Close />
          </a>

          <Box component="div">
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 0, fontSize: 16 }}>
              Issue Product Internally
            </Typography>

            <Box component="div" sx={{ mt: 3 }}>
              <Alert severity="info">
                <AlertTitle>Product Info</AlertTitle>
                Blood Type:  <strong> { product.blood_type?.name }</strong><br/>
                Blood Component: <strong>{ product.blood_component?.name }</strong><br/>
                Product Code: <strong> { product.product_code || '-' }</strong><br/>
                Expiry Date: <strong> { product.expiration_date }</strong>
              </Alert>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Controller
                    name="department"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Department is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        label="Enter Department Name"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name="physicianName"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Physician name is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        label="Physician Name"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name="patientAge"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Patient age is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        label="Patient Age"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Controller
                    name="physicianContact"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Physican contact is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        label="Physician Contact"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name="patientName"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Patient name is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        size="small"
                        label="Patient Full name"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Controller
                    name="patientBloodType"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Patient blood type is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        select
                        id="outlined-select-bgroup"
                        size="small"
                        label="Patient Blood Type"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      >
                        { bloodTypeList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        )) }
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        size="small"
                        label="Enter Comments"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>

                  <Grid container justifyContent="flex-end">
                    <Button color="secondary" type="submit" variant="contained" disabled={loading} sx={buttonStyle}>
                      { loading ? 'Please wait ...' : 'Submit' }
                    </Button>
                  </Grid>
                </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      <SuccessAlert message={'Product successfully issued !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to issue product, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </React.Fragment>
  );
}
