import * as React from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './../../../components/Title';
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import AppointmentRepository from "../AppointmentRepository";
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AuthRepository from "./../../auth/AuthRepository";
import './../Appointments.css'
import { enableTrackingForMetrics } from "../../../libs/activityTracking";


const AppointmentScheduling = () => {

const user = AuthRepository.getUser();
let [rows, setRows] = React.useState([]);
let [blocked, setBlockedDays] = React.useState([]);

const { handleSubmit, reset, control } = useForm();
const { control: control2, reset: reset2, handleSubmit: handleSubmit2 } = useForm();

const [loading, setLoading] = React.useState(false);
const [resetForm, setResetForm] = React.useState(false);

const { enqueueSnackbar } = useSnackbar();

const showSnack = (variant) => {
  enqueueSnackbar('Data saved! successfully!', { variant });
};


const getProperDate = (date) => {
  return moment(date).format('ll');
};


const onSubmit = async (data) => {
  await addAppointmentSchedule(data);
  setResetForm(true);
  await getScheduledAppointments();
};

const onSubmitBlocked = async (data) => {
  await addBlockedDays(data);
  setResetForm(true);
  await getAppointmentBlockedDays();
};

const getScheduledAppointments = async () => {
  const data = await AppointmentRepository.getAppointmentSchedules(user.facility_id);
  setRows(data);
};

const addAppointmentSchedule = async (data) => {
  setLoading(true);
  const body = {
    created_by: user.id,
    facility_id: user.facility_id,
    monday: data.monday ? 1 : 0,
    tuesday: data.tuesday ? 1 : 0,
    wednesday: data.wednesday ? 1 : 0,
    thursday: data.thursday ? 1 : 0,
    friday: data.friday ? 1 : 0,
    saturday: data.saturday ? 1 : 0,
    sunday: data.sunday ? 1 : 0,
    starttime: data.starttime,
    endtime: data.endtime,
    breakstarttime: data.breakstarttime,
    breakendtime: data.breakendtime,
    donors_per_hour: data.donorsperhour,
  };

  try {
    const response = await AppointmentRepository.addAppointmentSchedule(body);
    if (response.id) {
      setLoading(false);
      resetForm ? reset() : showSnack('success');
      if (resetForm) reset();
    }
  }
  catch (e) {
    setLoading(false);
  }
};

const deleteScheduled = async (scheduleID) => {
  try {
    await AppointmentRepository.deleteAppointmentSchedule(scheduleID);
    setResetForm(true);
    await getScheduledAppointments();
  }
  catch (e) {
    setLoading(false);
  }
};

const getAppointmentBlockedDays = async () => {
  const data = await AppointmentRepository.getAppointmentBlockedDays(user.facility_id);
  setBlockedDays(data);
};

const addBlockedDays = async (data) => {
  setLoading(true);
  const body = {
    created_by: user.id,
    facility_id: user.facility_id,
    date: data.blockeddate,
  };

  try {
    const response = await AppointmentRepository.addBlockedDays(body);
    if (response.id) {
      setLoading(false);
      resetForm ? reset2() : showSnack('success');
    }
  }
  catch (e) {
    setLoading(false);
  }
};

const deleteBlocked = async (blockedID) => {
    try {
      await AppointmentRepository.deleteAppointmentBlockedDays(blockedID);
      setResetForm(true);
      await getAppointmentBlockedDays();
    }
    catch (e) {
      setLoading(false);
    }
  };

React.useEffect(() => {
    getScheduledAppointments();
    getAppointmentBlockedDays();
    enableTrackingForMetrics(user.id);
  }, []);


return <>
<div style={{margin: "0 0 200px"}} className="addschedule">

<Title>
<Link to="/appointments" variant="body2">
  <ArrowBackIcon sx={{mr:1}} />
</Link>
Saved Schedules</Title>

<Paper
  sx={{
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  }}
>
<Box textAlign="left">
{ rows ? (
<Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Mon</TableCell>
            <TableCell>Tue</TableCell>
            <TableCell>Wed</TableCell>
            <TableCell>Thu</TableCell>
            <TableCell>Fri</TableCell>
            <TableCell>Sat</TableCell>
            <TableCell>Sun</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Break Time</TableCell>
            <TableCell>Break Over</TableCell>
            <TableCell>Sessions per hour</TableCell>
            <TableCell>Delete?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.monday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.tuesday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.wednesday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.thursday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.friday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.saturday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.sunday ? <EventAvailableIcon color="secondary" /> : <EventBusyIcon color="error" /> }</TableCell>
              <TableCell>{row.starttime}</TableCell>
              <TableCell>{row.endtime}</TableCell>
              <TableCell>{row.breakstarttime}</TableCell>
              <TableCell>{row.breakendtime}</TableCell>
              <TableCell>{row.donors_per_hour}</TableCell>
              <TableCell><Button type="icon" color="text" onClick={() => deleteScheduled(row.id)}> <DeleteIcon /> </Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      ) : (
      <p>No schedules set</p>
      )}
</Box>
</Paper>

<div style={{margin: "40px auto 30px"}}>
<Title>
Schedule Settings</Title>
  {/* <Typography variant="body" component="div" sx={{mt:1, ml:3.5, fontSize:11, color:"#202020"}}>
        Appointments > <strong>Change Schedule</strong>
  </Typography> */}
</div>


<Paper
  sx={{
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  }}
>
<Box textAlign="left">
<form onSubmit={handleSubmit(onSubmit)}>
<FormGroup className="checkboxes" sx={{mb:5}}>
<Grid container spacing={3}>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="monday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Monday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="tuesday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Tuesday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="wednesday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Wednesday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="thursday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Thursday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="friday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Friday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="saturday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Saturday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
      <Grid item xs={6} md={1.5} lg={1.5}>
      <Controller
        name="sunday"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel label="Sunday" control={<Checkbox color="secondary" value={value} onChange={onChange} />} />
        )}
      />
      </Grid>
</Grid>
</FormGroup>

    <Grid container spacing={3}>
      <Grid item xs={6} md={3} lg={3}>
        <p>Start Time</p>
        <Controller
          name="starttime"
          control={control}
          defaultValue=""
          rules={{ required: 'Start time is required'}}
          render={({ field: { onChange, value }, fieldState: {error } }) => (
            <TextField
              id="starttime"
              type="time"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              sx={{ width: "100%" }}
              helperText={error ? error.message : null}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} md={3} lg={3}>
        <p>End time</p>
        <Controller
          name="endtime"
          control={control}
          defaultValue=""
          rules={{ required: 'End time is required'}}
          render={({ field: { onChange, value }, fieldState: {error } }) => (
            <TextField
              id="endtime"
              type="time"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              sx={{ width: "100%" }}
              helperText={error ? error.message : null}
            />
          )}
        />
          </Grid>

      <Grid item xs={6} md={3} lg={3}>
        <p>Break Start Time</p>
        <Controller
          name="breakstarttime"
          control={control}
          defaultValue=""
          rules={{ required: 'Break Start time is required'}}
          render={({ field: { onChange, value }, fieldState: {error } }) => (
            <TextField
              id="breakstarttime"
              type="time"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              sx={{ width: "100%" }}
              helperText={error ? error.message : null}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} md={3} lg={3}>
        <p>Break End Time</p>
        <Controller
          name="breakendtime"
          control={control}
          defaultValue=""
          rules={{ required: 'Break End time is required'}}
          render={({ field: { onChange, value }, fieldState: {error } }) => (
            <TextField
              id="breakendtime"
              type="time"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              sx={{ width: "100%" }}
              helperText={error ? error.message : null}
            />
          )}
        />
      </Grid>

      <Grid item xs={6} md={3} lg={3}>
        <p>Sessions per hour <br /><small>How many donors can you see per hour?</small></p>

        <Controller
          name="donorsperhour"
          control={control}
          defaultValue=""
          rules={{ required: 'This field is required'}}
          render={({ field: { onChange, value }, fieldState: {error } }) => (
            <TextField
              id="donorsperhour"
              type="number"
              variant="outlined"
              value={value}
              error={!!error}
              onChange={onChange}
              sx={{ width: "50%" }}
              helperText={error ? error.message : null}
            />
          )}
        />
      </Grid>



    </Grid>

    <Button
      type="submit"
      variant="contained"
      sx={{ mt: 7, mb: 2, backgroundColor: "#0046AF", color:"#fff", width: 200 }}
    >
      Submit
    </Button>

</form>

</Box>
</Paper>



<Paper
  sx={{
    mt:4,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
  }}
>
<Box textAlign="left">
<Grid container spacing={3}>
<Grid item xs={12} md={5} lg={5}>
<form onSubmit={handleSubmit2(onSubmitBlocked)}>
        <p>Blocked Days</p>
        <small>Donors will not be able to book donation appointments on the days you select.</small>
          <p>Date</p>

          <Controller
            name="blockeddate"
            control={control2}
            defaultValue=""
            rules={{ required: 'Date is required'}}
            render={({ field: { onChange, value }, fieldState: {error } }) => (
              <TextField
                id="blockeddate"
                type="date"
                variant="outlined"
                value={value}
                error={!!error}
                onChange={onChange}
                sx={{ width: "100%" }}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Button
          type="submit"
          variant="contained"
          sx={{ mt: 5, mb: 2, backgroundColor: "#0046AF", color:"#fff", width: 200 }}
          >
          Add Date
          </Button>
        </form>
</Grid>
      <Grid item xs={12} md={7} lg={7}>
        <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Blocked (Closed) Date</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Delete?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {blocked.map((blocked) => (
            <TableRow key={blocked.id}>
              <TableCell>{getProperDate(blocked.date)}</TableCell>
              <TableCell>{getProperDate(blocked.createdAt)}</TableCell>
              <TableCell><Button type="icon" color="text" onClick={() => deleteBlocked(blocked.id)}> <DeleteIcon /> </Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>


      </Grid>
</Grid>

</Box>
</Paper>

 </div>
    </>
}

export default function ScheduleAppointment() {
  return (
    <SnackbarProvider maxSnack={3}>
      <AppointmentScheduling />
    </SnackbarProvider>
    );
}
