import './Billing.css';
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import BillingTabs from './components/BillingTabs'
import AuthRepository from '../auth/AuthRepository';
import { enableTrackingForMetrics } from "../../libs/activityTracking";

const AdminUser = () => {
  const user = AuthRepository.getUser();

  React.useEffect(() => {
    enableTrackingForMetrics(user.id);
  });

  return <>
    <Paper
      sx={{
        mt: 5,
        pt: 2,
        pr: 5,
        pl: 5,
        pb: 10,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <React.Fragment>
        <Typography variant="h5" textAlign='left' component="div"
          sx={{
            mt: 1,
            mb: 2,
            fontSize: 18,
            fontWeight: 600,
            color: "#202020",
            borderBottom: "1px solid #ccc",
            width: "100%",
            pb: 1.5
        }}>
          Billing
        </Typography>

        <div className="billingcontainer">
          <BillingTabs />
        </div>

      </React.Fragment>
    </Paper>
  </>
}

export default function AdminUserContent() {
  return <AdminUser />;
}
