import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useForm, Controller } from "react-hook-form";
import AuthRepository from '../../auth/AuthRepository';
import Divider from '@mui/material/Divider';
import SuccessAlert from "../../../components/SuccessAlert";
import ErrorAlert from "../../../components/ErrorAlert";
import '../DonationManagement.css';
import DonationManagementRepository from './../DonationManagementRepository';
import FormLabel from '@mui/material/FormLabel';


export default function ScreeningQuestions(props) {
  const gender = props.gender;
  const donorId = props.donorId;
  const appointmentId = props.appointmentId;

  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const { handleSubmit, reset, control } = useForm();
  const [resetForm, setResetForm] = React.useState(false);

  const showSucessSnakbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const statuses = [
    {
      value: "1",
      label: "Passed",
    },
    {
      value: "2",
      label: "Pre-screen",
    },
  ];

  const [status, setStatus] = React.useState("2");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };


  const user = AuthRepository.getUser();


  const onSubmit = async (data) => {
    setLoading(true);
    await addDonation(data);
  };


  const addDonation = async (data) => {
    setLoading(true);
    const body = {
      appointment_id: appointmentId,
      created_by: user.id,
      donor_id: donorId,
      weight: data.weight,
      bp: data.bp,
      pulse: data.pulse,
      hb_checked: data.hb_checked,
      facility_id: user.facility_id,
      unwell: data.unwell === 'Yes' ? 1 : 0,
      pre_status: data.pre_status === 'Passed' ? "Passed" : "Failed",
      deferred_donor: data.deferred_donor === 'Yes' ? 1 : 0,
      on_blood_pressure_medication: data.on_blood_pressure_medication === 'Yes' ? 1 : 0,
      diabetes_history: data.diabetes_history === 'Yes' ? 1 : 0,
      on_treatment: data.on_treatment === 'Yes' ? 1 : 0,
      hepatitis_positive: data.hepatitis_positive === 'Yes' ? 1 : 0,
      sickle_cell: data.sickle_cell === 'Yes' ? 1 : 0,
      drug_addict: data.drug_addict === 'Yes' ? 1 : 0,
      non_medical_injection: data.non_medical_injection === 'Yes' ? 1 : 0,
      surgical_operation: data.surgical_operation === 'Yes' ? 1 : 0,
      lost_weight: data.lost_weight === 'Yes' ? 1 : 0,
      have_std: data.have_std === 'Yes' ? 1 : 0,
      hiv_positive: data.hiv_positive === 'Yes' ? 1 : 0,
      work_after_donation: data.work_after_donation === 'Yes' ? 1 : 0,
      previous_pregnancies: data.previous_pregnancies === 'Yes' ? 1 : 0,
      previous_donation: data.previous_donation === 'Yes' ? 1 : 0,
      reason: data.reason,
      units: 1
    };

    try {
      const donation = await DonationManagementRepository.addDonation(body);
      if (donation) {
        setLoading(false);
        showSucessSnakbar();
        if (resetForm) reset();
        window.location.assign("/donations/unscreened");
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };


  return (
    <Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup className="checkboxes" sx={{ mb: 5 }}>
          <Grid container spacing={3}>

            <Grid item xs={3} md={2} lg={2}>
              <p><small>Weight (Kg)</small></p>

              <Controller
                name="weight"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="weight"
                    type="number"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>


            <Grid item xs={3} md={2} lg={2}>
              <p><small>BP</small></p>

              <Controller
                name="bp"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="bp"
                    type="text"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>


            <Grid item xs={3} md={2} lg={2}>
              <p><small>Pulse (bpm)</small></p>

              <Controller
                name="pulse"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="pulse"
                    type="text"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={3} md={2} lg={2}>
              <p><small>HB</small></p>

              <Controller
                name="hb_checked"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="hb_checked"
                    type="text"
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    sx={{ width: "100%" }}
                  />
                )}
              />
            </Grid>



            <Grid item xs={3} md={2} lg={2}>

            </Grid>



            {gender === "Female" ? (
              <Grid item xs={4} md={3} lg={3}>
                <FormControl>
                  <p><strong>Previous Pregnancies</strong></p>
                  <Controller
                    name="previous_pregnancies"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            ) : (null)}



            <Grid item xs={4} md={3} lg={3}>
              <FormControl>
                <p><strong>Previous Reaction</strong></p>
                <Controller
                  name="previous_reaction"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Field is required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={onChange}
                    >
                      <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <FormControl>
                <p><strong>Donation Type</strong></p>
                <Controller
                  name="donor_type"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Donor is required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={onChange}
                    >
                      <FormControlLabel value="Voluntary" control={<Radio color="text" />}
                        label="Voluntary" />
                      <FormControlLabel value="Replacement" control={<Radio color="text" />} label="Replacement" />
                      <FormControlLabel value="Contract" control={<Radio color="text" />} label="Contract" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

          </Grid>

          <br />
          <Divider />
          <br />

          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you donated blood before?</FormLabel>
                  <Controller
                    name="previous_donation"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Are you feeling unwell today? (eg. fever, headache, cough/cold)</FormLabel>
                  <Controller
                    name="unwell"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you been deferred/told not to donate blood in the past?</FormLabel>
                  <Controller
                    name="deferred_donor"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Are you taking medication for high blood pressure (HBP) or heart failure?</FormLabel>
                  <Controller
                    name="on_blood_pressure_medication"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Do you have a history of epilepsy, diabetes, duodenal or gastric ulcer, hypertension, asthma or TB?</FormLabel>
                  <Controller
                    name="diabetes_history"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Are you taking any medication?</FormLabel>
                  <Controller
                    name="on_treatment"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you had jaundice, hepatitis or a positive blood test for hepatitis?</FormLabel>
                  <Controller
                    name="hepatitis_positive"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Do you have any sickle cell disease (e.g. SS, SC, and S Bthal)?</FormLabel>
                  <Controller
                    name="sickle_cell"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>


              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you taken any injection drugs or been diagnosed as a drug addict?</FormLabel>
                  <Controller
                    name="drug_addict"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">In the last 12 months, Donor has had injections or cuts that were not done in a hospital/clinic (e.g. tattoos, vaccination, circumcision)?</FormLabel>
                  <Controller
                    name="non_medical_injection"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you had a major surgical operation in the last 12 months?</FormLabel>
                  <Controller
                    name="surgical_operation"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you lost more than 5KG in the last 6 months due to illness?</FormLabel>
                  <Controller
                    name="lost_weight"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you received or been transfused with blood in the last 6 months?</FormLabel>
                  <Controller
                    name="previous_transfusion"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you had a sexually transmitted disease or sexually transmitted infection?</FormLabel>
                  <Controller
                    name="have_std"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">Have you or a partner been tested positive for HIV/AIDS in the past?</FormLabel>
                  <Controller
                    name="hiv_positive"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" color="text">After this blood donation, donor will be doing any vigorous activity such as climbing, driving heavy vehicles, or work at hazardous depths & heights?</FormLabel>
                  <Controller
                    name="work_after_donation"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Field is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel value="Yes" control={<Radio color="text" />} label="Yes" />
                        <FormControlLabel value="No" control={<Radio color="text" />} label="No" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>

            </Grid>
          </Box>



          <br />
          <Divider />
          <br />

          <Typography variant="h5" textAlign='left' component="div" sx={{ mt: 2, fontSize: 16, fontWeight: 600, color: "#202020", width: "100%", pb: 1.5 }}>
            Screening status:
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={6} md={4} lg={4}>
              <FormControl>
                <Controller
                  name="pre_status"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Field is required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={value}
                      onChange={onChange}
                    >
                      <FormControlLabel value="Passed" control={<Radio color="text" />} onClick={() => setShow(false)} label="Passed screening" />
                      <FormControlLabel value="Failed" control={<Radio color="text" />} onClick={() => setShow(true)} label="Failed screening" />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>


            {show ? (
              <Grid item xs={3} md={5} lg={5} sx={{ mt: -1 }}>
                <p><strong>Reason for Failure</strong></p>

                <Controller
                  name="reason"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Reason is required' }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      id="reason"
                      multiline
                      rows={2}
                      type="text"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      sx={{ width: "100%" }}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>) : (
              null)}

          </Grid>


        </FormGroup>

        <Button type="submit" variant="contained"
          sx={{ mt: 5, mb: 2, backgroundColor: "#0046AF", color: "#fff", width: 200 }}
          disabled={loading}>
          {loading ? 'Please wait ...' : 'Submit'}
        </Button>

        {/* <Button type="submit" variant="contained"
          sx={{mt: 5, mb: 2, backgroundColor: "red", color: "#fff", width: 200}}
          disabled={loading}>
      {loading ? 'Please wait ...' : 'Fail'}
  </Button> */}

      </form>

      <SuccessAlert message={'Donation info added successfully !'} successSnackbar={successSnackbar}
        closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to add donation info, something unexpected happened.'}
        errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </Box>
  );
}
