import axios from 'axios';

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const DonationManagementRepository = {

  /**
   * Add new donation to inventory.
   * @param {*} donation
   * @returns
   */
  addDonation: async (donation) => {
    const response = await axios.post(`${HOST}/donations`, donation);
    return response.data;
  },

/**
   * Add new appointment for enrolled donor.
   * Add new walk in Appointment.
   * @param {*} appointment
   * @returns
   */
  walkinAppointment: async (appointment) => {
    const response = await axios.post(`${HOST}/appointments`, appointment);
    return response.data;
  },


   /**
   * Updates a donation
   * @returns
   */
  updateDonation: async (data) => {
    const response = await axios.put(`${HOST}/donations/${data.id}`, data);
    return response.data;
  },


  /**
   * Add new donor to facility.
   * @param {*} donation
   * @returns
   */
  addDonor: async (donor) => {
    const response = await axios.post(`${HOST}/donors`, donor);
    return response.data;
  },


  /**
   * Updates a donor
   * @returns
   */
  updateDonor: async (data) => {
    const response = await axios.put(`${HOST}/donors/${data.id}`, data);
    return response.data;
  },



  getBloodTypes: async () => {
    const response = await axios.get(`${HOST}/blood/types`);
    return response.data;
  },



  /**
   * Returns all donations in inventory.
   * @returns 
   */
  getAllDonations: async (facilityId) => {
    const response = await axios.get(`${HOST}/donations/${facilityId}`);
    return response.data;
  },

  /**
   * Returns all donations in inventory.
   * @returns 
   */
  getAllDonors: async (facilityId) => {
    const response = await axios.get(`${HOST}/donors/facility/${facilityId}`);
    return response.data;
  },


  /**
   * Returns all donations of all statuses.
   * @returns {Promise<any>}
   */
  getDonationHistory: async () => {
    const response = await axios.get(`${HOST}/donations/history`);
    return response.data;
  },

  /**
   * Returns all unscreened products.
   * @returns {Promise<any>}
   */
  getUnscreenedBlood: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/unscreened/${facilityId}`);
    return response.data;
  },
};

export default DonationManagementRepository;