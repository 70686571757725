import moment from 'moment';
import * as React from 'react';
import './DonationManagement.css';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Modal from "@mui/material/Modal";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import MUIDataTable from "mui-datatables";
import { Close } from '@mui/icons-material';
import Title from './../../components/Title';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import AuthRepository from '../auth/AuthRepository';
import ListItemText from '@mui/material/ListItemText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import DonationManagementRepository from './DonationManagementRepository';
import { enableTrackingForMetrics } from "../../libs/activityTracking";

const modalStyle = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

const options = {
  filterType: 'dropdown',
  selectableRows: 'none',
  responsive: "vertical",
  rowsPerPage: 30,
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No donations.",
    },
  },
};


const DonationPage = () => {
  const user = AuthRepository.getUser();

  const [open, setOpen] = React.useState(false);
  const [donations, setDonations] = React.useState([]);
  const [donationDetails, setDonationDetails] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const columns = [
    { name: 'id', label: 'ID', options: { sort: false, filter: false, display: false } },
    {
      name: 'donor', label: 'Donor', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['fname'] + " " + value['lname']
        )
      }
    },
    {
      name: 'donor', label: 'Gender', options: {
        sort: true, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['gender']
        )
      }
    },
    {
      name: 'donor', label: 'Donor Phone', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['phone']
        )
      }
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            var check = new Date(date);
            var from = new Date(filters[0]);
            var to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    { name: 'status', label: 'Status', options: { sort: true, filter: true } },
    {
      name: "Action",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                type="icon"
                color="secondary"
                onClick={() => {
                  const donationId = tableMeta.rowData[0];
                  const donationDetail = donations.filter((p) => p.id === donationId);
                  setDonationDetails(donationDetail[0]);
                  handleOpen();
                }}
              >
                <VisibilityIcon />
              </Button>
            </>
          );
        }
      }
    }
  ];

  const getAllDonations = async () => {
    const donations = await DonationManagementRepository.getAllDonations(user.facility_id);
    setDonations(donations);
  };

  React.useEffect(() => {
    getAllDonations();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>Blood Donations</Title>
    </div>

    <Paper sx={{ mt: 5, pt: 2, pr: 2, pl: 2, pb: 10, display: 'flex', flexDirection: 'column' }}>
      <React.Fragment>
        <MUIDataTable
          className="muidatatable"
          data={donations}
          columns={columns}
          options={options}
        />
      </React.Fragment>
    </Paper>

    {/* View Details Modal */}
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <a
            href="#"
            style={{ float: "right", mt: "-10px", textDecoration: "none" }}
            onClick={handleClose}
          >
            <Close />
          </a>
          <div>
            <Typography variant="h6" component="div">
              Donation History Details
            </Typography>
            <Grid container spacing={1}>
              <Grid item sx={{ mt:2 }} xs={12} md={3}>
                {/* Row One */}
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Previous Transfusion"
                      secondary={donationDetails?.previous_transfusion ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Previous Pregnancies"
                      secondary={donationDetails?.previous_pregnancies ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Previous Donation"
                      secondary={donationDetails?.previous_donation ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Donation Count"
                      secondary={donationDetails?.donation_count}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Deferred Donor"
                      secondary={donationDetails?.deferred_donor ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Non Medication Injection"
                      secondary={donationDetails?.non_medical_injection ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Surgical Operation"
                      secondary={donationDetails?.surgical_operation ? 'Yes' : 'No'}
                    />
                  </ListItem>
                </List>
              </Grid>

              {/* Row Two */}
              <Grid item xs={12} md={3}>
                <Box sx={{ mb: 2 }}></Box>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Patient Unwell"
                      secondary={donationDetails?.unwell ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="On BP Medication"
                      secondary={donationDetails?.on_blood_pressure_medication ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Diabetes History"
                      secondary={donationDetails?.diabetes_history ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="On Treatment"
                      secondary={donationDetails?.on_treatment ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Hepatitis"
                      secondary={donationDetails?.hepatisis_positive ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Sickle Cell"
                      secondary={donationDetails?.sickle_cell ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Drug Addit"
                      secondary={donationDetails?.drug_addict ? 'Yes' : 'No'}
                    />
                  </ListItem>
                </List>
              </Grid>

              {/* Row Three */}
              <Grid item xs={12} md={3}>
                <Box sx={{ mb: 2 }}></Box>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Weight Loss"
                      secondary={donationDetails?.weight_loss ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Have STD"
                      secondary={donationDetails?.have_std ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="HIV Positive"
                      secondary={donationDetails?.hiv_positive ? 'Yes' : 'No'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Weight"
                      secondary={donationDetails?.weight || '-'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="BP"
                      secondary={donationDetails?.bp || '-'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Pulse"
                      secondary={donationDetails?.pulse || '-'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Rhesus"
                      secondary={donationDetails?.rhesus || '-'}
                    />
                  </ListItem>
                </List>
              </Grid>

              {/* Row Four */}
              <Grid item xs={12} md={3}>
                <Box sx={{ mb: 2 }}></Box>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="HIV Screening"
                      secondary={donationDetails?.screen_hiv ? 'Passed' : 'Failed'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Hepatisis B Screening"
                      secondary={donationDetails?.screen_hepatitisB ? 'Passed' : 'Failed'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Hepatisis C Screening"
                      secondary={donationDetails?.screen_hepatitisC ? 'Passed' : 'Failed'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Syphilis Screening"
                      secondary={donationDetails?.screen_syphilis ? 'Passed' : 'Failed'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Post Screening"
                      secondary={donationDetails?.post_status ? 'Passed' : 'Failed'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Blood Type"
                      secondary={donationDetails?.product?.blood_type.name || '-'}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Screening Reason"
                      secondary={donationDetails?.reason || '-'}
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
    </div>
  </>
}

export default function DonationManagement() {
  return <DonationPage />;
}
