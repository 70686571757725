import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Units by products',
    },
  },
};


const DoughnutChart = (props) => {
	const labels = props.labels;
	const data = props.data;

	return <div>
	<Doughnut
	  options={options}
	  data={{
	    labels: labels,
	    datasets: [
	      {
	        id: 1,
	        label: '',
	        data: data,
	        backgroundColor: [
		      'rgba(224, 69, 55, 1)',
		      'rgba(224, 69, 55, 0.5)',
		      'rgba(252, 186, 2, 1)',
		      'rgba(252, 186, 2, 0.5)',
		      'rgba(12, 160, 88, 1)',
		      'rgba(12, 160, 88, 0.5)',
		      'rgba(51, 102, 204, 1)',
		      'rgba(51, 102, 204, 0.5)',
		      ],
	      },
	    ],
	  }}
	/>
	</div>
}

export default DoughnutChart
