import moment from 'moment';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import { Link, useLocation } from "react-router-dom";
import "../BloodRequest.css";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import { useForm, Controller } from "react-hook-form";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorAlert from "../../../components/ErrorAlert";
import { CloseOutlined } from "@mui/icons-material";
import SuccessAlert from "../../../components/SuccessAlert";
import Barcode from "react-jsbarcode";
import InventoryManagementRepository from "../../inventory/InventoryManagementRepository";
import RequestManagementRepository from "../RequestManagementRepository";
import AuthRepository from "../../auth/AuthRepository";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
  p: 4,
};

const MakeRequest = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { handleSubmit, reset, control } = useForm();

  const [barcode, setBarcode] = React.useState(null)
  const [bloodtype, setBloodType] = React.useState('')
  const [expiry, setExpiry] = React.useState('')
  const [collection, setCollection] = React.useState('')
  const [component, setComponent] = React.useState('')

  const [loading, setLoading] = React.useState(false);
  const [resetForm, setResetForm] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const [bloodTypeList, setBloodTypeList] = React.useState([]);
  const [requestTypeList, setRequestTypeList] = React.useState([]);
  const [bloodComponentList, setComponentList] = React.useState([]);

  const reqtypes = [
    {
      value: 1,
      label: "Standard",
    },
    {
      value: 2,
      label: "Emergency",
    },
  ];

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
    if (!resetForm) navigate('/inventory');
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const onSubmit = async (data) => {
    await makeRequest(data);
  };

  const addContinue = () => {
    setResetForm(true);
  };

  const removeAddContinue = () => {
    setResetForm(false);
  };

  const makeRequest = async (data) => {
    setLoading(true);
    const user = AuthRepository.getUser();
    const body = {
      created_by: user.id,
      requestee_facility_id: user.facility_id,
      blood_type_id: data.bloodType,
      blood_component_id: data.product,
      units: data.units,
      request_type_id: data.request_type_id
    };

    try {
      const response = await RequestManagementRepository.makeBloodRequest(body);
      if (response.id) {
        setLoading(false);
        showSuccessSnackbar();
        if (resetForm) reset();
      }
    }
    catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const getBloodTypes = async () => {
    const data = await InventoryManagementRepository.getBloodTypes();
    setBloodTypeList(data);
  };

  const getRequestTypes = async () => {
    const data = await InventoryManagementRepository.getBloodTypes();
    setRequestTypeList(data);
  };

  const getBloodComponents = async () => {
    const data = await InventoryManagementRepository.getBloodComponents();
    setComponentList(data);
  };

  const location = useLocation();

  React.useEffect(() => {
    console.clear()
    getBloodTypes();
    getBloodComponents();
  }, []);

  return <>
    <div style={{ margin: "0 0 200px" }} className="addproduct">
      <div style={{ margin: "40px auto 30px" }}>
        <Title>
          <Link to="/inventory" variant="body2">
            <ArrowBackIcon sx={{ mr: 1 }} />
          </Link>
          Make Blood Request
        </Title>
        <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
          requests / <strong>Make Blood Request</strong>
        </Typography>
      </div>

      <Typography variant="h5" textAlign='left' component="div" sx={{ mt: 5, fontSize: 15, fontWeight: 600, color: "#464D69" }}>
        Request Form
        <Divider variant="left" sx={{ mt: 2, mb: 2, backgroundColor: "#ddd" }} />
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={2} lg={2}>
            <p>Blood Type</p>
            <Controller
              name="bloodType"
              control={control}
              defaultValue=""
              rules={{ required: 'Blood Type is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={location.state ? location.state[1] : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                >
                  {bloodTypeList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>



          <Grid item xs={6} md={2} lg={2}>
            <p>Unit(s) needed</p>
            <Controller
              name="units"
              control={control}
              defaultValue=""
              rules={{ required: 'Enter number of blood units' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="units"
                  type="number"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={4} lg={4}>
            <p>Component</p>
            <Controller
              name="product"
              control={control}
              defaultValue=""
              rules={{ required: 'Product is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={location.state ? location.state[0] : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                >
                  {bloodComponentList.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>


          <Grid item xs={6} md={4} lg={4}>
            <p>Request Type</p>
            <Controller
              name="request_type_id"
              control={control}
              defaultValue=""
              rules={{ required: 'Request type is required' }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  select
                  id="outlined-select-bgroup"
                  label="Select"
                  size="small"
                  variant="outlined"
                  value={location.state ? location.state[0] : value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                >
                  {reqtypes.map((option) => (
                    <MenuItem key={option.id} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>

        </Grid>

        <Button type="submit" variant="contained" color="secondary" onClick={addContinue} sx={{ mt: 7, mb: 2, width: 200 }} disabled={loading}>
          {loading ? 'Please wait ...' : 'Send Request'}
        </Button>
      </form>




      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <a
            href="#"
            style={{ float: "right", mt: "-20px", textDecoration: "none" }}
            onClick={handleClose}
          >
            <CloseOutlined />
          </a>


          <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
              <Typography variant="body" component="div" sx={{ mt: 4, fontSize: 13, color: "#202020" }}>
                <strong>CAUTION: </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 5, mb: 2, fontSize: 24, color: "#202020" }}>
                <strong>{component}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Collected" color="secondary" variant="outlined" /> {collection}</strong>
              </Typography>

              <Typography variant="body" component="div" sx={{ mt: 1, mb: 2, fontSize: 18, color: "#202020" }}>
                <strong><Chip label="Expires" color="error" variant="outlined" /> {expiry}</strong>
              </Typography>
            </Grid>


            <Grid item xs={6} md={6} lg={6} align="center">
              <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 94, color: "#202020" }}>
                <strong>{bloodtype}</strong>
              </Typography>

              <Barcode value={barcode} />
            </Grid>

          </Grid>

        </Box>
      </Modal>

      <SuccessAlert message={'Request sent successfully!'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to make request, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </div>
  </>
}

export default function ProductContent() {
  return <MakeRequest />;
}
