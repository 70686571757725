import moment from 'moment';
import * as React from 'react';
import '../DonationManagement.css';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Title from './../../../components/Title';
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import MUIDataTable from "mui-datatables";
import { FormGroup, FormLabel, TextField } from '@mui/material';
import AuthRepository from '../../auth/AuthRepository';
import DonationManagementRepository from '../DonationManagementRepository';
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const options = {
  filterType: 'dropdown',
  selectableRows: 'none',
  responsive: "vertical",
  rowsPerPage: 30,
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No enrolled donor.",
    },
  },
};


const DonationPage = () => {
  const user = AuthRepository.getUser();

  const [donors, setDonors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    setErrorSnackbar(false);
  };

  const createAppointment = async (donorphone) => {
    setLoading(true);
    const appointment = {
      donor: {
        email: "jane11100929383877@example.com",
        phone: donorphone,
      },
      appointment: {
        appointment_type_id: 1,
        appointment_status_id: 2,
        facility_id: user.facility_id,
        time: moment().add(24, 'hours').format('lll'),
      }
    };

    try {
      const response = await DonationManagementRepository.walkinAppointment(appointment);
      if (response.id) {
        setLoading(false);
        showSuccessSnackbar();
        window.location.assign("/appointments/upcoming");
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const columns = [
    { name: 'id', label: 'ID', options: { sort: false, filter: false, display: false } },
    { name: 'fname', label: 'First Name', options: { sort: true, filter: false, display: false } },
    { name: 'lname', label: 'Last Name', options: { sort: true, filter: false, display: false } },
    {
      name: "full_name",
      label: "Full Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>{tableMeta.rowData[1]} {tableMeta.rowData[2]}</div>
          );
        }
      }
    },
    { name: 'gender', label: 'Gender', options: { sort: true, filter: true } },
    { name: 'phone', label: 'Phone', options: { sort: true, filter: false } },
    {
      name: "createdAt",
      label: "Enrolled on",
      options: {
        filter: true,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            let from = new Date(filters[0]);
            let to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },

    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                type="icon"
                size="small"
                onClick={() => {
                  createAppointment(tableMeta.rowData[5])
                }}
                sx={{ backgroundColor: "green", color: "#fff", fontSize: 10, mt: 0 }}
              >
                + Add appointment
              </Button>
            </>
          );
        },
        filter: false,
        sort: false,
      }
    }

  ];

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const getAllDonors = async () => {
    const donors = await DonationManagementRepository.getAllDonors(user.facility_id);
    setDonors(donors);
  };

  React.useEffect(() => {
    getAllDonors();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>Donors Enrolled</Title>
    </div>


    <Paper sx={{ mt: 5, pt: 2, pr: 2, pl: 2, pb: 10, display: 'flex', flexDirection: 'column' }}>
      <React.Fragment>

        <MUIDataTable
          className="muidatatable"
          data={donors}
          columns={columns}
          options={options}
        />

      </React.Fragment>

    </Paper>


    <SuccessAlert message={'Appointment created successfully!'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    <ErrorAlert message={'Oops! Failed to create appointment, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
  </>
}

export default function DonationManagement() {
  return <DonationPage />;
}
