import * as React from 'react';
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

export default function ProductHistoryDetails(props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography sx={{ mt: -6, mb: 2.5, ml: 2, fontWeight: 600 }} variant="h6" component="div">
          Product History Details
        </Typography>
        {/* Row One */}
        <List>
          <ListItem>
            <ListItemText
              primary="Blood Type"
              secondary={props.productHistory?.blood_type}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Blood Component"
              secondary={props.productHistory?.product}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Barcode"
              secondary={props.productHistory?.barcode}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Product Code"
              secondary={props.productHistory?.product_code}
            />
          </ListItem>
        </List>
      </Grid>

      {/* Row Two */}
      <Grid item xs={12} md={4}>
        <Box sx={{ mt: 2, mb: 2 }}/>
        <List>
          <ListItem>
            <ListItemText
              primary="Status"
              secondary={props.productHistory?.status}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Collection Date"
              secondary={props.productHistory?.received_date}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Expiration Date"
              secondary={props.productHistory?.expiration_date}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Reason"
              secondary={props.productHistory?.reason || '-'}
            />
          </ListItem>
        </List>
      </Grid>

      {/* Row Three */}
      <Grid item xs={12} md={4}>
        <Box sx={{ mt: 2, mb: 2 }}/>
        <List>
          <ListItem>
            <ListItemText
              primary="Physician Name"
              secondary={props.productHistory?.physician_name || '-'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Physician Contact"
              secondary={props.productHistory?.physician_contact || '-'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Patient Name"
              secondary={props.productHistory?.patient_name || '-'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Patient Age"
              secondary={props.productHistory?.patient_contact || '-'}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
}

