import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import MuiPhoneNumber from 'material-ui-phone-number';
import Modal from "@mui/material/Modal";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import MUIDataTable from "mui-datatables";
import ButtonGroup from '@mui/material/ButtonGroup';
import { Close } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsRepository from "../SettingsRepository";
import AuthRepository from '../../auth/AuthRepository';
import '../AdminUser.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";

const muiPhoneTextFieldStyle = {
  marginTop: "9px",
  padding: "10px",
  borderRadius: "6px",
  marginBottom: "12px",
  border: "1px solid #ccc",
};

const UserSettings = () => {
  const [open, setOpen] = React.useState(false);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);

  let currentUser = {};
  const [userId, setUserId] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { handleSubmit, control, setValue } = useForm();

  const [facilityusers, setFacilityUsers] = React.useState([]);
  const [positionList, setPositionList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [phone, setPhone] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  function handleNumberChange(value) {
    setPhone(value);
  }

  const user = AuthRepository.getUser();

  const getCurrentUser = async (userId) => {
    currentUser = await SettingsRepository.getUser(userId);
  };

  const showErrorAlert = () => {
    setShowError(true);
  };

  const handleClickOpen = (value) => () => {
    setOpenDialogue(true);
    setUserId(value);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };

  const handleEditOpen = (_userId) => async () => {
    setOpenEditModal(true);
    await getCurrentUser(_userId);
    initializeEditUserModalFormFields();
  };

  const handleEditClose = () => {
    setOpenEditModal(false);
  };

  const initializeEditUserModalFormFields = () => {
    setValue('email', currentUser?.email);
    setValue('phone', currentUser?.phone);
    setValue('lastname', currentUser?.lastname);
    setValue('username', currentUser?.username);
    setValue('firstname', currentUser?.firstname);
    setValue('position', currentUser?.position_id);
    setValue('appointment', currentUser?.permissions?.appointment);
  };

  const onSubmit = async (data) => {
    await createUser(data);
  };

  const onSubmitEdit = async (data) => {
    await editUser(currentUser.id, data);
  };

  const createUser = async (data) => {
    setIsLoading(true);
    const body = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      username: data.username,
      phone: phone,
      password: "demo123",
      position_id: data.position,
      facility_id: user.facility_id,
      role: "user"
    };
    body.permissions = JSON.stringify(body.permissions)

    try {
      const user = await AuthRepository.createuser(body);
      if (user) {
        getFacilityUsers();
        setIsLoading(false);
        setOpen(false);
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  const editUser = async (userId, data) => {
    setIsLoading(true);
    const body = {
      role: "user",
      phone: phone,
      email: data.email,
      lastname: data.lastname,
      firstname: data.firstname,
      position_id: data.position,
      facility_id: user.facility_id
    };

    try {
      const user = await AuthRepository.updateUser(currentUser.id, body);
      if (user) {
        setIsLoading(false);
        handleEditClose();
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  const HeaderElements = () => {
    return (
      <>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mt: 2, ml: 5, mb: 2, width: 150, color: "#fff" }}
          onClick={handleOpen}
        >
          Add New User
        </Button>
      </>
    );
  }

  const options = {
    rowsPerPage: 30,
    selectableRows: 'none',
    customToolbar: HeaderElements,
    rowsPerPageOptions: [15, 30, 50],
  };

  const columns = [
    { name: 'id', label: 'User ID', options: { sort: false, filter: false, display: false } },
    "First Name", "Last Name", "Email", "Role",
    {
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ButtonGroup color="text" size="small" label="small">
                <Button
                  type="icon"
                  onClick={handleEditOpen(tableMeta.rowData[0])}
                  sx={{ backgroundColor: "#eee", color: "#0046AF" }}>
                  <EditIcon />
                </Button>

                <Button
                  type="icon"
                  sx={{ backgroundColor: "#fff", color: "#BF2026" }}
                  onClick={handleClickOpen(tableMeta.rowData[0])}
                  state={tableMeta.rowData}
                >
                  <DeleteIcon />
                </Button>
              </ButtonGroup>
            </>
          );
        }
      }
    }
  ];


  const modalStyle = {
    position: "absolute",
    top: "48%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
  };

  const getPositions = async () => {
    const positions = await AuthRepository.getPositions();
    setPositionList(positions);
  };


  const deleteFacilityUser = async (userId) => {
    try {
      await SettingsRepository.deleteUser(userId);
      await getFacilityUsers();
      setOpenDialogue(false);
    }
    catch (e) {
      console.log(e);
    }
  };


  const getFacilityUsers = async () => {
    const userList = [];
    const user = await AuthRepository.getUser();
    const response = await SettingsRepository.getFacilityUsers(user.facility_id);

    response.forEach(value => {
      userList.push({
        "id": value.id,
        "First Name": value.firstname,
        "Last Name": value.lastname,
        "Email": value.email,
        "Role": (value.position.name === "Normal User" ? "Normal User"
          : value.position.name === "Supervisor" ? "Supervisor" : "Admin"),
      });
    });

    setFacilityUsers(userList);
  };

  React.useEffect(() => {
    getFacilityUsers();
    getPositions();
  }, []);

  return <>

    <MUIDataTable
      className="muidatatable"
      title={"Manage Users"}
      data={facilityusers}
      columns={columns}
      options={options}
    />

    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleClose}>
            <Close />
          </a>

          <Typography sx={{ mb: 1, ml: 2, fontWeight: 600 }} variant="h6" component="div">
            Add User
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)} className="addUserForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "First name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="fname"
                      label="First name"
                      autoComplete="first-name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Last name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="lname"
                      label="Last name"
                      autoComplete="last-name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required", pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email address is invalid"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      autoComplete="email-none"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: -1 }}>
                <MuiPhoneNumber
                  id="phone"
                  value={phone}
                  disableAreaCodes={true}
                  InputProps={{ disableUnderline: true }}
                  fullWidth defaultCountry={'gh'}
                  sx={muiPhoneTextFieldStyle}
                  onChange={handleNumberChange}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="position"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Position is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      select
                      id="position"
                      label="Select Position"
                      autoComplete="lastname"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      sx={{ mt: -2 }}
                      color="secondary"
                      helperText={error ? error.message : null}
                    >
                      {positionList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isLoading}
                  sx={{ mt: 1, mb: 2, height: 45, width: "100%", fontWeight: 600, }}>
                  {isLoading ? 'Please wait ...' : 'Add User'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>

      <Modal open={openEditModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleEditClose}>
            <Close />
          </a>

          <Typography sx={{ mb: 1, ml: 2, fontWeight: 600 }} variant="h6" component="div">
            Edit User
          </Typography>

          <form onSubmit={handleSubmit(onSubmitEdit)} className="editUserForm">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="firstname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "First name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="fname"
                      label="First name"
                      autoComplete="first-name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="lastname"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Last name is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="lname"
                      label="Last name"
                      autoComplete="last-name"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6} sx={{ mt: -2, color: "#000" }}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Email is required", pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Email address is invalid"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      id="email"
                      type="email"
                      label="Email Address"
                      autoComplete="email-none"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      color="secondary"
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: -1 }}>
                <MuiPhoneNumber
                  id="phone"
                  value={phone}
                  disableAreaCodes={true}
                  InputProps={{ disableUnderline: true }}
                  fullWidth defaultCountry={'gh'}
                  sx={muiPhoneTextFieldStyle}
                  onChange={handleNumberChange}
                />

              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="position"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Position is required" }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      select
                      id="position"
                      label="Select Position"
                      autoComplete="lastname"
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      sx={{ mt: -2 }}
                      color="secondary"
                      helperText={error ? error.message : null}
                    >
                      {positionList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ mt: 1 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isLoading} sx={{ mt: -3, mb: 2, width: "100%", height: 45, fontWeight: 600 }}>
                  {isLoading ? 'Please wait ...' : 'Edit User'}
                </Button>
              </Grid>
            </Grid>

          </form>
        </Box>
      </Modal>

      <Dialog open={openDialogue} onClose={handleDialogueClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" show={userId}>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this user?
        </DialogTitle>
        <DialogActions>
          <Button color="error" onClick={() => deleteFacilityUser(userId)} autoFocus>
            Confirm Deletion
          </Button>
        </DialogActions>
      </Dialog>

    </div>

    {/* <SuccessAlert message={'Product successfully issued !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    <ErrorAlert message={'Oops! Failed to issue product, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} /> */}
  </>
}

export default function UserSettingsPage() {
  return <UserSettings />;
}
