import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MailIcon from "@mui/icons-material/Mail";
import BloodtypeIcon from "@mui/icons-material/Bloodtype";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import AuthRepository from "./pages/auth/AuthRepository";

const menuStyle = {
    fontWeight: 600,
};

export default function ListItems() {
    const [open, setOpen] = React.useState(false);
    const [openRequest, setOpenRequest] = React.useState(false);
    const [openDonation, setOpenDonation] = React.useState(false);
    const [openappointment, setAppointmentOpen] = React.useState(false);

    const user = AuthRepository.getUser();
    const runsDonations = user.facility.runs_donations;
    const keepsInventory = user.facility.keeps_inventory;
    let adminUser = user ? user.position.name == "Admin" : null

    const handleClick = () => {
        setOpen(!open);
    };

    const handleappointmentClick = () => {
        setAppointmentOpen(!openappointment);
    };
    const handleDonationClick = () => {
        setOpenDonation(!openDonation);
    };
    const handleRequestClick = () => {
        setOpenRequest(!openRequest);
    };

    return (
        <div>
            <ListItem button component={Link} to="/dashboard">
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={menuStyle} primary="Dashboard" />
            </ListItem>

            {runsDonations ? (
                <div>
                    <ListItemButton onClick={handleappointmentClick}>
                        <ListItemIcon>
                            <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={menuStyle} primary="Appointments" />
                        {openappointment ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openappointment} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/appointments/upcoming">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Upcoming Appointments" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/appointments">
                                <ListItemText primaryTypographyProps={menuStyle} primary="All Appointments" />
                            </ListItemButton>

                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/appointments/add">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Change Schedule" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </div>
            ) : (null)}


            {runsDonations ? (
                <div>
                    <ListItemButton onClick={handleDonationClick}>
                        <ListItemIcon>
                            <VolunteerActivismIcon />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={menuStyle} primary="Donations" />
                        {openDonation ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openDonation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/donations/unscreened">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Manage Donations" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/donations/donors/add">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Add Donor" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/donations/donors/enrolled">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Donors Enrolled" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/donations">
                                <ListItemText primaryTypographyProps={menuStyle} primary="Donation History" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </div>
            ) : (null)}

            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <BloodtypeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={menuStyle} primary="Manage Inventory" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/inventory">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Active Inventory" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/inventory/reaction">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Transfusion Reaction" />
                    </ListItemButton>

                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/inventory/history">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Inventory History" />
                    </ListItemButton>

                    {keepsInventory ? (
                        <ListItemButton sx={{ pl: 4 }} component={Link} to="/inventory/add">
                            <ListItemText primaryTypographyProps={menuStyle} primary="Add Product" />
                        </ListItemButton>) : (null)
                    }
                </List>
            </Collapse>

            <ListItemButton onClick={handleRequestClick}>
                <ListItemIcon>
                    <AddShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={menuStyle} primary="Blood Requests" />
                {openRequest ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openRequest} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/requests/new">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Make A Request" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/requests/history">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Your Requests" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/requests/active">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Active Requests On Network" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/requests/accepted">
                        <ListItemText primaryTypographyProps={menuStyle} primary="Accepted Requests" />
                    </ListItemButton>
                </List>
            </Collapse>

            {adminUser ?
                (<ListItem button component={Link} to="/billing">
                    <ListItemIcon>
                        <PointOfSaleIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={menuStyle} primary="Billing" />
                </ListItem>) : (null)
            }

            <ListItem button component={Link} to="/contact">
                <ListItemIcon>
                    <MailIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={menuStyle} primary="Contact Us" />
            </ListItem>
        </div>
    );
}
