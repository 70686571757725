import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthRepository from '../../auth/AuthRepository';
import InventoryManagementRepository from "../InventoryManagementRepository";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const IssuedProducts = () => {
  const user = AuthRepository.getUser();
  const [issuedProducts, setIssuedProducts] = React.useState([]);

  const options = {
    rowsPerPage: 30,
    filterType: 'checkbox',
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    textLabels: {
      body: {
        noMatch: "No issued products.",
      },
    },
  };

  const columns = [
    { name: 'product', label: 'Product', options: { sort: true, filter: true } },
    { name: 'requested_by', label: 'Requested By', options: { sort: true, filter: true } },
    { name: 'user', label: 'Issued By', options: { sort: true } },
    { name: 'issued_date', label: 'Issued Date', options: { sort: true } },
    { name: 'blood_type', label: 'Blood Type', options: { sort: true, filter: true } },
    { name: 'payment_method', label: 'Payment Method', options: { sort: true, filter: true } },
  ];

  const getProperDate = (date) => {
    return moment(date).format('ll');
  };

  const userFullName = (fname, lname) => {
    return `${fname} ${lname}`;
  };

  const getIssuedProducts = async () => {
    const productList = [];
    const facilityId = AuthRepository.getUser();
    const response = await InventoryManagementRepository.getIssuedProducts(facilityId);

    response.forEach(value => {
      productList.push({
        id: value.id,
        requested_by: value.facility.name,
        blood_type: value.product.blood_type.name,
        payment_method: value.payment_method.name,
        product: value.product.blood_component.name,
        issued_date: getProperDate(value.created_at),
        user: userFullName(value.user.firstname, value.user.lastname),
      });
    });
    setIssuedProducts(productList);
  };

  React.useEffect(() => {
    getIssuedProducts();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        <Link to="/inventory" variant="body2">
          <ArrowBackIcon sx={{ mr: 1 }} />
        </Link>
        Issued Products
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
        Manage Inventory / <strong>Issued Products</strong>
      </Typography>
    </div>

    <Box textAlign="center">
      <div style={{ height: 900, width: '100%' }}>
        <MUIDataTable
          className="muidatatable"
          data={issuedProducts}
          columns={columns}
          options={options}
        />
      </div>
    </Box>
  </>
}

export default function IssuedInventory() {
  return <IssuedProducts />;
}
