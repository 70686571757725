import moment from 'moment';
import * as React from 'react';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import Title from './../../../components/Title';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import '../Appointments.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MUIDataTable from "mui-datatables";
import ButtonGroup from '@mui/material/ButtonGroup';
import AuthRepository from '../../auth/AuthRepository';
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import AppointmentRepository from "../AppointmentRepository";
import UpcomingAppointmentsRepository from "../../dashboard/upcoming-appointments/UpcomingAppointmentsRepository";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";

const modalStyle = {
  p: 2,
  top: "50%",
  left: "50%",
  width: "50%",
  boxShadow: 24,
  borderRadius: 4,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

const options = {
  rowsPerPage: 30,
  filterType: 'checkbox',
  selectableRows: 'none',
  responsive: "vertical",
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No upcoming appointments.",
    },
  },
};

const UpcomingAppointments = () => {
const user = AuthRepository.getUser();
const [open, setOpen] = React.useState(false);
const [loading, setLoading] = React.useState(false);
const [anchorEl, setAnchorEl] = React.useState(null);
const [errorSnackbar, setErrorSnackbar] = React.useState(false);
const [successSnackbar, setSuccessSnackbar] = React.useState(false);

let [apptLen, setApptLen] = React.useState(-1);
let [upcomingAppointmentList, setUpcomingAppointmentList] = React.useState([]);

const showSuccessSnackbar = () => {
  setSuccessSnackbar(true);
};

const showErrorSnackbar = () => {
  setErrorSnackbar(true);
};

const closeSuccessSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSuccessSnackbar(false);
};

const closeErrorSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setErrorSnackbar(false);
};

const getProperDate = (date) => {
  return moment(date).format('lll');
};

const cancelAppointment = async (appointmentID) => {
  const status = {
    appointment_status_id : 3,
  };
  try {
    const data = await AppointmentRepository.cancelAppointment(appointmentID, status);
    if (data) {
      showSuccessSnackbar();
      await getUpcomingAppointments();
    }
  } catch (e) {
    setLoading(false);
    showErrorSnackbar();
  }
};

const getUpcomingAppointments = async () => {
  const appointmentList = [];
  const facilityId = user.facility_id
  const data = await UpcomingAppointmentsRepository.getUpcomingAppointments(facilityId);
  data.forEach(value => {
    appointmentList.push({
      id: value.id,
      fullname: value.donor.fname + ' ' + value.donor.lname,
      gender : value.donor.gender,
      phone: value.donor.phone,
      type: value.type.name,
      time: getProperDate(value.time),
      created_at: getProperDate(value.created_at),
    });
  });

  setUpcomingAppointmentList(appointmentList);
  setApptLen(appointmentList.length);
};

const handleClose = () => {
  setAnchorEl(null);
  setOpen(false);
};

  React.useEffect(() => {
    getUpcomingAppointments();
    enableTrackingForMetrics(user.id);
  },[apptLen]);

const columns = [
{ name: 'id', label: 'ID', options: { sort: false, filter: false,  display:false  }},
{ name: 'fullname', label: 'Donor', options: { sort: true, filter: false } },
{ name: 'gender', label: 'Gender', options: { sort: false, filter: false,  display:true  }},
{ name: 'phone', label: 'Phone', options: { sort: true, filter: false } },
{ name: 'type', label: 'Type', options: { sort: true, filter: true, display: false } },
{ name: "createdAt", label: "Booked On", options: {
  filter: false,
  sort: true,
  sortOrder: 'desc',
  customBodyRender: (value) => {
    return new moment(value).format('ll');
  },
  filterType: 'custom',
          customFilterListOptions: v => {
            if (v[0] && v[1]) {
              return `Start Date: ${v[0]}, End Date: ${v[1]}`;
            } else if (v[0]) {
              return `Start Date: ${v[0]}`;
            } else if (v[1]) {
              return `End Date: ${v[1]}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              const check = new Date(date);
              let from = new Date(filters[0]);
              let to = new Date(filters[1]);
              from.setDate(from.getDate() + 1);
              to.setDate(to.getDate() + 1);
              from = new Date(from).setHours(0,0,0,0);
              to = new Date(to).setHours(23,59,59,59);

              if(filters[0] && filters[1] && check >= to && check <= from) {
                return true;
              } else if (filters[0] && check >= to) {
                return true;
              } else if (filters[1] && check <= from) {
                return true;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel><strong>Booking Date</strong></FormLabel>
                <FormGroup row style={{marginTop: '20px' }}>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },

{
        name: "time",
        label: "Appointment Date Time",
        options: {
          filter: true,
          sort: true,
          sortOrder: 'desc',
          customBodyRender: (value) => {
            return new moment(value).format('lll');
          },
          filterType: 'custom',
          customFilterListOptions: v => {
            if (v[0] && v[1]) {
              return `Start Date: ${v[0]}, End Date: ${v[1]}`;
            } else if (v[0]) {
              return `Start Date: ${v[0]}`;
            } else if (v[1]) {
              return `End Date: ${v[1]}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              const check = new Date(date);
              let from = new Date(filters[0]);
              let to = new Date(filters[1]);
              from.setDate(from.getDate() + 1);
              to.setDate(to.getDate() + 1);
              from = new Date(from).setHours(0,0,0,0);
              to = new Date(to).setHours(23,59,59,59);

              if(filters[0] && filters[1] && check >= to && check <= from) {
                return true;
              } else if (filters[0] && check >= to) {
                return true;
              } else if (filters[1] && check <= from) {
                return true;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel><strong>Appointment Date</strong></FormLabel>
                <FormGroup row style={{marginTop: '20px' }}>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "Actions",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <ButtonGroup>
                <Link to="/donations/add" state={tableMeta.rowData} style={{ textDecoration: 'none', mr: 2 }}>
                  <Button type="icon" size="small" sx={{backgroundColor: "green", color:"#fff", fontSize:10, mt:0}}>
                    + Add Donation
                  </Button>
                </Link>
                <Button type="icon" size="small" sx={{backgroundColor: "red", color:"#fff", fontSize:10, mt:0}} onClick={() => cancelAppointment(tableMeta.rowData[0])}>
                  Cancel
                </Button>
              </ButtonGroup>
            </>
            );
          },
          filter:false
        }
      }];

  return <>
    <div style={{margin: "40px auto 30px"}}>
      <Title>
        Upcoming Appointments
      </Title>
      <Typography variant="body" component="div" sx={{mt:1, fontSize:11, color:"#202020"}}>
        <Link to="/appointments"><i>Appointments</i></Link>&nbsp; / &nbsp;<strong><i>Upcoming Appointments</i></strong>
      </Typography>
    </div>

    <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
      <Box textAlign="center">
        <MUIDataTable
        className="muidatatable"
        data={upcomingAppointmentList}
        columns={columns}
        options={options}
        />

      </Box>
      </Paper>

      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="cancellation_modal"
        >
          <Box sx={modalStyle}>
            <a
              href="#"
              style={{float: "right", mt: "-20px", textDecoration: "none"}}
              onClick={handleClose}
            >
              x Close
            </a>

            <FormLabel><strong>Reason for cancelling appointment</strong></FormLabel>
                <FormGroup row style={{marginTop: '20px' }}>
                  <TextField
                    id="reason"
                    type="text"
                    multiline
                    rows={2}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: '90%', marginRight: '5%' }}
                  />
                </FormGroup>

                <Button
                  className="cancelbtn"
                  sx={{backgroundColor: "red", color: "#fff", width:"200px", mt:2}}
                  // onClick={() => approveRequest(requestId, user.id, facilityId)}
                  autoFocus
                >
                  Confirm Cancellation
                </Button>
          </Box>
        </Modal>
      </div>

      <SuccessAlert message={'Appointment cancelled successfully!'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar}/>
      <ErrorAlert message={'Oops! Failed to cancel appointment, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar}/>

        </>
}

export default function AllUpcomingAppointments() {
  return <UpcomingAppointments />;
}
