import "./Contact.css";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Title from './../../components/Title';
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthRepository from "../auth/AuthRepository";
import { enableTrackingForMetrics } from "../../libs/activityTracking";
import Phone from "@mui/icons-material/Phone";

const Contact = () => {
  const user = AuthRepository.getUser();

  React.useEffect(() => {
    enableTrackingForMetrics(user.id);
  });
  return <>
  <div style={{margin: "0 0 200px"}} className="addproduct">

  <div style={{margin: "40px auto 30px"}}>
    <Title>
    <Link to="/inventory" variant="body2">
      <ArrowBackIcon sx={{mr:1}} />
    </Link>
    Contact Us</Title>
    </div>

  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
    <Box textAlign="left">
      <Typography variant="h5" textAlign='left' component="div" sx={{ fontSize:15, fontWeight:600, color:"#464D69"}}>
        Get In Touch
        <Divider variant="left" sx={{mt:2, mb:2, backgroundColor:"#ddd"}} />
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={7} lg={7} className="Contact-form">
          <p>Message</p>
          <TextField multiline rows={5} sx={{ width: "100%", border: "1px solid #ddd", borderRadius: 2 }}/>
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#0046AF", color:"#fff", width: 200 }}>
            Send Message
          </Button>
        </Grid>

        <Grid item xs={0} md={1} lg={1} />

        <Grid item xs={12} md={4} lg={4}>
          <p>Reach Out</p>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Phone />
                </ListItemIcon>
                <ListItemText primary="+233 054 558 1115" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
            <a href="https://web.facebook.com/waladigitalhealth/" rel="noreferrer" target="_blank">
              <ListItemButton>
                <ListItemIcon>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText primary="Follow Wala Facebook" />
              </ListItemButton>
            </a>
            </ListItem>
            <ListItem disablePadding>
            <a href="https://twitter.com/waladigital/" rel="noreferrer" target="_blank">
              <ListItemButton>
                <ListItemIcon>
                  <TwitterIcon />
                </ListItemIcon>
                <ListItemText primary="Follow Wala on Twitter" />
              </ListItemButton>
            </a>
            </ListItem>
            <ListItem disablePadding>
            <a href="https://www.linkedin.com/company/waladigital/" rel="noreferrer" target="_blank">
              <ListItemButton>
                <ListItemIcon>
                  <LinkedInIcon />
                </ListItemIcon>
                <ListItemText primary="Follow Wala on LinkedIn" />
              </ListItemButton>
            </a>
            </ListItem>
          </List>
        </Grid>

      </Grid>
    </Box>
  </Paper>

  </div>
    </>
}

export default function ContactUs() {
  return <Contact />;
}
