import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import DashboardRepository from './../DashboardRepository';
import AuthRepository from './../../auth/AuthRepository';

export default function DonorsEnrolledPerMonth() {
  let [count, setCount] = React.useState(0);

  const getCount = async () => {
    const user = AuthRepository.getUser();

    try {
        const data = await DashboardRepository.getTotalDonorsEnrolled(user.facility_id);
        console.log(data)
        if (data) {
            setCount(data);
        }
    } catch (e) {
        console.log(e);
    }
  };

  React.useEffect(() => {
    getCount();
  }, []);

  return (
    <Grid item xs={12} md={3} lg={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 120,
        }}
      >
        <React.Fragment>
          <Typography
            component="p"
            variant="subtitle" 
            sx={{
              fontSize: 15,
              fontWeight: 400,
            }}
            >
              Donors enrolled this month
            </Typography>
          <Typography component="p" variant="h3">
            { count }
          </Typography>
        </React.Fragment>
      </Paper>
    </Grid>
  );
}