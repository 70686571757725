import "./../Billing.css";
import moment from 'moment';
import * as React from "react";
import Grid from "@mui/material/Grid";
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PaystackButton } from 'react-paystack';
import TableHead from '@mui/material/TableHead';
import AuthRepository from "../../auth/AuthRepository";
import SettingsRepository from './../../settings/SettingsRepository'

export default function FacilityInvoiceModal(props) {
  let openModal = props.open;
  const rows = props.invoice;
  const status = props.status;
  const amountdue = props.amountdue;
  const description = props.description;

  const user = AuthRepository.getUser();
  const publicKey = "pk_test_adb2a40bb30dac52e6ee5f452949e2977a1d989a"
  const currency = "GHS"
  const email = user["email"]
  const name = user["facility"]["name"]
  const phone = user["phone"]

  const componentProps = {
    email,
    currency,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: () => alert("Thanks for doing business with us!"),
    onClose: () => alert("Wait! You haven't completed the payment."),
  }

  const dategen = rows.createdAt;
  const [open, setOpen] = React.useState(false);
  const [facility, setFacility] = React.useState({});

  const getFacility = async () => {
    const user = AuthRepository.getUser();
    const facility = await SettingsRepository.getFacility(user.facility_id);
    setFacility(facility);
  };

  React.useEffect(() => {
    setOpen(openModal);
    getFacility();
  }, [openModal]);

  return (
    <React.Fragment>

      <div style={{ padding: 20 }}>
        <h3 align="center" style={{ background: "#000", color: "#fff" }}>INVOICE</h3>

        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <p><strong>PAY TO:</strong>
              <br />
              <div>
                <small>Wala Digital Health</small>
                <br />
                <small>{facility.address}</small>
              </div>
            </p>
          </Grid>

          <Grid item xs={6} md={6} lg={6} justify='flex-end'>
            <img src={facility.photo} height="80px" style={{ float: "right" }} />
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <p><strong>CUSTOMER:</strong>
              <br />
              <div>
                <small>{facility.name}</small>
                <br />
                <small>{facility.address}</small>
              </div>
            </p>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <p align="right"><strong>Invoice #:</strong> {112233}</p>
            <p align="right"><strong>Date Generated:</strong> {moment(dategen).format('ll')}</p>
            <p align="right"><strong>Amount Due:</strong> GHS {amountdue}</p>
          </Grid>
        </Grid>

        <br />

        <Grid item xs={6} md={6} lg={6}>
          <p><strong>DESCRIPTION:</strong>
            <br />
            <div>
              <small>{description}</small>
            </div>
          </p>
        </Grid>

        <br />

        <Table size="small">
          <TableHead style={{ background: "#f0f0f0" }}>
            <TableRow>
              <TableCell>Payment Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Charge Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={rows.id}>
              <TableCell>Wala Charges</TableCell>
              <TableCell>{status}</TableCell>
              <TableCell>GHS {amountdue}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <PaystackButton className="paystack-button"  {...componentProps} amount={amountdue} />
        <h3 align="right"><strong>Total Amount Due:</strong> <span style={{ border: "1px solid #aaa", padding: "10px" }}> GHS {amountdue}</span></h3>
      </div>
    </React.Fragment>
  );
}
