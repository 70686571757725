import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LoginImage from "../../assets/images/loginbg.jpg";
import LogoLight from "../../assets/images/logo-light.png";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "./AuthRepository";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.waladigital.io/" target="_blank" style={{color:"#fff"}}>
        Wala Digital Health
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password length should be at least 6 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords should match'),
  });

  const validationOptions= { resolver: yupResolver(formSchema) };

  const { handleSubmit, control } = useForm(validationOptions);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
  }, []);

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const onSubmit = async (data) => {
    const userId = localStorage.getItem('user_id');
    const facilityId = localStorage.getItem('facility_id');
    await resetPassword(userId, facilityId, data.password, data.confirmPassword);
  };

  const resetPassword = async (userId, facilityId, password, confirmPassword) => {
    setLoading(true);
    const authData = {
      id: userId,
      facility_id: facilityId,
      password: password,
      confirm_password: confirmPassword
    };

    try {
      const user = await AuthRepository.resetPassword(authData);
      if (user) {
        setLoading(false);
        navigate('/login');
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  return (
      <Grid container component="main" sx={{ height: "100vh" }} className="authpage">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={6}
          sx={{
            backgroundImage: `linear-gradient(135deg, rgba(0,70,175,0.8) 0%, rgba(191,32,38,0.8) 100%), url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left top",
            height: "100vh"
          }} className="bgpage">
          <div style={{ position: "absolute", color: "white", top: 30, left: "19%", transform: "translateX(-50%)" }}>
            <Box component="img" sx={{ width: 150, maxHeight: { xs: 233, md: 167 }, maxWidth: { xs: 350, md: 250 } }} alt="Wala Digital Health." src={LogoLight} />
          </div>
          <Typography variant="body2" textAlign="center" sx={{ mt: "50%", fontSize: 22, color: "#ffffff", fontWeight: 600 }} gutterBottom>
            Connecting hospitals and donors
            <br /> for critical life-saving transfusions.
            <br />
          </Typography>
          <Typography variant="caption" textAlign="center" display="block" sx={{ mt: 2, color: "#ffffff" }} gutterBottom>
            A mission for better health.
          </Typography>

          <Typography variant="caption" align="center" sx={{ position: "absolute", bottom: 0, left: "11%", color: "#ffffff" }}>
            <Copyright sx={{ mb: 2, color: "#ffffff", fontSize: 11 }} />
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box sx={{ my: 8, mx: 4, mt: 10, display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography component="h1" variant="h5" sx={{ mb: 5, fontWeight: 600  }}>
              Reset password
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              {showError
                ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                  <AlertTitle>Reset Password Failed</AlertTitle>
                  {errorMessage}
                </Alert>
                : <div/>
              }
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    id="password"
                    type="password"
                    label="Choose Password"
                    autoComplete="password-none"
                    autoFocus
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    color="secondary"
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{ required: "Confirm Password is required" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    margin="normal"
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    id="confirm_password"
                    autoComplete="password-none"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    color="secondary"
                    helperText={error ? error.message : null}
                  />
                )}
              />

              <Box textAlign="center">
                <Button type="submit" color="secondary" variant="contained" sx={{ mt: 2, mb: 2, pr: 3, pl: 3, width: 250, height: 45, letterSpacing: 2 }} disabled={loading}>
                  {loading ? "Please wait ..." : "Submit"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
  );
};
