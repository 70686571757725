import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import { FormLabel, } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AuthRepository from '../../auth/AuthRepository';
import BillingRepository from './../BillingRepository';
import InvoiceModal from "./InvoiceModal";
import MUIDataTable from "mui-datatables";
import { PaystackButton } from 'react-paystack';

const modalStyle = {
  p: 2,
  top: "50%",
  left: "50%",
  width: "70%",
  boxShadow: 24,
  borderRadius: 4,
  overflow:'scroll',
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function Payables(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [invoiceItem, setInvoiceItem] = React.useState([]);

  const user = AuthRepository.getUser();
  const publicKey = process.env.REACT_APP_PAYSTACK_PK;
  const currency = "GHS";
  const email = user["email"];
  const name = user["facility"]["name"];
  const phone = user["phone"];
  const [invoices, setInvoices] = React.useState([]);
  const [amount, setAmount] = React.useState('');

  const updateBills = async (payment_id, payment_status) => {
    try {
      await BillingRepository.updateInvoice(payment_id, payment_status);
      await getPayables();
    } catch(e){
      console.log(e);
    }
  };

  const componentProps = {
    email,
    currency,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: (response) =>
      response.status === "success" ? (
      updateBills(response.reference.split("-")[1], "success")
      ) : (updateBills(response.reference.split("-")[1], "failed")),
    onClose: () => alert("Wait! You haven't completed the payment."),
  }

  const getPayables = async () => {
    const id = AuthRepository.getUser().facility_id;
    const data = await BillingRepository.getPayables(id);
    setInvoices(data.reverse());
  };

  const options = {
    rowsPerPage: 30,
    selectableRows: 'none',
    filterType: 'dropdown',
    responsive: "vertical",
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    textLabels: {
      body: {
        noMatch: "No payables.",
      },
    },
  };

  const columns = [
      { name: 'request', label: 'B. Type', options: { sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value['blood_type']['name']
          ) } },

      { name: 'request', label: 'Units', options: { sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value['units']
          ) } },

      { name: 'amount', label: 'Amount Due', options: { sort: false, filter: false, display:false  }},
      {
        name: "amount_billed",
        label: "Amount",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div>GHS {tableMeta.rowData[2]}</div>
                );
            }
        }
      },
      { name: 'payment_id', label: 'Payment ID', options: { sort: false, filter: false, display:false  }},
      { name: 'id', label: 'ID', options: { sort: false, filter: false, display:false  }},
      {
        name: "createdAt",
        label: "Generated On",
        options: {
          filter: true,
          sort: true,
          sortOrder: 'desc',
          customBodyRender: (value) => {
            return new moment(value).format('ll');
          },
          filterType: 'custom',
          customFilterListOptions: v => {
            if (v[0] && v[1]) {
              return `Start Date: ${v[0]}, End Date: ${v[1]}`;
            } else if (v[0]) {
              return `Start Date: ${v[0]}`;
            } else if (v[1]) {
              return `End Date: ${v[1]}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              const check = new Date(date);
              let from = new Date(filters[0]);
              let to = new Date(filters[1]);
              from.setDate(from.getDate() + 1);
              to.setDate(to.getDate() + 1);
              from = new Date(from).setHours(0,0,0,0);
              to = new Date(to).setHours(23,59,59,59);

              if(filters[0] && filters[1] && check >= to && check <= from) {
                return true;
              } else if (filters[0] && check >= to) {
                return true;
              } else if (filters[1] && check <= from) {
                return true;
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel><strong>Booking Date</strong></FormLabel>
                <FormGroup row style={{marginTop: '20px' }}>
                  <TextField
                    id="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    id="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: false,
        },
      },
      { name: 'supplier_facility', label: 'Pay To', options: { sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value['name']
          ) } },
      { name: 'status', label: 'Status', options: { sort: true, filter: true } },
      {
        name: "Action",
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <Button
              type="icon"
              color="secondary"
              onClick={() => {
                setInvoiceItem(tableMeta.rowData[0]);
                setAmount(tableMeta.rowData[2]);
                handleOpen();}}
              >
              <VisibilityIcon />
              </Button>
              <span>
              <PaystackButton
                className="paystack-button"
                {...componentProps}
                amount={tableMeta.rowData[2] * 100}
                reference = {tableMeta.rowData[4]}
              />
              </span>
            </>
            );
          }
        }
      }
      ];


  React.useEffect(() => {
    getPayables();
  }, []);


  return (
    <Box sx={{ width: '100%'}}>

    <MUIDataTable
      className="muidatatable"
      data={invoices}
      columns={columns}
      options={options}
    />


        <div>
          <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <a
              href="#"
              style={{float: "right", mt: "-20px", textDecoration: "none"}}
              onClick={handleClose}
            >
              x Close
            </a>
            <InvoiceModal invoice={invoiceItem} amountdue={amount} />
          </Box>
         </Modal>
        </div>
     </Box>

  );
}

