import axios from 'axios';

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const InventoryManagementRepository = {

  /**
   * Add new product to inventory.
   * @param {*} product
   * @returns
   */
  addProduct: async (product) => {
    const response = await axios.post(`${HOST}/products`, product);
    return response.data;
  },

  /**
   * Add new transfusion reaction for a product.
   * @returns
   * @param reaction
   */
  addReaction: async (reaction) => {
    const response = await axios.post(`${HOST}/reactions`, reaction);
    return response.data;
  },

  /**
   * Returns all blood types.
   * @returns
   */
  getProductByBarcode: async (barCode) => {
    const response = await axios.get(`${HOST}/products/barcode/${barCode}`);
    return response.data;
  },

  /**
   * Returns all blood components.
   * @returns
   */
  getBloodComponents: async () => {
    const response = await axios.get(`${HOST}/blood/components`);
    return response.data;
  },

  /**
   * Returns all blood types.
   * @returns
   */
  getBloodTypes: async () => {
    const response = await axios.get(`${HOST}/blood/types`);
    return response.data;
  },

  /**
   * Returns all blood types.
   * @returns
   */
  getBloodTypeById: async (bloodTypeId) => {
    const response = await axios.get(`${HOST}/blood/types/${bloodTypeId}`);
    return response.data;
  },

  issueProductInternal: async (data) => {
    const response = await axios.post(`${HOST}/issue/products/internal`, data);
    return response.data;
  },

  issueProductExternal: async (data) => {
    const response = await axios.post(`${HOST}/issue/products/external`, data);
    return response.data;
  },

  /**
   * Returns a list of all issued products.
   * @returns
   */
  getIssuedProducts: async (facilityId) => {
    const response = await axios.get(`${HOST}/issue/products/${facilityId}`);
    return response.data;
  },

  rejectProduct: async (data) => {
    const response = await axios.post(`${HOST}/products/reject`, data);
    return response.data;
  },

  /**
   * Returns all products in inventory.
   * @returns
   */
  getAllProducts: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/${facilityId}`);
    return response.data;
  },


  /**
   * Returns all products of all statuses.
   * @returns {Promise<any>}
   */
  getProductHistory: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/history/${facilityId}`);
    return response.data;
  },


  /**
   * Returns all transfusion reactions.
   * @returns {Promise<any>}
   */
  getTransfusionReactions: async (facilityId) => {
    const response = await axios.get(`${HOST}/reactions/hospital/${facilityId}`);
    return response.data;
  },


  /**
   * Returns transfusion reaction by ID.
   * @returns {Promise<any>}
   */
  getReactionById: async (reactionId) => {
    const response = await axios.get(`${HOST}/reactions/${reactionId}`);
    return response.data;
  },


  /**
   * Returns total unit for products by the blood type.
   * @returns
   */
  getUnitsOfProductByBloodType: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/total/blood/type/${facilityId}`);
    return response.data;
  },

  /**
   * Returns total unit for products by the blood component.
   * @returns
   */
  getUnitsOfProductByBloodComponent: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/total/blood/component/${facilityId}`);
    return response.data;
  },

  /**
   * Returns a list fo all delivery methods.
   * @returns
   */
  getDeliveryMethods: async () => {
    const response = await axios.get(`${HOST}/delivery/methods`);
    return response.data;
  },

  /**
   * Returns a list of all payment methods.
   * @returns
   */
  getPaymentMethods: async () => {
    const response = await axios.get(`${HOST}/payment/methods`);
    return response.data;
  },

  /**
   * Returns a list of all hospitals.
   * @returns
   */
  getFacilities: async () => {
    const response = await axios.get(`${HOST}/facilities`);
    return response.data;
  },

  /**
   * Returns a list of expiring products.
   * @returns
   */
  expiringProducts: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/expiring/${facilityId}`);
    return response.data;
  },

  /**
   * Returns a list of low inventory products.
   * @returns
   */
  lowInventoryProducts: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/low/blood/type/${facilityId}`);
    return response.data;
  },
};

export default InventoryManagementRepository;
