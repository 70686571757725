import * as React from "react";
import Box from "@mui/material/Box";
import "./../InventoryManagement.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import AuthRepository from "../../auth/AuthRepository";
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import InventoryManagementRepository from "../InventoryManagementRepository";

const modalStyle = {
  p: 4,
  top: "45%",
  left: "50%",
  width: "50%",
  boxShadow: 24,
  borderRadius: 4,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function IssueProductExternal(props) {
  let openExternal = props.open;
  const requestId = props.request;
  const productId = props.product;
  const handleCloseExternal = props.close;
  const facilityId = AuthRepository.getUser().facility_id;
  const { handleSubmit, reset, control } = useForm();

  const [open, setOpen] = React.useState(openExternal);
  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const [facilityList, setFacilityList] = React.useState([]);
  const [paymentMethodList, setPaymentMethodList] = React.useState([]);
  const [deliveryMethodList, setDeliveryMethodList] = React.useState([]);

  const buttonStyle = { mt: 4, textTransform: "capitalize" };

  const handleClose = () => {
    openExternal = false;
    setOpen(openExternal);
    handleCloseExternal();
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const getFacilities = async () => {
    const facilities = await InventoryManagementRepository.getFacilities();
    setFacilityList(facilities);
  };

  const getDeliveryMethods = async () => {
    const methods = await InventoryManagementRepository.getDeliveryMethods();
    setDeliveryMethodList(methods);
  };

  const getPaymentMethods = async () => {
    const methods = await InventoryManagementRepository.getPaymentMethods();
    setPaymentMethodList(methods);
  };

  const onSubmit = async (data) => {
    await issueProduct(data);
  };

  const issueProduct = async (data) => {
    setLoading(true);
    const user = AuthRepository.getUser();
    const body = {
      user_id: user.id,
      reason: data.reason,
      product_ids: [productId],
      facility_id: facilityId,
      blood_request_id: requestId,
      issued_facility_id: user.facility_id,
    };

    try {
      const response = await InventoryManagementRepository.issueProductExternal(body);
      if (response.id) {
        setLoading(false);
        handleClose();
        showSuccessSnackbar();
        reset();
      }
    }
    catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  React.useEffect(() => {
    setOpen(openExternal);
    getFacilities();
    getPaymentMethods();
    getDeliveryMethods();
  }, [openExternal]);

  return (
    <React.Fragment>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", marginTop: "-20px", textDecoration: "none" }} onClick={handleCloseExternal}>
            <Close />
          </a>

          <Box component="div">
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 0, fontSize: 16 }}>
              Issue Product Externally
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Controller
                    name="deliveryMethod"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Delivery method is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        select
                        id="outlined-select-bgroup"
                        label="Select Delivery Method"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%" }}
                        helperText={error ? error.message : null}
                      >
                        {deliveryMethodList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    name="paymentMethod"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Payment method is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        select
                        id="outlined-select-bgroup"
                        label="Select Payment Method"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      >
                        {paymentMethodList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <Controller
                    name="facility"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Facility is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        select
                        id="outlined-select-bgroup"
                        label="Select Facility"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%" }}
                        helperText={error ? error.message : null}
                      >
                        {facilityList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    name="reason"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Reason is required' }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        label="Enter reason"
                        size="small"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%", mt: 3 }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Grid container justifyContent="flex-end">
                    <Button color="secondary" type="submit" variant="contained" disabled={loading} sx={buttonStyle}>
                      {loading ? 'Please wait ...' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>

          </Box>
        </Box>
      </Modal>

      <SuccessAlert message={'Product successfully issued !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <ErrorAlert message={'Oops! Failed to issue product, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
    </React.Fragment>
  );
}
