import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import Title from './../../components/Title';
import Typography from '@mui/material/Typography';
import AuthRepository from "../auth/AuthRepository";
import AppointmentRepository from './AppointmentRepository';
import { FormGroup, FormLabel, TextField, } from '@mui/material';
import { enableTrackingForMetrics } from "../../libs/activityTracking";

const options = {
  rowsPerPage: 30,
  filterType: 'dropdown',
  selectableRows: 'none',
  responsive: "vertical",
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No appointments.",
    },
  },
};

const Appointment = () => {
const user = AuthRepository.getUser();
const [allAppointments, setAppointments] = React.useState([]);

const getProperDate = (date) => {
  return moment(date).format('lll');
};

const columns = [
{ name: 'id', label: 'ID', options: { sort: false, filter: false,  display:false  }},
{ name: 'fullname', label: 'Donor Name', options: { sort: true, filter: false } },
{ name: 'phone', label: 'Phone', options: { sort: true, filter: false } },
{ name: 'type', label: 'Type', options: { sort: true, filter: true } },
{
  name: "createdAt",
  label: "Booked on",
  options: {
    filter: true,
    sort: true,
    sortOrder: 'desc',
    customBodyRender: (value) => {
      return new moment(value).format('ll');
    },
    filterType: 'custom',
    customFilterListOptions: v => {
      if (v[0] && v[1]) {
        return `Start Date: ${v[0]}, End Date: ${v[1]}`;
      } else if (v[0]) {
        return `Start Date: ${v[0]}`;
      } else if (v[1]) {
        return `End Date: ${v[1]}`;
      }
      return false;
    },
    filterOptions: {
      names: [],
      logic(date, filters) {
        var check = new Date(date);
        var from = new Date(filters[0]);
        var to = new Date(filters[1]);
        from.setDate(from.getDate() + 1);
        to.setDate(to.getDate() + 1);
        from = new Date(from).setHours(0,0,0,0);
        to = new Date(to).setHours(23,59,59,59);

        if(filters[0] && filters[1] && check >= to && check <= from) {
          return true;
        } else if (filters[0] && check >= to) {
          return true;
        } else if (filters[1] && check <= from) {
          return true;
        }
        return false;
      },
      display: (filterList, onChange, index, column) => (
        <div>
          <FormLabel>Date</FormLabel>
          <FormGroup row>
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={filterList[index][0] || ''}
              onChange={event => {
                filterList[index][0] = event.target.value;
                onChange(filterList[index], index, column);
              }}
              style={{ width: '45%', marginRight: '5%' }}
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={filterList[index][1] || ''}
              onChange={event => {
                filterList[index][1] = event.target.value;
                onChange(filterList[index], index, column);
              }}
              style={{ width: '45%', marginRight: '5%' }}
            />
          </FormGroup>
        </div>
      ),
    },
    print: false,
  },
},
  {
    name: "scheduled_for",
    label: "Appt. Date",
    options: {
      filter: true,
      sort: true,
      sortOrder: 'desc',
      customBodyRender: (value) => {
        return new moment(value).format('lll');
      },
      filterType: 'custom',
      customFilterListOptions: v => {
        if (v[0] && v[1]) {
          return `Start Date: ${v[0]}, End Date: ${v[1]}`;
        } else if (v[0]) {
          return `Start Date: ${v[0]}`;
        } else if (v[1]) {
          return `End Date: ${v[1]}`;
        }
        return false;
      },
      filterOptions: {
        names: [],
        logic(date, filters) {
          const check = new Date(date);
          let from = new Date(filters[0]);
          let to = new Date(filters[1]);
          from.setDate(from.getDate() + 1);
          to.setDate(to.getDate() + 1);
          from = new Date(from).setHours(0,0,0,0);
          to = new Date(to).setHours(23,59,59,59);

          if(filters[0] && filters[1] && check >= to && check <= from) {
            return true;
          } else if (filters[0] && check >= to) {
            return true;
          } else if (filters[1] && check <= from) {
            return true;
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel>Date</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filterList[index][0] || ''}
                onChange={event => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filterList[index][1] || ''}
                onChange={event => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
            </FormGroup>
          </div>
        ),
      },
      print: false,
    },
  },
  { name: 'status', label: 'Status', options: { sort: true, filter: true } }
];

const getAppointments = async () => {
  const appointmentList = [];
  const user = AuthRepository.getUser();
  const facilityId = user.facility_id;
  const data = await AppointmentRepository.getAllAppointments(facilityId);
  data.forEach(value => {
    appointmentList.push({
      id: value.id,
      fullname: value.donor.fname + ' ' + value.donor.lname,
      phone: value.donor.phone,
      type: value.type.name,
      status: value.status.name,
      scheduled_for: getProperDate(value.time),
      createdAt: getProperDate(value.createdAt),
    });
  });

  setAppointments(appointmentList);
};

React.useEffect(() => {
  getAppointments();
  enableTrackingForMetrics(user.id);
}, []);

  return <>
    <div style={{margin: "40px auto 30px"}}>
      <Title>
        Appointments
      </Title>
      <Typography variant="body" component="div" sx={{mt:1, fontSize:11, color:"#202020"}}>
        <i>Appointments</i> &#8594; <Link to="/appointments/upcoming">Upcoming Appointments</Link>
      </Typography>
    </div>

    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Box textAlign="center">
        <MUIDataTable
          className="muidatatable"
          data={allAppointments}
          columns={columns}
          options={options}
        />
      </Box>
      </Paper>
  </>
}

export default function AllAppointments() {
  return <Appointment />;
}
