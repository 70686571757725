import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Title from './../../../components/Title';
import AuthRepository from '../../auth/AuthRepository';
import { enableTrackingForMetrics } from "../../../libs/activityTracking";
import UpcomingAppointmentsRepository from './UpcomingAppointmentsRepository';

export default function UpcomingAppointments() {
  const user = AuthRepository.getUser();

  let [rows, setRows] = React.useState([]);
  const facilityId = user.facility_id;

  const getProperDate = (date) => {
    return moment(date).format('lll');
  };

  const getUpcomingAppointments = async () => {
    const data = await UpcomingAppointmentsRepository.getUpcomingAppointments(facilityId);
    setRows(data.splice(0, 5));
  };

  React.useEffect(() => {
    getUpcomingAppointments();
    enableTrackingForMetrics(user.id);
  }, []);


  return (
    <React.Fragment>
      <Title>
        <Grid container spacing={2}>
          <Grid item xs={9}>Upcoming Appointments</Grid>
        </Grid>
      </Title>
      {
        rows.length !== 0 ? (
          <>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Appointment Type</TableCell>
                  <TableCell>Date &amp; Time</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.donor.fname} {row.donor.lname}</TableCell>
                    <TableCell>{row.donor.email}</TableCell>
                    <TableCell>{row.donor.phone}</TableCell>
                    <TableCell>{row.type.name}</TableCell>
                    <TableCell>{getProperDate(row.time)}</TableCell>
                    <TableCell>{row.status?.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>) : (
          <>
            <Box sx={{ textAlign: "center" }}>
              <p>No upcoming appointments yet!</p>
            </Box>
          </>
        )
      }
    </React.Fragment>
  );
}
