import * as Yup from 'yup';
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "../auth/AuthRepository";
import { useState } from "react";
import AlertTitle from "@mui/material/AlertTitle";
import SuccessAlert from "../../components/SuccessAlert";

export default function PasswordChange() {
  const navigate = useNavigate();

  const oldPasswordValidation = Yup.string().required('Old Password is required').min(6, 'Password length should be at least 6 characters');
  const newPasswordValidation = Yup.string().required('Password is required').min(6, 'Password length should be at least 6 characters');
  const confirmPasswordValidation = Yup.string().required('Confirm Password is required').oneOf([Yup.ref('newPassword')], 'Passwords should match');

  const formSchema = Yup.object().shape({
    oldPassword: oldPasswordValidation,
    newPassword: newPasswordValidation,
    confirmPassword: confirmPasswordValidation,
  });

  const validationOptions = { resolver: yupResolver(formSchema) };
  const { handleSubmit, control } = useForm(validationOptions);

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
    navigate('/login');
  };

  const onSubmit = async (data) => {
    const userId = AuthRepository.getUser().id;
    await changePassword(userId, data.oldPassword, data.newPassword);
  };

  const changePassword = async (userId, oldPassword, newPassword) => {
    setLoading(true);
    const authData = {
      id: userId,
      old_password: oldPassword,
      new_password: newPassword
    };

    try {
      const user = await AuthRepository.changePassword(authData);
      if (user) {
        setLoading(false);
        showSuccessSnackbar();
      }
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={6}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: -2, fontSize: 16 }}
        >
          Your new password must
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 5, fontSize: 14 }}
        >
          - Be at least 6 characters in length
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 1, fontSize: 14 }}
        >
          - Not be the same as your current password
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 1, fontSize: 14 }}
        >
          - Not contain common passphrases
        </Typography>

      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <Box component="div">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mt: -2, mb: 2, fontSize: 16 }}
          >
            <strong>Change Password</strong>
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            {showError
              ? <Alert severity="error" sx={{ mb: 2 }} onClose={dismissErrorAlert}>
                <AlertTitle>Reset Password Failed</AlertTitle>
                {errorMessage}
              </Alert>
              : <div></div>
            }
            <Controller
              name="oldPassword"
              control={control}
              defaultValue=""
              rules={{ required: "Your current password is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="old-password"
                  type="password"
                  label="Enter Current Password"
                  autoComplete="password-none"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{ required: "New password is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  id="password"
                  type="password"
                  label="Choose Password"
                  autoComplete="password-none"
                  autoFocus
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{ required: "Confirm Password is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  id="confirm_password"
                  autoComplete="password-none"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
              sx={{
                mt: 4,
                mb: 2,
                width: "100%",
                textTransform: "capitalize",
              }}
            >
              {loading ? 'Please wait ...' : 'Change Password'}
            </Button>
          </form>
        </Box>

      </Grid>

      <SuccessAlert message={'Password successfully updated'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    </Grid>
  );
}
