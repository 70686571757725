import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Title from './../../../components/Title';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MUIDataTable from "mui-datatables";
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import AuthRepository from '../../auth/AuthRepository';
import PendingRequestsRepository from './../../dashboard/pending-requests/PendingRequestsRepository';
import RequestManagementRepository from './../RequestManagementRepository';

const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  responsive: "vertical",
};

const ActiveRequests = () => {
  const [requests, setRequests] = React.useState([]);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const user = AuthRepository.getUser();

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
    window.location.assign('requests/accepted');
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const approveRequest = async (requestId) => {
    try {
      const data = await PendingRequestsRepository.approveRequest(requestId, user.id, user.facility_id);
      if (data) {
        showSuccessSnackbar();
        await getPendingRequests();
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const columns = [
    { name: 'id', label: 'ID', options: { sort: true, filter: false, display: false } },
    {
      name: 'facility', label: 'Requesting Facility', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: "created_at",
      label: "Time Requested",
      options: {
        filter: false,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).fromNow();
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            let from = new Date(filters[0]);
            let to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel><strong>Booking Date</strong></FormLabel>
              <FormGroup row style={{ marginTop: '20px' }}>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },

    { name: 'bloodtypeid', label: 'Blood Type ID', options: { sort: false, filter: false, display: false } },
    {
      name: 'blood_type', label: 'B. Type', options: {
        sort: true, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: 'blood_component', label: 'Component', options: {
        sort: true, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    { name: 'units', label: 'Units', options: { sort: true, filter: false } },
    {
      name: 'request_type', label: 'Request type', options: {
        sort: true, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button variant="contained"
                color="secondary"
                onClick={() => approveRequest(tableMeta.rowData[0])}
                style={{ color: "#fff", textDecoration: 'none', padding: "5px 20px", fontSize: "12px" }}>
                Accept
              </Button>
            </>
          );
        },
        filter: false,
        sort: true,
      }
    }
  ];

  const getPendingRequests = async () => {
    const data = await RequestManagementRepository.getIncomingRequests(user.facility_id);
    setRequests(data);
  };

  React.useEffect(() => {
    getPendingRequests();
  }, []);


  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        <Link to="/inventory" variant="body2">
          <ArrowBackIcon sx={{ mr: 1 }} />
        </Link>
        Active Blood Requests on Our Network</Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
        Blood Requests/<strong>Active requests on network</strong>
      </Typography>
    </div>

    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box textAlign="center">
        <MUIDataTable
          className="muidatatable"
          data={requests}
          columns={columns}
          options={options}
        />

      </Box>
    </Paper>

    <SuccessAlert message={'Request Accepted successfully !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    <ErrorAlert message={'An error occurred. Please try again.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />
  </>
}

export default function OutgoingRequests() {
  return <ActiveRequests />;
}
