import moment from 'moment';
import * as React from 'react';
import './InventoryManagement.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Title from './../../components/Title';
import Typography from '@mui/material/Typography';
import AuthRepository from '../auth/AuthRepository';
import InventoryUnits from './components/InventoryUnits';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductViewModal from "./components/ProductViewModal";
import RemoveProductModal from "./components/RemoveProductModal";
import IssueProductExternal from './components/IssueProductExternal';
import IssueProductInternal from './components/IssueProductInternal';
import { FormGroup, FormLabel, TextField, Tooltip, } from '@mui/material';
import { ArrowRightAltOutlined, DeleteOutline } from '@mui/icons-material';
import InventoryManagementRepository from './InventoryManagementRepository';
import { enableTrackingForMetrics } from "../../libs/activityTracking";

const options = {
  rowsPerPage: 30,
  filterType: 'checkbox',
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  selectableRows: 'none',
  responsive: "vertical",
  textLabels: {
    body: {
      noMatch: "No product(s) in inventory.",
    },
  },
  customSort: (data, colIndex, order, meta) => {
    return data.sort((a, b) => {
      return (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) * (order === 'desc' ? 1 : -1);
    });
  }
};

const InventoryPage = () => {
  const user = AuthRepository.getUser();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopoverId, setOpenedPopoverId] = React.useState(null);

  const [removeProductModal, setRemoveProductModal] = React.useState(false);
  const [productViewModal, setProductViewModal] = React.useState(false);

  const [openInternal, setOpenInternal] = React.useState(false);
  const [openExternal, setOpenExternal] = React.useState(false);

  const [products, setProducts] = React.useState([]);
  const [product, setProduct] = React.useState({});
  const [barcode, setBarcode] = React.useState([]);
  const [unitCount, setUnitCount] = React.useState([]);

  const [productId, setProductId] = React.useState([]);
  const [bloodType, setBloodType] = React.useState([]);
  const [bloodComponent, setBloodComponent] = React.useState([]);

  const facilityId = user.facility_id;
  let adminUser = user ? user.position.name === "Admin" : null;

  const columns = [
    { name: 'id', label: 'ID', options: { sort: false, filter: false, display: false } },
    {
      name: 'blood_type', label: 'Blood Type', options: {
        sort: true,
        filter: true,
        sortOrder: "desc",
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        ),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            if (order === 'desc')
              return obj1.data.name.localeCompare(obj2.data.name);
            else
              return obj2.data.name.localeCompare(obj1.data.name);
          };
        }
      }
    },
    {
      name: 'blood_component', label: 'Component', options: {
        sort: true, filter: true, sortOrder: 'asc',
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    { name: 'product_code', label: 'Product Code', options: { sort: true, filter: false } },
    { name: 'barcode', label: 'Barcode', options: { sort: true, filter: false } },
    {
      name: "received_date",
      label: "Date Received",
      options: {
        filter: true,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            let from = new Date(filters[0]);
            let to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Collection Date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "expiration_date",
      label: "Expiry Date",
      options: {
        filter: true,
        sort: true,
        sortOrder: 'desc',
        customBodyRender: (value) => {
          return new moment(value).format('ll');
        },
        filterType: 'custom',
        customFilterListOptions: v => {
          if (v[0] && v[1]) {
            return `Start Date: ${v[0]}, End Date: ${v[1]}`;
          } else if (v[0]) {
            return `Start Date: ${v[0]}`;
          } else if (v[1]) {
            return `End Date: ${v[1]}`;
          }
          return false;
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            const check = new Date(date);
            let from = new Date(filters[0]);
            let to = new Date(filters[1]);
            from.setDate(from.getDate() + 1);
            to.setDate(to.getDate() + 1);
            from = new Date(from).setHours(0, 0, 0, 0);
            to = new Date(to).setHours(23, 59, 59, 59);

            if (filters[0] && filters[1] && check >= to && check <= from) {
              return true;
            } else if (filters[0] && check >= to) {
              return true;
            } else if (filters[1] && check <= from) {
              return true;
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Expiry Date</FormLabel>
              <FormGroup row>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
                <TextField
                  id="endDate"
                  label="End Date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
        print: false,
      },
    },
    {
      name: "ACTION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                type="icon"
                color="secondary"
                sx={{ minWidth: '45px', '&:hover': 'background-color: "#cccccc !important"' }}
                onClick={() => { showProductViewModal(tableMeta.rowData[4]) }}>
                <Tooltip title="View product details">
                  <VisibilityIcon />
                </Tooltip>
              </Button>

              <Button color="secondary" onClick={() => handleOpenInternal(tableMeta.rowData[4])} >
                <Tooltip title="Issue Product">
                  <ArrowRightAltOutlined />
                </Tooltip>
              </Button>

              { adminUser ? (
                <Tooltip title="Remove Product">
                  <Button color="secondary" sx={{ minWidth: '45px' }} onClick={() => { showRemoveProductModal(tableMeta.rowData[0]) }} ><DeleteOutline /></Button>
                </Tooltip>
              ) : null
              }
            </>
          );
        }
      }
    }
  ];

  const showRemoveProductModal = (productId) => {
    setProductId(productId);
    setRemoveProductModal(true);
  };

  const showProductViewModal = (barcode) => {
    setBarcode(barcode);
    setProductViewModal(true);
  };

  const closePopover = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  const handleOpenInternal = async (productBarcode) => {
    setOpenInternal(true);
    closePopover();
    setProductId(productBarcode);
    await getAllProducts();
    const selectedProduct = products.filter((p) => p.barcode === productBarcode)[0];
    setProduct(selectedProduct);
  };

  const handleCloseExternal = () => {
    setAnchorEl(null);
    setOpenExternal(false);
  };

  const handleCloseInternal = () => {
    setOpenInternal(false);
    setAnchorEl(null);
  };

  const dismissProductViewModal = () => {
    setProductViewModal(false);
  };

  const dismissRemoveProductModal = () => {
    setRemoveProductModal(false);
  };

  const getAllProducts = async () => {
    const id = AuthRepository.getUser().facility_id;
    const productList = await InventoryManagementRepository.getAllProducts(id);
    setProducts(productList);
  };

  const getProductUnitsCount = async () => {
    const id = AuthRepository.getUser().facility_id;
    const counts = await InventoryManagementRepository.getUnitsOfProductByBloodType(id);
    setUnitCount(counts);
  };

  React.useEffect(() => {
    getAllProducts();
    getProductUnitsCount();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>Manage Inventory</Title>
    </div>

    <Box>
      <Typography variant="h5" textAlign='left' component="div" sx={{ mt: 1, fontSize: 16, fontWeight: 700, color: "#202020", width: "100%" }}>
        Select Blood Group
      </Typography>

      <Grid container spacing={1} sx={{ mt: 1 }}>
        <InventoryUnits units={unitCount} xs={4} md={1.5} lg={1.5} />
      </Grid>
    </Box>
    <br />
    <br />
    <React.Fragment>
      <Typography variant="h5" textAlign='left' component="div" sx={{ mt: 4, mb: 3, fontSize: 18, fontWeight: 600, color: "#202020", width: "100%", pb: 1.5 }}>
        Inventory
      </Typography>

      <MUIDataTable
        className="muidatatable"
        data={products}
        columns={columns}
        options={options}
      />

    </React.Fragment>

    <div>
      <IssueProductExternal open={openExternal} close={handleCloseExternal} product={productId} />
      <IssueProductInternal open={openInternal} closeInternal={handleCloseInternal} productId={productId} product={product} />
      <RemoveProductModal open={removeProductModal} closeModal={dismissRemoveProductModal} facility={facilityId} product={productId} />
      <ProductViewModal open={productViewModal} closeModal={dismissProductViewModal} product={productId} expiry={product.expiration_date} collected={product.received_date} bloodtype={bloodType} component={bloodComponent} barcode={barcode} />
    </div>
  </>
}

export default function AdminUserContent() {
  return <InventoryPage />;
}
