import axios from "axios";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const PendingRequestsRepository = {
  /**
   * Returns a list of pending requests.
   */
  getPendingRequests: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/incoming/pending/${facilityId}`);
    return response.data;
  },

  getRequestStatuses: async () => {
    const response = await axios.get(`${HOST}/blood/requests/statuses`);
    return response.data;
  },

  /**
   * Approves a request.
   *
   * @param {number} requestId
   * @param {number} supplierId
   * @param {string} message
   */
  approveRequest: async (requestId, userId, supplierId) => {
    const body = {
      request_id: requestId,
      approved_by: userId,
      approved_message: "Approved",
      supplier_facility_id: supplierId,
    };

    const response = await axios.post(`${HOST}/blood/requests/approve`, body);
    return response.data;
  },


  /**
   * Cancels a request.
   * @param {number} requestId
   * @param {number} facilityId
   */
  cancelRequest: async (requestId, facilityId) => {
    const body = {
      request_id: requestId,
      facility_id: facilityId,
    };

    return (await axios.post(`${ HOST }/blood/requests/cancel`, body)).data;
  },

  cancelAcceptedRequest: async (requestId, facilityId) => {
    const body = {
      request_id: requestId,
      facility_id: facilityId,
    };

    return (await axios.post(`${ HOST }/blood/requests/accepted/cancel`, body)).data;
  },


  /**
   * Declines a request.
   *
   * @param {number} requestId
   * @param {string} message
   */
  declineRequest: async (requestId, userId, message) => {
    const body = {
      request_id: requestId,
      declined_by: userId,
      declined_message: message,
    };

    const response = await axios.post(`${HOST}/blood/requests/decline`, body);
    return response.data;
  },

  getRequestedProduct: async (facilityId, bloodComponentId, bloodTypeId) => {
    const url= `${HOST}/blood/requests/product/${facilityId}/${bloodComponentId}/${bloodTypeId}`;
    return (await axios.get(url)).data;
  },
}

export default PendingRequestsRepository;
