import * as React from 'react';
import { uploadFile } from 'react-s3';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "../../auth/AuthRepository";
import SettingsRepository from './../SettingsRepository';
import SuccessAlert from "../../../components/SuccessAlert";
import LoadingAlert from '../../../components/LoadingAlert';

// Works with S3 image upload
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: 'lagos-state-hospitaldashboard',
  dirName: 'facility_images', /* optional */
  region: 'eu-west-3',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}

export default function InventorySettings() {
  const { handleSubmit, control, setValue } = useForm();
  const facilityId = AuthRepository.getUser().facility_id;
  const [isLoading, setIsLoading] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [loadingSnackbar, setLoadingSnackbar] = React.useState(false);
  const [facility, setFacility] = React.useState([]);

  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const onUpdateInventoryDataSubmit = async (data) => {
    await updateFacility(data);
    // window.location.reload();
  };

  const getFacilityDetails = async () => {
    const data = await SettingsRepository.getFacility(facilityId);
    initializeFacility(data);
    setFacility(data);
  };

  const initializeFacility = (facility) => {
    setValue('whole_blood_price', facility.whole_blood_price);
    setValue('wbexpiry', facility.whole_blood_expire);
    setValue('ffpexpiry', facility.ffp_expire);
    setValue('crcexpiry', facility.crc_expire);
    setValue('cryoexpiry', facility.cryo_expire);
    setValue('plateletxpiry', facility.platelets_expire);
    setValue('crc_price', facility.crc_price);
    setValue('cryo_price', facility.cryo_price);
    setValue('ffp_price', facility.ffp_price);
    setValue('platelets_price', facility.platelets_price);
  };

  const updateFacility = (data) => {
    setIsLoading(true);
    dismissErrorAlert();
    const formData = {
      id: facilityId,
      ffp_price: data.ffp_price,
      crc_price: data.crc_price,
      crc_expire: data.crcexpiry,
      ffp_expire: data.ffpexpiry,
      cryo_price: data.cryo_price,
      cryo_expire: data.cryoexpiry,
      whole_blood_expire: data.wbexpiry,
      platelets_expire: data.plateletxpiry,
      platelets_price: data.platelets_price,
      whole_blood_price: data.whole_blood_price,
    };

    try {
      const facility = SettingsRepository.updateFacility(facilityId, formData);
      if (facility) {
        setIsLoading(false);
        showSuccessSnackbar();
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  React.useEffect(() => {
    getFacilityDetails().then();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdateInventoryDataSubmit)}>
        <Typography variant="body" component="h3" sx={{ mt: 1, ml: 1, fontSize: 13, color: "#202020" }}>
          <strong>Product expiry settings (use days)</strong>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Whole blood</strong></p>
            <Controller
              name="wbexpiry"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="wbexpiry"
                  type='number'
                  label="expiry"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>FFP</strong></p>
            <Controller
              name="ffpexpiry"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="ffpexpiry"
                  type='number'
                  label="expiry"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>CRC</strong></p>
            <Controller
              name="crcexpiry"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="crcexpiry"
                  type='number'
                  label="expiry"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Platelet</strong></p>
            <Controller
              name="plateletxpiry"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="plateletxpiry"
                  type='number'
                  label="expiry"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>


          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Cryoprecipitate</strong></p>
            <Controller
              name="cryoexpiry"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="cryoexpiry"
                  type='number'
                  label="expiry"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>


        <br />
        <Grid container spacing={3}>
          <Typography variant="body" component="h3" sx={{ mt: 6, mb: -2, ml: 4, fontSize: 13, color: "#202020", width: "100%" }}>
            <strong>Product pricing settings (GHS)</strong>
          </Typography>
          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Whole blood</strong></p>
            <Controller
              name="whole_blood_price"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="whole_blood_price"
                  type='number'
                  label="price"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>FFP</strong></p>
            <Controller
              name="ffp_price"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="ffp_price"
                  type='number'
                  label="price"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>CRC</strong></p>
            <Controller
              name="crc_price"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="crc_price"
                  type='number'
                  label="price"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Platelet</strong></p>
            <Controller
              name="platelets_price"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="platelets_price"
                  type='number'
                  label="price"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} md={2} lg={2}>
            <p><strong>Cryoprecipitate</strong></p>
            <Controller
              name="cryo_price"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="cryo_price"
                  type='number'
                  label="price"
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

        </Grid>


        <Button
          type="submit"
          variant="contained"
          color="secondary"
          disabled={isLoading}
          sx={{
            mt: 3,
            mb: 2,
            ml: 3,
            width: 200,
            textTransform: "capitalize",
          }}
        >
          {isLoading ? 'Please wait ...' : 'Submit'}
        </Button>


      </form>


      <LoadingAlert title={'Uploading Facility Logo'} openSnackbar={loadingSnackbar} />
      <SuccessAlert message={'Facility successfully updated'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    </div>
  );
}
