import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import Paper from '@mui/material/Paper';
import Modal from "@mui/material/Modal";
import MUIDataTable from "mui-datatables";
import Button from '@mui/material/Button';
import Title from './../../../components/Title';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import AuthRepository from '../../auth/AuthRepository';
import ErrorAlert from "../../../components/ErrorAlert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SuccessAlert from "../../../components/SuccessAlert";
import RequestFulfillment from '../components/RequestFulfillment';
import RequestManagementRepository from './../RequestManagementRepository';
import PendingRequestsRepository from './../../dashboard/pending-requests/PendingRequestsRepository';
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const options = {
  filterType: 'checkbox',
  selectableRows: 'none',
  responsive: "vertical",
  rowsPerPage: 30,
  rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
  textLabels: {
    body: {
      noMatch: "No accepted requests.",
    },
  },
};

const ActiveRequests = () => {
  const user = AuthRepository.getUser();
  const facilityId = user.facility_id;

  const [open, setOpen] = React.useState(false);
  const [approvedrequests, setApprovedrequests] = React.useState([]);
  const [bloodrequest, setBloodRequest] = React.useState([]);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    setErrorSnackbar(false);
  };

  const cancelRequest = async (requestId) => {
    try {
      const data = await PendingRequestsRepository.cancelAcceptedRequest(requestId, facilityId);
      if (data) {
        showSuccessSnackbar();
        await getApprovedOutgoingRequests();
      }
    } catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    getApprovedOutgoingRequests();
    setOpen(false)
  };


  const columns = [
    { name: 'id', label: 'ID', options: { sort: true, filter: false, display: false } },
    {
      name: 'blood_type', label: 'Blood Type', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: 'blood_component', label: 'Component', options: {
        sort: false, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name'])
      }
    },
    {
      name: 'request_type', label: 'Type', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    { name: 'units', label: 'Units', options: { sort: true, filter: false } },
    {
      name: 'facility', label: 'Requesting Facility', options: {
        sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: 'status', label: 'Status'.toUpperCase(), options: {
        sort: false, filter: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['name']
        )
      }
    },
    {
      name: "Actions".toUpperCase(),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ButtonGroup color="secondary" size="small" label="small">
                <Button
                  size="small"
                  type="icon"
                  onClick={() => {
                    setBloodRequest(tableMeta.rowData);
                    handleOpen();
                  }}
                  sx={{ backgroundColor: "green", color: "#fff", fontSize: 10, ml: 1, mr: 1 }}
                >
                  Issue Blood
                </Button>

                <Button
                  size="small"
                  type="icon"
                  onClick={() => cancelRequest(tableMeta.rowData[0])}
                  sx={{ backgroundColor: "red", color: "#fff", fontSize: 10, ml: 1 }}
                >
                  Cancel
                </Button>

              </ButtonGroup>
            </>
          );
        },
        filter: false,
        sort: false,
      }
    }
  ];


  const getApprovedOutgoingRequests = async () => {
    const data = await RequestManagementRepository.getApprovedIncomingRequests(user.facility_id);
    setApprovedrequests(data.reverse());
  };

  React.useEffect(() => {
    getApprovedOutgoingRequests();
  }, []);


  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        <Link to="/inventory" variant="body2">
          <ArrowBackIcon sx={{ mr: 1 }} />
        </Link>
        Accepted requests</Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
        Blood Requests/<strong>Accepted requests</strong>
      </Typography>
    </div>

    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box textAlign="center">

        <MUIDataTable
          className="muidatatable"
          data={approvedrequests}
          columns={columns}
          options={options}
        />


      </Box>
    </Paper>

    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: "-20px", textDecoration: "none" }} onClick={handleClose}>
            <Close />
          </a>
          <RequestFulfillment
            bloodtReqId={bloodrequest[0]}
            reqFacility={bloodrequest[5]}
            bloodGroup={bloodrequest[1]}
            bloodComponent={bloodrequest[2]}
            unitsReq={bloodrequest[4]}
            closeFunction={handleClose}
          />
        </Box>
      </Modal>
    </div>

    <SuccessAlert message={'Request cancelled successfully!'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
    <ErrorAlert message={'Oops! Failed to cancel request, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar} />

  </>
}

export default function OutgoingRequests() {
  return <ActiveRequests />;
}
