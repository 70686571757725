import axios from 'axios';

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const RequestManagementRepository = {

  getBloodTypes: async () => {
    const response = await axios.get(`${HOST}/blood/types`);
    return response.data;
  },

  getLiveInventory: async () => {
    const response = await axios.get(`${HOST}/products/available`);
    return response.data;
  },

  getIncomingRequests: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/incoming/pending/${facilityId}`);
    return response.data;
  },


  /**
   * Returns a list of approved requests
   * @returns
   * @param facilityId
   */
  getApprovedOutgoingRequests: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/outgoing/approved/${facilityId}`);
    return response.data;
  },


  /**
   * Returns a list of outgoing requests from the facility
   * @returns
   * @param facilityId
   */
  getOutgoingRequests: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/outgoing/${facilityId}`);
    return response.data;
  },

  /**
   * Returns a list of incoming approved requests
   * @returns
   * @param facilityId
   */
  getApprovedIncomingRequests: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/incoming/approved/${facilityId}`);
    return response.data;
  },


  /**
   * Make new blood request.
   * @returns
   * @param data
   */
  makeBloodRequest: async (data) => {
    const response = await axios.post(`${HOST}/blood/requests/create`, data);
    return response.data;
  },


  /**
   * Issue blood/ fulfill blood request.
   * @returns
   * @param data
   */
  fullfillRequest: async (data) => {
    const response = await axios.post(`${HOST}/issue/products/external`, data);
    return response.data;
  },

};

export default RequestManagementRepository;
