import * as React from 'react';
import Grid from '@mui/material/Grid';
import { uploadFile } from 'react-s3';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { Controller, useForm } from "react-hook-form";
import AuthRepository from "../../auth/AuthRepository";
import SettingsRepository from './../SettingsRepository';
import SuccessAlert from "../../../components/SuccessAlert";
import LoadingAlert from "../../../components/LoadingAlert";

// Works with S3 image upload
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: 'lagos-state-hospitaldashboard',
  dirName: 'facility_images', /* optional */
  region: 'eu-west-3',
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
}


export default function GeneralSettings() {
  const { handleSubmit, control, setValue } = useForm();
  const facilityId = AuthRepository.getUser().facility_id;
  const [isLoading, setIsLoading] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [facility, setFacility] = React.useState([]);

  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [facilityphoto, setFacilityPhoto] = React.useState("");

  const showErrorAlert = () => {
    setShowError(true);
  };

  const dismissErrorAlert = () => {
    setShowError(false);
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showLoadingSnackbar = () => {
    setShowLoading(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
    window.location.reload();
  };

  const hideLoadingSnackbar = () => {
    setShowLoading(false);
  };

  const upload = (e) => {
    showLoadingSnackbar();
    const file = e.target.files[0]
    uploadFile(file, config)
      .then(data => {
        setFacilityPhoto(data.location)
        console.log(data);
        hideLoadingSnackbar();
      })
      .catch(err => {
        console.error(err);
        hideLoadingSnackbar();
      });
  }

  const onUpdateFacilitySubmit = async (data) => {
    updateFacility(data);
  };

  const getFacilityDetails = async () => {
    const data = await SettingsRepository.getFacility(facilityId);
    initializeFacility(data);
    setFacility(data);
  };

  const initializeFacility = (facility) => {
    setValue('facility_name', facility.name);
    setValue('address', facility.address);
    setValue('type', facility.type);
    setValue('photo', facility.photo);
  };

  const updateFacility = (data) => {
    setIsLoading(true);
    dismissErrorAlert();
    const formData = {
      id: facilityId,
      name: data.facility_name,
      address: data.address,
      photo: facilityphoto,
    };

    try {
      const facility = SettingsRepository.updateFacility(facilityId, formData);
      if (facility) {
        setIsLoading(false);
        showSuccessSnackbar();
      }
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(e.response.data.error);
      showErrorAlert();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  React.useEffect(() => {
    getFacilityDetails();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onUpdateFacilitySubmit)}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={4} lg={4}>
            <p><strong>Facility Name</strong></p>
            <Controller
              name="facility_name"
              control={control}
              defaultValue=""
              rules={{ required: "Facility name is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="name"
                  type='text'
                  size="small"
                  variant="outlined"
                  placeholder={facility.name}
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <p><strong>Facility Address</strong></p>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="address"
                  type='text'
                  size="small"
                  variant="outlined"
                  value={value}
                  error={!!error}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <p><strong>Facility Logo/Image</strong></p>
            <Controller
              name="photo"
              control={control}
              defaultValue={facility.photo}
              rules={{ required: false }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  id="photo"
                  type='file'
                  size="small"
                  variant="outlined"
                  placeholder={facility.photo}
                  defaultValue={facility.photo}
                  error={!!error}
                  onChange={(e) => { onChange(); upload(e); }}
                  sx={{ width: "100%" }}
                  helperText={error ? error.message : null}
                />
              )}
            />
            {/* <div><span><img src={facility.photo} alt={facility.name + " logo"} width="48px" /></span></div> */}
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            sx={{
              mt: 3,
              mb: 2,
              ml: 3,
              width: 200,
              textTransform: "capitalize",
            }}
          >
            {isLoading ? 'Please wait ...' : 'Submit'}
          </Button>

        </Grid>
      </form>


      <SuccessAlert message={'Facility successfully updated'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar} />
      <LoadingAlert title={'Uploading Profile Photo'} openSnackbar={showLoading} closeSnackbar={hideLoadingSnackbar} />
    </div>
  );
}
