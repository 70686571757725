import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Modal from "@mui/material/Modal";
import EditIcon from '@mui/icons-material/Edit';
import QuestionTabs from '../components/QuestionTabs';
import AuthRepository from '../../auth/AuthRepository';
import DonationManagementRepository from "../DonationManagementRepository";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";
import { Close } from "@mui/icons-material";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

const UnscreenedProducts = () => {
  const user = AuthRepository.getUser();

  const [donations, setDonations] = React.useState([]);
  const [donationId, setDonationId] = React.useState([]);
  const [donorId, setDonorId] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    getAllDonations();
    setOpen(false)
  };

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    responsive: "vertical",
    rowsPerPage: 30,
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    textLabels: {
      body: {
        noMatch: "No unscreened product.",
      },
    },
  };

  const columns = [
    { name: 'donation', label: 'Donation', options: { sort: true, filter: false, display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['id']
          ) } },
    { name: 'id', label: 'ID', options: { sort: true, filter: false, display: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          value['id']
          ) } },
    { name: 'donor', label: 'Donor', options: { sort: true, filter: false, display:false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value['id']
          ) } },
    { name: 'donor', label: 'Donor', options: { sort: true, filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
            value['fname'] + " " + value['lname']
          ) } },
    { name: 'blood_component', label: 'Component', options: { sort: false, filter: false ,
      customBodyRender: (value, tableMeta, updateValue) => (
            value['name']
          ) } },
    { name: 'batch_number', label: 'Batch #', options: { sort: true, filter: true } },
    { name: 'received_date', label: 'Donation Date', options: { sort: true, filter: true } },
    { name: 'status', label: 'Status', options: { sort: false, filter: true ,
      customBodyRender: (value, tableMeta, updateValue) => (
            value['name']
          ) } },
    {
        name: "ACTIONS",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <ButtonGroup color="secondary" size="small" label="small">
              <Button
              type="icon"
              size="small"
              onClick={() => {
                setDonationId(tableMeta.rowData[0]["id"]);
                setDonorId(tableMeta.rowData[2]["id"]);
                handleOpen();
              }}
              sx={{backgroundColor: "green", fontSize:10, color:"#fff", ml:1}}
              >
              <EditIcon sx={{mr:1, fontSize:12}} /> Manage
              </Button>
              </ButtonGroup>
            </>
            );
          },
          filter: false,
          sort: false,
        }
      }
  ];

  const getAllDonations = async () => {
    const user = await AuthRepository.getUser();
    const donations = await DonationManagementRepository.getUnscreenedBlood(user.facility_id);
    setDonations(donations);
  };

  React.useEffect(() => {
    getAllDonations();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        <Link to="/inventory" variant="body2">
          <ArrowBackIcon sx={{ mr: 1 }} />
        </Link>
        Unscreened Products
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
        Manage Donation / <strong>Unscreened Products </strong>
      </Typography>
    </div>

    <Box textAlign="center">
      <div style={{ height: 900, width: '100%' }}>
        <MUIDataTable
            className="muidatatable"
            data={donations}
            columns={columns}
            options={options}
        />
      </div>
    </Box>

    <div>
        <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <a href="#" style={{float: "right", mt: "-20px", textDecoration: "none"}} onClick={handleClose}>
              <Close />
            </a>
            <QuestionTabs donationID={donationId} donorID={donorId} closeFunction={handleClose} />
          </Box>
        </Modal>
      </div>
  </>
}

export function UnscreenedProductPage() {
  return <UnscreenedProducts/>
}
