import * as React from "react";
import Box from "@mui/material/Box";
import "./../InventoryManagement.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Close } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import ErrorAlert from "../../../components/ErrorAlert";
import SuccessAlert from "../../../components/SuccessAlert";
import InventoryManagementRepository from "../InventoryManagementRepository";
import AuthRepository from "../../auth/AuthRepository";

const modalStyle = {
  p: 4,
  top: "45%",
  left: "50%",
  width: "50%",
  boxShadow: 24,
  borderRadius: 3,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

export default function RemoveProductModal(props) {
  let openModal = props.open;
  const productId = props.product;
  const user = AuthRepository.getUser();
  const handleCloseModal = props.closeModal;
  const { handleSubmit, reset, control } = useForm();

  const [open, setOpen] = React.useState(openModal);
  const [loading, setLoading] = React.useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [successSnackbar, setSuccessSnackbar] = React.useState(false);

  const buttonStyle = { mt: 2, color: "#ffffff", width: 150, textTransform: "capitalize" };

  const handleClose = () => {
    openModal = false;
    setOpen(openModal);
    handleCloseModal();
  };

  const showSuccessSnackbar = () => {
    setSuccessSnackbar(true);
  };

  const showErrorSnackbar = () => {
    setErrorSnackbar(true);
  };

  const closeSuccessSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessSnackbar(false);
  };

  const closeErrorSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbar(false);
  };

  const onSubmit = async (data) => {
    await rejectProduct(data);
  };

  const rejectProduct = async (data) => {
    setLoading(true);
    const body = {
      user_id: user.id,
      facility_id: user.facility_id,
      reason: data.reason,
      product_id: productId,
    }

    try {
      const response = await InventoryManagementRepository.rejectProduct(body);
      if (response.id) {
        setLoading(false);
        handleClose();
        showSuccessSnackbar();
        reset();
      }
      else {
        setLoading(false);
        showErrorSnackbar();
      }
    }
    catch (e) {
      setLoading(false);
      showErrorSnackbar();
    }
  };

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <React.Fragment>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", marginTop: -5, textDecoration: "none" }} onClick={handleCloseModal}>
            <Close/>
          </a>

          <Box component="div" sx={{ ml: 2}}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 5, fontSize: 16, ml: -3 }}>
              Remove Product
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                  <Controller
                    name="reason"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Reason is required'}}
                    render={({ field: { onChange, value }, fieldState: {error } }) => (
                      <TextField
                        id="outlined-select-bgroup"
                        size="small"
                        multiline
                        rows={4}
                        label="Enter reason why this blood was not used"
                        variant="outlined"
                        value={value}
                        error={!!error}
                        onChange={onChange}
                        sx={{ width: "100%" }}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />

                  <Grid container justifyContent="flex-end">
                    <Button color="secondary" type="submit" variant="contained" disabled={loading} sx={buttonStyle}>
                      { loading ? 'Please wait ...' : 'Remove product' }
                    </Button>
                  </Grid>
                </Grid>
            </form>
          </Box>
        </Box>
      </Modal>

      <SuccessAlert message={'Product successfully removed !'} successSnackbar={successSnackbar} closeSuccessSnackbar={closeSuccessSnackbar}/>
      <ErrorAlert message={'Oops! Failed to remove blood, something unexpected happened.'} errorSnackbar={errorSnackbar} closeErrorSnackbar={closeErrorSnackbar}/>
    </React.Fragment>
  );
}
