import axios from "axios";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const UpcomingAppointmentsRepository = {
  /**
   * Returns a list of upcoming appointments.
   */
  getUpcomingAppointments: async (facilityId) => {
    const response = await axios.get(`${HOST}/appointments/upcoming/${facilityId}`);
    return response.data;
  },

}

export default UpcomingAppointmentsRepository;