import DashboardRepository from "../pages/dashboard/DashboardRepository";

export const enableTrackingForMetrics = async (userId) => {
  try {
    const response = await DashboardRepository.trackActivity(userId);
    if (response.id !== null) {
      console.log('metrics analytics info collection was successful');
    } else {
      console.error('metrics analytics info collection failed');
    }
  } catch (e) {
    console.error('Exception: metrics analytics info collection failed');
    console.log('More details: ', e);
  }
};
