import axios from "axios";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const BillingRepository = {
  /**
   * Gets incoming payments
   */
  getReceivables: async (facilityId) => {
    const response = await axios.get(`${HOST}/payment/receivable/${facilityId}`);
    return response.data;
  },


  /**
   * Gets outgoing payments.
   * 
   * @param {number} facilityId
   */
  getPayables: async (facilityId) => {
    const response = await axios.get(`${HOST}/payment/payable/${facilityId}`);
    return response.data;
  },

  /**
   * Gets receipts for payments received.
   * 
   * @param {number} facilityId
   */
  getPaymentReceived: async (facilityId) => {
    const response = await axios.get(`${HOST}/payment/receivable/history/${facilityId}`);
    return response.data;
  },

  /**
   * Gets receipts for payments made.
   * 
   * @param {number} facilityId
   */
  getPaymentMade: async (facilityId) => {
    const response = await axios.get(`${HOST}/payment/payable/history/${facilityId}`);
    return response.data;
  },

  /**
   * Facility invoices.
   * @param {*} facilityId 
   * @returns 
   */
  getFacilityInvoice: async(facilityId) => {
    const response = await axios.get(`${HOST}/payment/billing/${facilityId}`);
    return response.data;
  },

  /**
   * Update unpaid invoices.
   * 
   * @param {number} id
   * @param {string} status 
   */
  updateInvoice: async (payment_id, payment_status) => {
    const body = {
      id: payment_id,
      status: payment_status,
    };
    const response = await axios.post(`${HOST}/payment/update/`, body);
    return response.data;
  },

}

export default BillingRepository;