import axios from "axios";
import moment from "moment";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const DashboardRepository = {
  getDonorsTotalCount: async (facilityId) => {
    const response = await axios.get(`${HOST}/donors/total/${facilityId}`);
    return response.data;
  },

  getDonationsTotalCount: async (facilityId) => {
    const response = await axios.get(`${HOST}/donations/total/${facilityId}`);
    return response.data;
  },

  getTotalDonorsEnrolled: async (facilityId) => {
    const month = new Date().getMonth();
    const formattedMonth = moment().month(month).format('MM');
    const response = await axios.get(`${HOST}/donors/total/month/${formattedMonth}/${facilityId}`);
    return response.data;
  },

  getTotalBloodInInventory: async (facilityId) => {
    const response = await axios.get(`${HOST}/products/total/${facilityId}`);
    return response.data;
  },

  getRequestTotals: async (facilityId) => {
    const response = await axios.get(`${HOST}/blood/requests/total/${facilityId}`);
    return response.data;
  },

  /**
   *
   * @param userId
   * @returns {Promise<any>}
   */
  trackActivity: async (userId) => {
    const body = { user_id: userId };
    return (await axios.post(`${ HOST }/analytics`, body)).data;
  },
};

export default DashboardRepository;
