import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import MUIDataTable from "mui-datatables";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Modal from "@mui/material/Modal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import InventoryManagementRepository from "../InventoryManagementRepository";
import AuthRepository from '../../auth/AuthRepository';
import { enableTrackingForMetrics } from "../../../libs/activityTracking";
import { Close } from "@mui/icons-material";
import TransfusionReactionDetails from "../components/TransfusionReactionDetails";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const TransfusionReaction = () => {
  const user = AuthRepository.getUser();

  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [reactionList, setReactionList] = React.useState([]);
  const [reactionDetails, setReactionDetails] = React.useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    responsive: "vertical",
    rowsPerPage: 30,
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    enableNestedDataAccess: '.',
    textLabels: {
      body: {
        noMatch: "No transfusion reactions.",
      },
    },
  };

  const columns = [
    { name: 'product', label: 'Product', options: { sort: true, filter: true } },
    { name: 'blood_type', label: 'B. Type', options: { sort: true, filter: true } },
    { name: 'diagnosis', label: 'Patient Diagnosis', options: { sort: true, filter: false } },
    { name: 'outcome', label: 'Transfusion result', options: { sort: true, filter: false } },
    { name: 'created_at', label: 'Recorded on', options: { sort: true, filter: false } },
    {
      name: "ACTIONS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ButtonGroup color="secondary" size="small" label="small">
                <Button
                  type="icon"
                  size="small"
                  onClick={() => {
                    const reactionId = tableMeta.tableData[tableMeta.rowIndex].id;
                    const reactionDetail = reactionList.filter((p) => p.id === reactionId);
                    setReactionDetails(reactionDetail[0]);
                    handleOpen();
                  }} sx={{ backgroundColor: "#008e76", fontSize: 10, color: "#fff", ml: 1 }}>
                  <VisibilityIcon sx={{ mr: 1, fontSize: 12, }} /> Details
                </Button>
              </ButtonGroup>
            </>
          );
        },
        filter: false,
        sort: false,
      }
    }
  ];

  const getProperDate = (date) => {
    return moment(date).format('ll');
  };

  const getTransfusionReactions = async () => {
    const productList = [];
    const user = await AuthRepository.getUser();
    const response = await InventoryManagementRepository.getTransfusionReactions(user.facility_id);
    setReactionList(response);

    response.forEach(value => {
      productList.push({
        id: value.id,
        requested_by: value.facility.name,
        blood_type: value.product.blood_type.name,
        created_at: getProperDate(value.created_at),
        product: value.product.blood_component.name,
        diagnosis: value.diagnosis,
        outcome: value.outcome.join(', '),
        volume_transfused: value.volume_transfused ? value.volume_transfused : 0,
        source_facility: value.source_facility.name,
      });
    });

    setProducts(productList);
  };

  React.useEffect(() => {
    getTransfusionReactions();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        Transfusion Reaction
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, fontSize: 11, color: "#202020" }}>
        Manage Inventory / <strong>Transfusion Reactions</strong>
      </Typography>
    </div>

    <Box textAlign="center">
      <div style={{ height: 900, width: '100%' }}>
        <MUIDataTable
          className="muidatatable"
          data={products}
          columns={columns}
          options={options}
        />
      </div>
    </Box>


    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: -5, textDecoration: "none" }} onClick={handleClose}>
            <Close />
          </a>
          <div>
            <TransfusionReactionDetails reactionDetails={reactionDetails} />
          </div>
        </Box>
      </Modal>
    </div>
  </>
}

export function TransfusionReactionPage() {
  return <TransfusionReaction />
}
