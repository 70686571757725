import * as React from 'react';
import LoginPage from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CodeVerification from "./pages/auth/CodeVerification";
import ResetPasswordPage from "./pages/auth/ResetPassword";
import RegisterPage from './pages/auth/Register';
import Profile from "./pages/profile/Profile";
import Dashboard from './pages/dashboard/Dashboard';
import Settings from "./pages/settings/Settings";
import ScheduleAppointment from './pages/appointments/sub-pages/ScheduleAppointment';
import UpcomingAppointments from './pages/appointments/sub-pages/UpcomingAppointments';
import Appointment from './pages/appointments/Appointment';
import InventoryManagement from './pages/inventory/InventoryManagement';
import AddProduct from './pages/inventory/sub-pages/AddProduct';
import {TransfusionReactionPage} from './pages/inventory/sub-pages/TransfusionReactions';
import IssuedInventory from './pages/inventory/sub-pages/IssuedProducts';
import BloodRequest from './pages/outgoing-requests/BloodRequest';
import AddRequest from './pages/outgoing-requests/sub-pages/AddRequest';
import ActiveRequests from './pages/outgoing-requests/sub-pages/ActiveRequests';
import ApprovedRequests from './pages/outgoing-requests/sub-pages/ApprovedRequests';
import Billing from './pages/billing/Billing';
import Contact from './pages/contact/Contact';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import { ProductHistoryPage } from "./pages/inventory/sub-pages/ProductHistory";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthRepository from "./pages/auth/AuthRepository";
import './main.css';
import DonationManagement from "./pages/donations/DonationManagement";
import AddDonation from "./pages/donations/sub-pages/AddDonation";
import AddDonor from "./pages/donations/sub-pages/AddDonor";
import DonorsEnrolled from "./pages/donations/sub-pages/DonorsEnrolled";
import {UnscreenedProductPage} from "./pages/donations/sub-pages/Unscreened";
import ReactGA from 'react-ga';
import MobileSignup from './pages/MobileSignup';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const mdTheme = createTheme({
  typography: {
    fontFamily: "Space Grotesk",
  },
  MUIDataTableBodyCell: {
    styleOverrides: {
      MuiTableCell: {
        head: { fontWeight: 600 }
      }
    }
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#0046AF",
    },
    error: {
      main: "#ba000d",
    },
    success: {
      main: "#1faa00",
    },
    text: {
      main: "#000",
    },
  },
});

function App() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const authToken = localStorage.getItem("__auth_token");

  const getLoggedInState = () =>{
    const _user = AuthRepository.getUser();
    if (_user === null || _user === undefined) setLoggedIn(false);
    else setLoggedIn(true);
  };

  React.useEffect(() => {
    getLoggedInState();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      {authToken ? (
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='profile' element={<Profile />} />
            <Route path='settings' element={<Settings />} />
            <Route path='inventory' element={<InventoryManagement />} />
            <Route path='inventory/issued' element={<IssuedInventory />} />
            <Route path='inventory/add' element={<AddProduct />} />
            <Route path='inventory/reaction' element={<TransfusionReactionPage />} />
            <Route path='inventory/history' element={<ProductHistoryPage />} />
            <Route path='appointments' element={<Appointment />} />
            <Route path='appointments/upcoming' element={<UpcomingAppointments />} />
            <Route path='appointments/add' element={<ScheduleAppointment />} />
            <Route path='requests/new' element={<AddRequest />} />
            <Route path='billing' element={<Billing />} />
            <Route path='requests/history' element={<BloodRequest />} />
            <Route path='requests/accepted' element={<ApprovedRequests />} />
            <Route path='requests/active' element={<ActiveRequests />} />
            <Route path='donations' element={<DonationManagement />} />
            <Route path='donations/add' element={<AddDonation />} />
            <Route path='donations/donors/add' element={<AddDonor />} />
            <Route path='donations/donors/enrolled' element={<DonorsEnrolled />} />
            <Route path='contact' element={<Contact />} />
            <Route path='donations/unscreened' element={<UnscreenedProductPage />} />
          </Route>
          <Route path="*" element={<Navigate to='/dashboard' />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verifycode" element={<CodeVerification />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/app_signup" element={<MobileSignup />} />
          <Route path="*" element={<Navigate to='/login' />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}

export default App;
