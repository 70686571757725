import axios from "axios";
import CryptoJS from "crypto-js";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const AUTH_USER_KEY = '__auth_user';

const AUTH_TOKEN_KEY = '__auth_token';

const ENCRYPT_SECRET_KEY = 'p8fx9AZGBdXxRSgLtYvUcHTFMVGjnRWSQXHQ7a9LT2YntKXhpwEq8Zrd3bjSwyhj';

const AuthRepository = {

  /**
   * Locally saves a user in the browser.
   * @param user
   */
  saveUser: (user) => {
    localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
  },

  /**
   * Returns locally saved user object.
   * @returns {any}
   */
  getUser: () => {
    return JSON.parse(localStorage.getItem(AUTH_USER_KEY));
  },

  /**
   * Encrypts auth token.
   * @param token
   * @returns {string}
   */
  encryptToken: (token) => {
    return CryptoJS.AES.encrypt(token, ENCRYPT_SECRET_KEY).toString();
  },

  /**
   * Decrypts auth token.
   * @param cipherToken
   * @returns {string|null}
   */
  decryptToken: (cipherToken) => {
    if (cipherToken === null) {
      return null;
    }
    else {
      const bytes = CryptoJS.AES.decrypt(cipherToken, ENCRYPT_SECRET_KEY);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
  },

  /**
   * Saves auth token locally in the browser.
   * @param token
   */
  saveToken: (token) => {
    const encryptedToken = AuthRepository.encryptToken(token);
    localStorage.setItem(AUTH_TOKEN_KEY, encryptedToken);
  },

  /**
   * Returns auth token saved locally.
   * @returns {string}
   */
  getToken: () => {
    const encryptedToken = localStorage.getItem(AUTH_TOKEN_KEY);
    return AuthRepository.decryptToken(encryptedToken);
  },

  /**
   * Logs in a user.
   * @param authData
   * @returns {Promise<any>}
   */
  login: async (authData) => {
    return (await axios.post(`${HOST}/auth/login`, authData)).data;
  },

  /**
   * Registers a new user.
   * @param userData
   * @returns {Promise<any>}
   */
  signup: async (userData) => {
    return (await axios.post(`${HOST}/auth/register`, userData)).data;
  },


  /**
   * Creates a new user.
   * @param userData
   * @returns {Promise<any>}
   */
  createuser: async (userData) => {
    return (await axios.post(`${HOST}/users`, userData)).data;
  },

  /**
   * Updates an existing user.
   * @param {number} userId 
   * @param {*} userData 
   * @returns {Promise<any>}
   */
  updateUser: async (userId, userData) => {
    const url = `${HOST}/users/${userId}`;
    return (await axios.put(url, userData)).data;
  },

  /**
   * Verifies user account.
   * @param email
   * @returns {Promise<any>}
   */
  forgotPassword: async (email) => {
    const body = { email };
    return (await axios.post(`${HOST}/auth/forgot/password`, body)).data;
  },

  /**
   * Updates user password.
   * @param data
   * @returns {Promise<any>}
   */
  changePassword: async (data) => {
    return (await axios.post(`${HOST}/auth/change/password`, data)).data;
  },

  /**
   * Resets a user's password.
   * @param data
   * @returns {Promise<any>}
   */
  resetPassword: async (data) => {
    return (await axios.post(`${HOST}/auth/reset/password`, data)).data;
  },

  /**
   * Verifies code.
   * @param data
   * @returns {Promise<<any>}
   */
  verifyCode: async (data) => {
    return (await axios.post(`${HOST}/auth/verify/code`, data)).data;
  },

  /**
   * Returns a list of all positions.
   * @returns {Promise<any>}
   */
  getPositions: async() => {
    return (await axios.get(`${HOST}/positions`)).data;
  },

  /**
   * Logs out a user.
   */
  logout: () => {
    localStorage.clear();
    sessionStorage.clear();
  },
};

export default AuthRepository;