import moment from 'moment';
import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import MUIDataTable from "mui-datatables";
import { Close } from "@mui/icons-material";
import Title from '../../../components/Title';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import AuthRepository from '../../auth/AuthRepository';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProductHistoryDetails from "../components/ProductHistoryDetails";
import { enableTrackingForMetrics } from "../../../libs/activityTracking";
import InventoryManagementRepository from "../InventoryManagementRepository";
import TransfusionReactionQuestions from "../components/TransfusionReactionQuestions";

const modalStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  paddingRight: "10%",
  paddingLeft: "10%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const ProductHistory = () => {
  const user = AuthRepository.getUser();

  const [open, setOpen] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [productId, setProductId] = React.useState([]);
  const [productHistory, setProductHistory] = React.useState({});
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [sourceFacilityId, setSourceFacilityId] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenViewModal = () => setOpenViewModal(true);
  const handleCloseViewModal = () => setOpenViewModal(false);

  const options = {
    rowsPerPage: 30,
    filterType: 'checkbox',
    selectableRows: 'none',
    responsive: "vertical",
    rowsPerPageOptions: [30, 50, 80, 100, 150, 200],
    textLabels: {
      body: {
        noMatch: "No product history.",
      },
    },
  };

  const getStatusColor = (statusName) => {
    if (statusName.toLowerCase() === 'issued internal') return 'secondary';
    if (statusName.toLowerCase() === 'issued external') return 'success';
    if (statusName.toLowerCase() === 'expired') return 'warning';
    if (statusName.toLowerCase() === 'removed') return 'error';
  };

  const presentReactionModal = (setProductId, tableMeta, setSourceFacilityId, handleOpen) => {
    setProductId(tableMeta.rowData[0]);
    setSourceFacilityId(tableMeta.rowData[0]);
    handleOpen();
  };

  const presentViewModal = (productId) => {
    const _productHistory = products.filter((p) => p.id === productId)[0];
    setProductHistory(_productHistory);
    handleOpenViewModal();
  }

  const columns = [
    { name: 'id', label: 'ID', options: { sort: true, filter: false, display: false } },
    { name: 'source_facility', label: 'Source facility', options: { sort: true, filter: false, display: false } },
    { name: 'product', label: 'Product', options: { sort: true, filter: true } },
    { name: 'barcode', label: 'Barcode', options: { sort: true, filter: false } },
    { name: 'product_code', label: 'Product Code', options: { sort: true, filter: false } },
    { name: 'blood_type', label: 'B. Type', options: { sort: true, filter: true } },
    {
      name: 'status', label: 'Status', options: {
        sort: true,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const color = getStatusColor(value);
          return (
            <>
              <Chip label={value} color={color} sx={{ display: "flex" }} />
            </>
          );
        },
      },
    },
    { name: 'received_date', label: 'Collected', options: { sort: true, filter: false } },
    { name: 'expiration_date', label: 'Expiry Date', options: { sort: true, filter: false } },
    {
      name: "Actions",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta.rowData);
          return (
            <>
              <ButtonGroup color="secondary" size="small" label="small">
                <Button size="small" color="secondary" variant="outlined" onClick={() => { presentViewModal(tableMeta.rowData[0])}} sx={{ fontSize: 10, ml: 1 }}>
                  View
                </Button>

                <Button size="small" color="secondary" variant="contained" onClick={() => presentReactionModal(setProductId, tableMeta, setSourceFacilityId, handleOpen) } sx={{ fontSize: 10, ml: 1 }}>
                  + Reaction
                </Button>

              </ButtonGroup>
            </>
          );
        },
        filter: false
      }
    }
  ];

  const getProperDate = (date) => {
    return moment(date).format('ll');
  };

  const getProductsHistory = async () => {
    const productList = [];
    const user = await AuthRepository.getUser();
    const response = await InventoryManagementRepository.getProductHistory(user.facility_id);
    response.forEach(value => {
      productList.push({
        id: value.id,
        barcode: value.barcode,
        product_code: value.product_code,
        // patient_name: value.patient_name,
        // patient_age: value.patient_age,
        // physician_name: value.physician_name,
        // physician_contact: value.physician_contact,
        // department: value.department,
        blood_type: value.blood_type ? value.blood_type.name : "None",
        // patient_blood_type: value.blood_type ? value.blood_type.name : "None",
        expiration_date: getProperDate(value.expiration_date),
        received_date: getProperDate(value.received_date),
        product: value.blood_component?.name,
        status: value.status.name,
        status_id: value.status.id,
        reason: value.rejection_reason,
      });
    });

    productList.reverse();
    setProducts(productList);
  };

  React.useEffect(() => {
    getProductsHistory();
    enableTrackingForMetrics(user.id);
  }, []);

  return <>
    <div style={{ margin: "40px auto 30px" }}>
      <Title>
        <Link to="/inventory" variant="body2">
          <ArrowBackIcon sx={{ mr: 1 }} />
        </Link>
        Products History
      </Title>
      <Typography variant="body" component="div" sx={{ mt: 1, ml: 3.5, fontSize: 11, color: "#202020" }}>
        Manage Inventory / <strong>Products History</strong>
      </Typography>
    </div>

    <Box textAlign="center">
      <div style={{ width: '100%' }}>
        <MUIDataTable
          className="muidatatable"
          data={products}
          columns={columns}
          options={options}
        />
      </div>
    </Box>

    <div>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: -5, textDecoration: "none", color: "#0d3b82" }} onClick={handleClose}>
            <Close />
          </a>
          <TransfusionReactionQuestions productID={productId} sourcefacilityID={sourceFacilityId} closeFunction={handleClose} />
        </Box>
      </Modal>

      <Modal open={openViewModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <a href="#" style={{ float: "right", mt: -5, textDecoration: "none", color: "#0d3b82" }} onClick={handleCloseViewModal}>
            <Close />
          </a>
          <ProductHistoryDetails productHistory={productHistory} closeFunction={handleClose} />
        </Box>
      </Modal>
    </div>
  </>
}

export function ProductHistoryPage() {
  return <ProductHistory />
}
