import axios from "axios";

/**
 * API server host address.
 */
const HOST = process.env.REACT_APP_API_HOST;

const AdminUserRepository = {

  /**
   * Returns a list of all users of a facility.
   * @returns
   */
  getFacilityUsers: async (facilityId) => {
    const response = await axios.get(`${HOST}/facility/users/${facilityId}`);
    return response.data;
  },

  /**
   * Gets details of a facility
   * @returns
   */
  getFacility: async (facilityId) => {
    const response = await axios.get(`${HOST}/facilities/${facilityId}`);
    return response.data;
  },

  /**
   * Updates a facility.
   * @returns
   */
  updateFacility: async (id, data) => {
    console.log(data);
    const response = await axios.put(`${HOST}/facilities/${id}`, data);
    return response.data;
  },

  /**
   * Deletes a User
   * @returns
   */
  getUser: async (userId) => {
    const response = await axios.get(`${HOST}/users/${userId}`);
    return response.data;
  },

   /**
   * Deletes a User
   * @returns
   */
  deleteUser: async (userId) => {
    const response = await axios.delete(`${HOST}/users/${userId}`);
    return response.data;
  },
}

export default AdminUserRepository;
