import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default function MessageTribe() {
  const members = [
    {
      value: "all_donors",
      label: "All Tribe Members",
    },
    {
      value: "Opos",
      label: "0+ Donors only",
    },
    {
      value: "Oneg",
      label: "0- Donors only",
    },
  ];

  const [tribe, setDefault] = React.useState("all_donors");

  const handleChange = (event) => {
    setDefault(event.target.value);
  };

  return (
    <Box component="form">
      <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontSize: 16 }}>
        Send Message
      </Typography>

      <p>To</p>
      <TextField
        id="outlined-select-tribe"
        select
        value={tribe}
        onChange={handleChange}
        sx={{ width: "100%", border: "1px solid #ddd", borderRadius: 2 }}
      >
        {members.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <p>Message</p>
      <TextField
        id="standard-multiline-flexible"
        multiline
        rows={4}
        sx={{ width: "100%", border: "1px solid #ddd", borderRadius: 2 }}
      />

      <Button type="submit" variant="contained" sx={{ mt: 4, mb: 2, backgroundColor: "#0046AF", color: "#ffffff", width: 100 }}>
        Send
      </Button>
    </Box>
  );
}
